/* eslint-disable no-param-reassign */
import type { UseMutationResult } from '@tanstack/react-query';
import { useShallow } from 'zustand/react/shallow';
import { updateEmployeeSortOrder } from '@/components/table/shared/utils';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Prompt } from '@/utils/types/AITypes';
import type { Employee, HiringPlan } from '@/utils/types/HiringTypes';
import { getDateStringFormat, parseUTCDateObject } from '../dateUtils';
import type { Branch } from '../hooks/branches/useBranches';
import useBranches from '../hooks/branches/useBranches';
import { useEmployeesQuery } from '../hooks/employees/useEmployees';
import { useProcessedBranchEmployees } from '../hooks/employees/useProcessedBranchEmployees';
import useMutations from '../hooks/mutations/useMutations';
import type { Graph } from '../types/DashboardAndChartsTypes';

/**
 * This function updates the graphs with the new ids for dashboard, formula and output from the new branch clone.
 * @param clonedBranch The cloned branch
 * @param allGraphsFromPrompts  all the graphs from the AI prompt
 */
function updateGraphsWithNewBranch(clonedBranch: Branch, allGraphsFromPrompts: Graph[]) {
  const newDashboardIDToOldDashboardIDMap = clonedBranch.retrieve_ids?.dashboard ?? {};
  const newFormulaaIDToOldFormulaIDMap = clonedBranch.retrieve_ids?.formulas ?? {};
  const newOutputsIDToOldOutputsIDMap = clonedBranch.retrieve_ids?.outputs ?? {};
  allGraphsFromPrompts.forEach(graph => {
    // Ensure the mapping result is a number or keep the original ID
    graph.graph_data.dashboard_id = newDashboardIDToOldDashboardIDMap[graph.graph_data.dashboard_id] ?? graph.graph_data.dashboard_id;
    graph.data_groups.forEach(group => {
      group.data_points.forEach(data => {
        // Ensure the mapping result is a number or keep the original ID
        data.formula_id = newFormulaaIDToOldFormulaIDMap[data.formula_id] ?? data.formula_id;
        data.output_id = newOutputsIDToOldOutputsIDMap[data.output_id] ?? data.output_id;
      });
    });
  });
}

/**
 * This function updates the employees with the new ids for dashboard, formula and output from the new branch clone, then generated the graph call to backend
 * @param branch_id the branch id
 * @param clonedBranch the cloned branch object
 * @param createBulkGraph the mutation to create bulk graph
 * @param allGraphs all the graphs parsed from the AI prompt
 */
function applyGraphPrompts(branch_id: number, clonedBranch: any | undefined, createBulkGraph: UseMutationResult<any, any, any, unknown>, updateBulkGraph: UseMutationResult<any, any, any, unknown>, allGraphs: Graph[], graphPrompts: Prompt[]) {
  if (clonedBranch) {
    updateGraphsWithNewBranch(clonedBranch, allGraphs);
  }
  const modifyPrompts = graphPrompts.filter(prompt => prompt.promptOperation === 'modify');
  let modifyGraphs = modifyPrompts.map(prompt => ((prompt.response as unknown) as Graph))?.flat();
  // filter out duplicate graphs, taking last id
  modifyGraphs = Array.from(new Map(modifyGraphs.map((graph: Graph) => [graph.id, graph])).values());
  let graphsToCreate = allGraphs.map(graph => {
    // if modify graphs conttains the graph, then skip
    if (modifyGraphs.some(modifyGraph => modifyGraph.id === graph.graph_data.id)) {
      return null;
    }
    graph.graph_data.branch_id = branch_id;
    return graph;
  });
  // filter out nulls from the graphsToCreate array
  graphsToCreate = graphsToCreate.filter(graph => graph !== null);
  if (graphsToCreate.length > 0) {
    createBulkGraph.mutate(graphsToCreate);
  }
  if (modifyGraphs.length > 0) {
    updateBulkGraph.mutate(modifyGraphs);
  }
}

/**
 * applies Hiring prompts to employees
 */
function applyHiringPrompts(branch_id: number, employees: Employee[], processedEmployees: Employee[], modifiedEmployeeIdsList: number[], createBulkEmployee: UseMutationResult<any, any, any, unknown>, deleteBulkEmployee: UseMutationResult<any, unknown, {
  ids: number[];
}, unknown>, updateBulkEmployee: UseMutationResult<any, unknown, any, unknown>, updateHiringPlan: UseMutationResult<any, unknown, any, unknown>, activeHiringPlan: HiringPlan) {
  return new Promise<void>(resolve => {
    const currentEmployeeSortOrder = activeHiringPlan.employee_sort_order;
    const mutations = [];

    // Define employees to delete
    const employeesToDelete = employees.filter(employee => employee.id !== undefined && processedEmployees.findIndex(processedEmployee => processedEmployee.id === employee.id) === -1).map(employee => (employee.id as number));

    // Define employees to create
    const employeesToCreate = processedEmployees.filter(employee => employee?.is_ai_employee).map(employee => {
      const newEmployee = ({
        ...employee,
        branch_id,
        start_date: employee.start_date ? getDateStringFormat(parseUTCDateObject(employee.start_date)) : ''
      } as Employee);
      delete newEmployee.id;
      delete newEmployee.is_ai_employee;
      return newEmployee;
    });
    // Define employees to update
    const employeesToUpdate = processedEmployees.filter(employee => !employee.is_ai_employee && modifiedEmployeeIdsList.includes((employee.id as number))).map(employee => {
      const currentEmployee = employee;
      if (!currentEmployee.role) {
        delete currentEmployee.role;
      }
      return ({
        ...employee,
        branch_id
      } as Employee);
    });
    if (employeesToDelete?.length > 0) {
      mutations.push(deleteBulkEmployee.mutateAsync({
        ids: employeesToDelete
      }).then(() => updateHiringPlan.mutateAsync({
        ...activeHiringPlan,
        employee_sort_order: updateEmployeeSortOrder(employeesToDelete, currentEmployeeSortOrder, true)
      })));
    }
    if (employeesToCreate?.length > 0) {
      mutations.push(createBulkEmployee.mutateAsync(employeesToCreate)
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .then((employees: Employee[]) => {
        if (activeHiringPlan.branch_id === branch_id) {
          updateHiringPlan.mutateAsync({
            ...activeHiringPlan,
            employee_sort_order: updateEmployeeSortOrder(employees.map(employee => (employee.id as number)), currentEmployeeSortOrder)
          });
        }
      }));
    }
    if (employeesToUpdate?.length > 0) {
      mutations.push(updateBulkEmployee.mutateAsync(employeesToUpdate));
    }
    Promise.all(mutations).then(() => resolve());
  });
}
async function createNewBranch(cloneBranch: (brannchToCloneId: number, body?: any) => Promise<Branch>, activeBranch: Branch, ignoreChildrenKeys: string[] = [], retrieve_ids: Record<string, number[]> = {}) {
  const {
    id,
    ...branchBody
  } = activeBranch;
  const newBranchBody = {
    model: {
      ...branchBody,
      is_main: false,
      name: `Copy of ${activeBranch.name}`,
      status: 'active',
      slug: `copy-of-${activeBranch.slug}`
    },
    //  optional parameter to disable cloning employees
    ignore_children: ignoreChildrenKeys,
    retrieve_ids
  };
  // clone new branch and set active branch to new branch
  const clonedBranch = await cloneBranch(id, newBranchBody);
  return clonedBranch;
}

/**
 * This function creates the mapping to require from the backend to retrieve new ids for dashboard, formula and output
 * @param keysToRetrieveNewIds keys to retrieve new ids
 * @param allGraphsFromPrompts all graphs from prompts
 * @returns keysToRetrieveNewIds map
 */
function getRetrieveIdsMapForGraphs(keysToRetrieveNewIds: Record<string, number[]>, allGraphsFromPrompts: Graph[]): Record<string, number[]> {
  // Populate keysToRetrieveNewIds with valid arrays
  keysToRetrieveNewIds = {
    dashboard: allGraphsFromPrompts.map(graph => graph.graph_data.dashboard_id).filter(Boolean),
    outputs: allGraphsFromPrompts.flatMap(graph => graph.data_groups.flatMap(group => group.data_points.map(data => data.output_id))).filter(Boolean),
    formulas: allGraphsFromPrompts.flatMap(graph => graph.data_groups.flatMap(group => group.data_points.map(data => data.formula_id))).filter(Boolean)
  };

  // Remove keys with empty arrays
  keysToRetrieveNewIds = Object.fromEntries(Object.entries(keysToRetrieveNewIds).filter(([, value]) => value && value.length > 0));
  return keysToRetrieveNewIds;
}

/**
 * This function processes existing employees to the case of cloneing data to new branch.
 * @param currentBranchID he current branch ID
 * @param processedEmployees Processed employees with prompts
 * @returns employees to create list
 */
function getNewBranchEmployees(currentBranchID: number, processedEmployees: Employee[]) {
  const employeesToCreate = processedEmployees.map(employee => {
    const newEmployee = ({
      ...employee,
      is_ai_employee: true,
      // set is_ai_employee to true for all employees to create them

      start_date: employee.start_date ? getDateStringFormat(parseUTCDateObject(employee.start_date)) : null,
      // converting start date to ISO string
      branch_id: currentBranchID
    } as Employee);
    delete newEmployee.id; // remove temporary id from employee
    return newEmployee;
  });
  return employeesToCreate;
}
export function useMergePrompts() {
  const {
    employees: processedEmployees,
    modifiedEmployeeIdsList
  } = useProcessedBranchEmployees();
  const {
    employeesByBranch
  } = useEmployeesQuery();
  const {
    activeBranch,
    cloneBranch
  } = useBranches();
  const {
    prompts,
    resetPrompts,
    activeHiringPlan
  } = useZustandStore(useShallow((state: ZustandState) => ({
    prompts: state.prompts,
    resetPrompts: state.resetPrompts,
    activeHiringPlan: state.activeHiringPlan
  })));
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));
  const {
    createBulkEmployee,
    deleteBulkEmployee,
    updateBulkEmployee,
    updateHiringPlan,
    createBulkGraph,
    updateBulkGraph
  } = useMutations();
  async function mergePrompts(mergeToNewBranch: boolean = false) {
    let currentBranchID = activeBranch.id;
    // Extract prompts to local variable before any operations
    const localPrompts = [...prompts];
    const hiringPrompts = localPrompts.filter((prompt: Prompt) => prompt.type === 'hiring-plan');
    const graphPrompts = localPrompts.filter((prompt: Prompt) => prompt.type === 'graph');
    if (!localPrompts?.length) {
      console.error('No prompts provided');
      return;
    }
    let clonedBranch: Branch | undefined;
    let employeesToSendToBackend = [...processedEmployees];

    // Reset prompts in store immediately
    await resetPrompts();
    setAppLoader(true);
    const allGraphsFromPrompts = (graphPrompts?.map(prompt => prompt.response).flat() as Graph[]) || [];
    if (mergeToNewBranch) {
      let keysToRetrieveNewIds = {};
      if (allGraphsFromPrompts) {
        keysToRetrieveNewIds = getRetrieveIdsMapForGraphs(keysToRetrieveNewIds, allGraphsFromPrompts);
      }
      clonedBranch = await createNewBranch(cloneBranch, activeBranch, hiringPrompts.length > 0 ? ['employees'] : [], keysToRetrieveNewIds // we need handle here getting new formula ids and outputs as well.
      );
      currentBranchID = clonedBranch.id;
    }
    try {
      if (hiringPrompts?.length > 0) {
        if (mergeToNewBranch) {
          employeesToSendToBackend = getNewBranchEmployees(currentBranchID, processedEmployees);
        }
        await Promise.all([new Promise<void>(resolve => {
          applyHiringPrompts(currentBranchID, mergeToNewBranch ? [] : employeesByBranch, employeesToSendToBackend ?? [], modifiedEmployeeIdsList, createBulkEmployee, deleteBulkEmployee, updateBulkEmployee, updateHiringPlan, activeHiringPlan);
          resolve();
        })]);
      }
      if (allGraphsFromPrompts) {
        await Promise.all([new Promise<void>(resolve => {
          applyGraphPrompts(currentBranchID, clonedBranch, createBulkGraph, updateBulkGraph, allGraphsFromPrompts, graphPrompts);
          resolve();
        })]);
      }
    } catch (error) {
      console.error('Error applying prompts:', error);
    } finally {
      setTimeout(() => {
        setAppLoader(false);
      }, 1500);
    }
  }
  return mergePrompts;
}