/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import { ChevronRight, MoveRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import ShineBorder from '@/components/common/ShineBorder/ShineBorder';
// eslint-disable-next-line import/no-cycle
import variables from '@/styles/scss/abstracts/_variables.module.scss';
import styles from './newCompanySetupStage.module.scss';
export enum CompanyPlanType {
  BlankPlan = 'blankPlan',
  AutomatedAiPlanBuilder = 'automatedAiPlanBuilder',
  UploadYourPlan = 'uploadYourPlan',
}
export enum AutomatedAiPlanBuilderSubPlanType {
  PlanBuilder = 'planBuilder',
  OneClickPlan = 'oneClickPlan',
}
export enum FastPlanOptionsType {
  SimpleFastPlan = 'simpleFastPlan',
  AdvancedFastPlan = 'advancedFastPlan',
}
interface NewCompanySetupStageProps {
  data: {
    selectedPlan: CompanyPlanType;
  };
  onChange: (data: {
    selectedPlan: CompanyPlanType;
  }) => void;
  planConfig: {
    icon: React.ReactNode;
    title: string;
    descriptionList: string[];
    badge: React.ReactNode;
    isHtml?: boolean;
    type: CompanyPlanType;
    isAiCard: boolean;
    isHidden: boolean;
  }[];
  title: string;
  breadcrumbPath?: Array<{
    label: string;
  }>;
}
interface BreadcrumbsProps {
  path: Array<{
    label: string;
  }>;
}
export const OnboardingBreadcrumbs: React.FC<BreadcrumbsProps> = ({
  path
}) => {
  if (!path || path.length <= 1) return null;
  return <div className={styles.breadcrumbs} data-testid="onboarding-breadcrumbs" data-sentry-component="OnboardingBreadcrumbs" data-sentry-source-file="NewCompanySetupStage.tsx">
      {path.map((item, index) => <React.Fragment key={item.label}>
          <span className={index < path.length - 1 ? styles.clickable : styles.current}>
            {item.label}
          </span>
          {index < path.length - 1 && <ChevronRight size={16} />}
        </React.Fragment>)}
    </div>;
};

/**
 * Component to render the new company setup stage.
 */
const NewCompanySetupStage: React.FC<NewCompanySetupStageProps> = ({
  data,
  onChange,
  planConfig,
  title,
  breadcrumbPath
}) => {
  const [selectedPlan, setSelectedPlan] = useState(data.selectedPlan);
  useEffect(() => {
    setSelectedPlan(data.selectedPlan);
  }, [data.selectedPlan]);

  /**
   * Generates a styled animation for the selected AI plan.
   * @param child - The content to render inside the animation.
   * @param selectedIndex - The current selected plan index.
   * @param activeIndex - The index of the active plan to animate.
   * @returns Rendered plan content with or without animation.
   */
  const generateAiPlanAnimation = (child: React.ReactNode, selected: CompanyPlanType, activePlan: CompanyPlanType) => {
    return selected === activePlan ? <ShineBorder borderRadius={9} borderWidth={4} duration={8} color={[(variables.midBlue as string), (variables.livelyPink as string), (variables.paleOrange as string)]} className="h-full !overflow-visible" // Use !overflow-visible to override the default
    data-sentry-element="ShineBorder" data-sentry-component="generateAiPlanAnimation" data-sentry-source-file="NewCompanySetupStage.tsx">
        {child}
      </ShineBorder> : child;
  };

  /**
   * Renders a single plan card.
   */
  const renderPlanCard = (plan: CompanyPlanType, {
    icon,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    title,
    descriptionList,
    badge,
    isHtml,
    isAiCard
  }: (typeof planConfig)[number]) => {
    const content = <div data-testid={(`${plan}-card` as string)} className={classNames(styles.companyPlan, {
      [(styles.selected as string)]: selectedPlan === plan
    })} onClick={() => onChange({
      selectedPlan: plan
    })}>
        <h1>
          {icon}
          {title}
        </h1>

        <ul>
          {descriptionList.map((item, index) => isHtml ? <li key={index}>
                <MoveRight size={18} />
                <span dangerouslySetInnerHTML={{
            __html: item
          }} />
              </li> : <li key={index}>
                <MoveRight size={18} />
                <span>{item}</span>
              </li>)}
        </ul>

        {badge && <div className={styles.bottomBadgeContainer}>
            <span className={styles.bottomBadge}>{badge}</span>
          </div>}
      </div>;
    return isAiCard ? generateAiPlanAnimation(content, selectedPlan, plan) : content;
  };
  return <div className={styles.newCompanySetupStage} data-testid="new-company-setup-stage" data-sentry-component="NewCompanySetupStage" data-sentry-source-file="NewCompanySetupStage.tsx">
      {breadcrumbPath && <OnboardingBreadcrumbs path={breadcrumbPath} />}
      <div className={styles.newCompanySetupStageContent}>
        <div className={styles.sectionTitle}>
          <h1 dangerouslySetInnerHTML={{
          __html: title
        }} />
        </div>
        {/* Company Plans */}
        <div className={styles.companyPlans}>
          {planConfig.filter(config => !config.isHidden).map(config => <React.Fragment key={config.type}>
                {renderPlanCard(config.type, config)}
              </React.Fragment>)}
        </div>
      </div>
    </div>;
};
export default NewCompanySetupStage;