import dynamic from 'next/dynamic';
import { memo, useEffect, useRef, useState } from 'react';
import { ChartSkeleton } from '@/components/common/Loaders/Loaders';
import { validateSeriesData } from '@/utils/ChartUtils';
import type { ChartTypes } from '@/utils/types/DashboardAndChartsTypes';
import styles from '../graphs.module.scss';

// Create a module-level cache that persists between renders
// This will help prevent charts from reloading when navigating between pages
const chartInstanceCache = new Map();

// Create a completely separate client component without dynamic imports first
// We'll handle the dynamic import in our wrapper component
const ClientSideChart = memo(function ClientSideChart({
  type,
  options,
  series,
  height,
  width,
  oldDashboard,
  chartKey
}: {
  type: ChartTypes;
  options: object;
  series: {
    name: string;
    data: number[];
  }[] | {
    data: number[];
  }[] | number[];
  height?: number | string;
  width?: number | string;
  oldDashboard?: Boolean;
  chartKey?: string;
}) {
  // Don't even try to import ApexCharts until we're sure we're on the client
  // UseEffect guarantees we're on the client side
  const [ApexCharts, setApexCharts] = useState<any>(null);
  const chartRef = useRef<any>(null);

  // Validate series data to ensure it has the correct format
  // Donut charts don't need to be validated because they are always an array of numbers
  const validatedSeries = type === 'donut' ? series : validateSeriesData(series);
  useEffect(() => {
    // Import ApexCharts only on the client side
    let isMounted = true;

    // Check if we already have this chart instance in the cache
    if (chartKey && chartInstanceCache.has(chartKey) && chartInstanceCache.get(chartKey).ApexCharts) {
      setApexCharts(chartInstanceCache.get(chartKey).ApexCharts);
    } else {
      import('react-apexcharts').then(mod => {
        if (isMounted) {
          setApexCharts(() => mod.default);
          if (chartKey) {
            chartInstanceCache.set(chartKey, {
              ...(chartInstanceCache.get(chartKey) || {}),
              ApexCharts: mod.default
            });
          }
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [chartKey]);

  // Show skeleton loader for non-donut charts while loading
  if (!ApexCharts) {
    return type === 'donut' ? <div className={styles.ChartHeightWidth} /> : <div className={styles.ChartHeightWidth}>
          <ChartSkeleton />
        </div>;
  }
  return <ApexCharts ref={chartRef} height={height || '100%'} data-testid="mocked-chart" width={width || (type !== 'donut' ? '100%' : '300')} style={{
    padding: 0,
    margin: 0
  }} options={options} series={validatedSeries} type={type} className={!oldDashboard ? type !== 'donut' ? styles.customChart : styles.customDonut : ''} />;
}, (prevProps, nextProps) => {
  // Only re-render if the props that affect the chart have changed
  return prevProps.type === nextProps.type && JSON.stringify(prevProps.options) === JSON.stringify(nextProps.options) && JSON.stringify(prevProps.series) === JSON.stringify(nextProps.series) && prevProps.height === nextProps.height && prevProps.width === nextProps.width && prevProps.oldDashboard === nextProps.oldDashboard && prevProps.chartKey === nextProps.chartKey;
});

// Create a no-SSR wrapper with dynamic import
const ClientOnlyChart = dynamic(() => Promise.resolve(ClientSideChart), {
  ssr: false,
  loading: () => <div className={styles.ChartHeightWidth}>
      <ChartSkeleton />
    </div>
});

// Props interface
interface Props {
  oldDashboard?: Boolean;
  type: ChartTypes;
  options: object;
  series: {
    name: string;
    data: number[];
  }[] | {
    data: number[];
  }[] | number[];
  height?: number | string | undefined;
  width?: number | string | undefined;
}

/**
 * ChartComponent - Creates a chart with ApexCharts that runs only on the client side
 *
 * @param props Chart configuration props
 * @returns The rendered chart component
 */
function ChartComponent(props: Props) {
  const [isReady, setIsReady] = useState(false);
  const [cacheKey] = useState(() => {
    // Generate a unique key for this chart instance
    const key = `chart-${Math.random().toString(36).substring(2, 9)}`;
    return key;
  });

  // Use a ref to track if this is the first render
  const isFirstRender = useRef(true);
  useEffect(() => {
    // Ensure DOM is fully ready before rendering
    setIsReady(true);

    // Store this chart instance in the cache
    if (!chartInstanceCache.has(cacheKey)) {
      chartInstanceCache.set(cacheKey, {
        type: props.type,
        options: props.options,
        series: props.series
      });
    }

    // This will run only once when the component mounts
    return () => {
      // We don't remove from cache to allow persistence between page navigations
      isFirstRender.current = false;
    };
  }, [cacheKey, props.options, props.series, props.type]);
  if (!isReady) {
    return props.type === 'donut' ? <div className={styles.ChartHeightWidth} /> : <div className={styles.ChartHeightWidth}>
        <ChartSkeleton />
      </div>;
  }
  return <div className={styles.ChartHeightWidth} data-testid="apexchart-test" data-sentry-component="ChartComponent" data-sentry-source-file="ChartComponent.tsx">
      <ClientOnlyChart key={cacheKey} chartKey={cacheKey} type={props.type} options={props.options} series={props.series} height={props.height} width={props.width} oldDashboard={props.oldDashboard} data-sentry-element="ClientOnlyChart" data-sentry-source-file="ChartComponent.tsx" />
    </div>;
}

// Use React.memo to prevent unnecessary re-renders
export default memo(ChartComponent, (prevProps, nextProps) => {
  // Only re-render if the props that affect the chart have changed
  return prevProps.type === nextProps.type && JSON.stringify(prevProps.options) === JSON.stringify(nextProps.options) && JSON.stringify(prevProps.series) === JSON.stringify(nextProps.series) && prevProps.height === nextProps.height && prevProps.width === nextProps.width && prevProps.oldDashboard === nextProps.oldDashboard;
});