import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { Layout, StripePaymentElementOptions } from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { MoveRight, ShieldCheck } from 'lucide-react';
import Image from 'next/image';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import ccpaIcon from '@/public/assets/images/ccpa.webp';
import guarranteIcon from '@/public/assets/images/guarrante.webp';
import stripeIcon from '@/public/assets/images/stripe.png';
import useCreateMutations from '@/utils/hooks/mutations/useCreateAllMutations';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { PaymentModalContext } from '../StripeProvider';
import styles from './stripeCheckout.module.scss';

/**
 * The `StripeCheckout` component is responsible for rendering the Stripe payment element and handling the payment flow.
 * It uses the `useStripe` and `useElements` hooks from the `@stripe/react-stripe-js` library to interact with the Stripe SDK.
 * The component also manages various state variables and updates the application state using the `useUtilsStore` and `useZustandStore` hooks.
 * When the payment is successful, the component updates the `onboarding_finished_date` for the active company, invalidates all queries in the query cache, and resets various UI state variables.
 * If an error occurs during the payment process, the component displays an error message and reopens the payment modal.
 */
const StripeCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    errorMessage,
    setErrorMessage
  } = useContext(PaymentModalContext);
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [promoCode, setPromoCode] = useState('');
  const {
    createSubscription,
    sendErrorReport
  } = useMutations();
  const {
    activeCompany,
    userMe,
    setStripeClientSecret
  } = useZustandStore(useShallow(state => ({
    activeCompany: state.activeCompany,
    userMe: state.userMe,
    setStripeClientSecret: state.setStripeClientSecret
  })));
  const {
    data: subscriptionProducts
  } = useQuery({
    queryKey: [QUERYKEYS.GET_SUBSCRIPTION_PRODUCTS, activeCompany?.id],
    queryFn: ApiUtils.getSubscriptionProducts
  });
  const {
    setShowPaymentModal
  } = useUtilsStore(useShallow(state => ({
    setShowPaymentModal: state.setShowPaymentModal
  })));
  const {
    paymentIntent
  } = useCreateMutations();
  const {
    t: translate
  } = useTranslation(['common']);
  const createNewPaymentIntent = async () => {
    const response = await paymentIntent.mutateAsync({});
    setStripeClientSecret(response.paymentIntent.client_secret);
  };

  /**
  /**
   * Handles the submission of the Stripe payment form.
   * - Prevents the default form submission behavior.
   * - Checks if the Stripe library and payment elements are available.
   * - Sets the loading state to true.
   * - Confirms the payment with Stripe using the provided payment elements.
   * - Handles any errors that may occur during the payment confirmation process.
   * - If the payment is successful, calls the `onPaymentSuccess` function and displays a success toast notification.
   * - Sets the loading state to false.
   * 
   * @param e - The React form submission event.
   */
  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mixpanelTrackEventWrapper(MIXPANEL_EVENTS.USER_CLICKED_START_FREE_TRIAL);
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const {
      error,
      setupIntent
    } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            address: {
              country: 'US'
            }
          }
        }
      },
      redirect: 'if_required'
    });
    if (error) {
      setIsLoading(false);
      setErrorMessage((error?.message as string));
      mixpanelTrackEventWrapper(MIXPANEL_EVENTS.STRIPE_PAYMENT_PROCESS_ERROR, {
        errorType: error.type,
        errorMessage: error.message,
        errorCode: error.code
      });
      sendErrorReport.mutate({
        error_message: `stripe payment error:\n ${error.message}`,
        error_details: error ? JSON.stringify(error) : null
      });
      if (error.code === 'setup_intent_unexpected_state') {
        await createNewPaymentIntent();
      }
    } else if (setupIntent && setupIntent.status === 'succeeded') {
      try {
        if (subscriptionProducts && subscriptionProducts.products.length > 0) {
          const subscriptionBody = {
            currency: 'usd',
            // promo_code: promoCode || '',
            product_id: subscriptionProducts.products[0].id
          };
          await createSubscription.mutateAsync(subscriptionBody);
        }
        mixpanelTrackEventWrapper(MIXPANEL_EVENTS.PAYMENT_PROCESS_SUCCESS);
      } catch (err) {
        // setErrorMessage(
        //   err instanceof Error
        //     ? (err as any)?.response?.data?.detail
        //     : translate('subscription_creation_failed'),
        // );
        const errorDetails = JSON.stringify({
          userEmail: userMe?.email,
          username: userMe?.full_name,
          errorMessage: (err as any)?.response?.data?.detail
        });
        sendErrorReport.mutate({
          error_message: `subscription creation failed on backend:\n ${errorDetails}`,
          error_details: err ? JSON.stringify(err) : null
        });
        mixpanelTrackEventWrapper(MIXPANEL_EVENTS.BACKEND_PAYMENT_PROCESS_ERROR, {
          errorMessage: (err as AxiosError)?.response?.data,
          errorCode: (err as AxiosError)?.response?.status,
          errorType: (err as AxiosError)?.response?.statusText
        });
        // await createNewPaymentIntent();
      } finally {
        mixpanelTrackEventWrapper(MIXPANEL_EVENTS.MAKE_PAYMENT, {
          amount: `$${subscriptionProducts?.products[0].amount}`,
          companyName: activeCompany?.name,
          lifetimeValue: subscriptionProducts?.products[0].lifetime_value
        });
        // we trigger successful payment anyway because  we want to let the user move on to use the app anyway
        toast.success(translate('payment_successful'));
        setShowPaymentModal(false);
        setMessage(translate('payment_successful'));
        setErrorMessage('');
        setIsLoading(false);
      }
    }
  }, [stripe, elements, subscriptionProducts, createSubscription]);

  /**
   * Options for configuring the layout of the Stripe Payment Element.
   * The `layout` option is set to `'tabs'` to display the payment options in a tabbed layout.
   */
  const paymentElementOptions = {
    layout: ('tabs' as Layout),
    terms: {
      card: 'never'
    },
    defaultValues: {
      // billingDetails: {
      //   email: userMe?.email || '',
      // },
    },
    fields: {
      billingDetails: {
        address: {
          country: 'never'
        }
      }
    }
  };
  return <form className={styles['payment-form']} onSubmit={handleSubmit} data-sentry-component="StripeCheckout" data-sentry-source-file="StripeCheckout.tsx">
      <PaymentElement className={styles['payment-element']} options={(paymentElementOptions as StripePaymentElementOptions)} data-testid="payment-element" data-sentry-element="PaymentElement" data-sentry-source-file="StripeCheckout.tsx" />

      {/* <input
        type="text"
        placeholder={translate('enter_promo_code')}
        value={promoCode}
        className={styles['promo-code']}
        onChange={(e) => setPromoCode(e.target.value)}
       /> */}

      <button disabled={isLoading || !stripe || !elements} id="submit" type="submit" className={styles['payment-button']} data-testid="submit-button">
        {isLoading ? translate('processing') : <>
            {translate('start_your_free_trial')}
            <MoveRight size={20} />
          </>}
      </button>
      <div className={styles['money-back-guarantee']}>
        <ShieldCheck size={20} data-sentry-element="ShieldCheck" data-sentry-source-file="StripeCheckout.tsx" />
        {translate('money_back_guarantee')}
      </div>
      {(message || errorMessage) && <div className={errorMessage ? styles['payment-message-error'] : styles['payment-message']}>
          {message || errorMessage}
        </div>}
      <div className={styles.stripePopupTextContainer}>
        <p>
          {translate('fill_out_your_payment_details_to_start_your_14_day_free_trial_of_lucid')}
        </p>
      </div>

      <div className={styles.iconsSection}>
        <Image src={stripeIcon.src} alt="stripe" width={150} height={150} data-sentry-element="Image" data-sentry-source-file="StripeCheckout.tsx" />
        <div className="flex items-center">
          <Image src={ccpaIcon.src} alt="ccpa" width={50} height={50} data-sentry-element="Image" data-sentry-source-file="StripeCheckout.tsx" />

          <Image src={guarranteIcon.src} alt="guarrante" width={70} height={70} data-sentry-element="Image" data-sentry-source-file="StripeCheckout.tsx" />
        </div>
      </div>
    </form>;
};
export default StripeCheckout;