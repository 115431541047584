import { MoveRight } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidUrl } from '@/utils/regex';
import type { CompanyWebsite } from '../../onboardingPopupTypes';
import styles from '../CompanyNameOrIntegrationsStage/companyNameOrIntegrationsStage.module.scss';
import { OnboardingBreadcrumbs } from '../NewCompanySetupStage/NewCompanySetupStage';

/**
 * Renders the CompanyWebsiteStage component, which allows the user to input their company's website.
 *
 * @param data - The current value of the company website input.
 * @param onChange - A function to be called when the company website input value changes.
 * @param resetNextStepData - An optional function to be called to reset data for the next step.
 * @param nextStep - An optional function to be called when the user presses the Enter key and the input value is not empty.
 * @returns A React component that renders the company website input field.
 */
const CompanyWebsiteStage = ({
  data,
  onChange,
  nextStep,
  breadcrumbPath
}: {
  data: string;
  onChange: (data: CompanyWebsite) => void;
  nextStep?: () => void;
  breadcrumbPath?: Array<{
    label: string;
  }>;
}) => {
  const {
    t: translate
  } = useTranslation('company');
  const [value, setValue] = useState(data || '');
  const [errorText, setErrorText] = useState('');

  // Handle value change
  const handleValueChange = (e: any) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (isValidUrl(newValue)) {
      setErrorText('');
    }
    onChange({
      website: newValue
    });
  };
  return <div className="relative h-full w-full overflow-y-auto overflow-x-hidden" data-testid="company-website" data-sentry-component="CompanyWebsiteStage" data-sentry-source-file="CompanyWebsiteStage.tsx">
      {breadcrumbPath && <OnboardingBreadcrumbs path={breadcrumbPath} />}
      <div className={styles.companyWebsiteStageTitle}>
        <h1>{translate('company_website_stage_title')}</h1>
        <div className={styles.descriptionContainer}>
          <p>
            <span>
              <MoveRight data-sentry-element="MoveRight" data-sentry-source-file="CompanyWebsiteStage.tsx" /> {translate('company_website_stage_description_1')}{' '}
              <br />
            </span>
            <span>
              <MoveRight data-sentry-element="MoveRight" data-sentry-source-file="CompanyWebsiteStage.tsx" /> {translate('company_website_stage_description_2')}{' '}
              <br />
            </span>
            <span>
              <MoveRight data-sentry-element="MoveRight" data-sentry-source-file="CompanyWebsiteStage.tsx" /> {translate('company_website_stage_description_3')}
            </span>
          </p>
        </div>
      </div>
      <div className="relative flex h-full w-full flex-col items-center justify-center">
        <div className={styles.addNameInputContainer}>
          <input data-testid="website-stage-input" type="text" className={`${styles.addNameInput}
            
            ${isValidUrl(value) ? styles.validInput : ''}
            ${errorText ? styles.invalidInput : ''}
          `} style={{
          borderBottomWidth: '2px',
          borderBottomStyle: 'solid'
        }} placeholder={translate('enter_company_website')}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus value={value} onKeyDown={e => {
          // Handle Enter key press
          if (e.key === 'Enter' && value.length > 0) {
            if (!isValidUrl(value)) {
              setErrorText(translate('invalid_website'));
            } else {
              nextStep?.();
            }
          }
        }} onChange={e => handleValueChange(e)} />
          {errorText && <div className={styles.invalidInput} data-testid="error-text">
              {errorText}
            </div>}
        </div>
      </div>
    </div>;
};
export default CompanyWebsiteStage;