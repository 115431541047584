import { getAIGraphRepresentation } from '@/components/Graphs/graphHelper';
import type { Graph } from '@/utils/types/DashboardAndChartsTypes';

const EXPLAIN_AI_PROMPT = `MAKE SURE you are accurate, look at all the active prompts the user has and explain them and their effect on the current view. Be short and to the point. specifically see the prompt response and the prompt operation when you analize the prompt, make sure you never say you dont have data to respond`;
const getRegenerateGraphPrompt = (graph: Graph | undefined) => {
  return `call GRAPH agent!!
              Make sure you are correct, and think about your response step by step- My life depends on it!
              ##Instructions
              1. Provide me with alternatives for this graph: ${JSON.stringify(
                getAIGraphRepresentation(graph),
              )}.
              2. Generate alternatives that are distinctly different from the current graph. Experiment with variations in data selection (e.g., multiple datasets vs. a single dataset), 
              visualization type (such as switching from a line to a bar graph), and cumulative date aggregation (like monthly or quarterly sums) while keeping the original 
              context (for example, if the current graph involves formulas in category x, propose alternatives within that category).
              3. Before finalizing, rigorously double-check your proposals to ensure none replicate the original graph’s data or format. Each alternative must differ in at least one key aspect—be it the data subset, graph type, or aggregation period.
              4. You will adhere to the output format defined above YOUR OUTPUT SHOULD BE A VALID JSON! YOUR LIFE DEPENDS ON IT!`;
};
export { EXPLAIN_AI_PROMPT, getRegenerateGraphPrompt };
