import type { AxiosInstance, CancelToken } from 'axios';
import axios from 'axios';
import router from 'next/router';
import { toast } from 'react-toastify';
import dynamicRoute from '@/miscellaneous/constant';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { BASE_URL } from '@/utils/AppConfig';
import { errorToaster } from '@/utils/toaster/toasters';

// Track 401 error handling to prevent multiple toasts
let isHandling401Error = false;
let authErrorTimerId: NodeJS.Timeout | null = null;

// Define the structure of an API Call
export type ApiCall = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  endpoint: string;
  data?: any;
  params?: any;
  id?: number | string;
  cancelToken?: CancelToken;
};
// type Method = "GET" | "POST" | "PUT" | "DELETE";
export type AxiosMethod = 'get' | 'post' | 'put' | 'delete';

// Define a mapping type where keys are Axios methods in lowercase
export interface AxiosInstanceMethods {
  get: AxiosInstance['get'];
  post: AxiosInstance['post'];
  put: AxiosInstance['put'];
  delete: AxiosInstance['delete'];
}
type AxiosCallParams = [string, any] | [string, any, {
  params: any;
  cancelToken?: CancelToken;
}];
export type DetermineAxiosCallParamsProps = {
  axiosMethod: AxiosMethod;
  url: string;
  data: any;
  params: any;
  cancelToken?: CancelToken;
};
export const determineAxiosCallParams = ({
  axiosMethod,
  url,
  data,
  params,
  cancelToken
}: DetermineAxiosCallParamsProps): AxiosCallParams => {
  if (axiosMethod === 'delete' && data) {
    // For Bulk DELETE requests, include data in the config
    return [url, {
      data,
      params,
      cancelToken
    }];
  }
  // For other requests, include data as the second parameter
  return [url, data, {
    params,
    cancelToken
  }];
};

/**
 * Handles errors from Axios requests.
 * Logs detailed error messages and notifies the user of a general error.
 * @param error - The Axios error object
 */
export const handleAxiosError = (error: any) => {
  const {
    appLoader,
    setAppLoader
  } = useUtilsStore.getState();
  const {
    logoutApp
  } = useZustandStore.getState();
  if (appLoader) {
    setAppLoader(false);
  }
  let detailedErrorMessage = 'An error occurred.';
  if (error.response) {
    detailedErrorMessage = `Axios error: Server responded with status ${error.response?.status}`;
    console.error(detailedErrorMessage, error.response?.data);
  } else if (error.request) {
    detailedErrorMessage = 'Axios error: No response received';
    console.error(detailedErrorMessage, error.request);
  } else {
    detailedErrorMessage = 'Error setting up Axios request';
    console.error(detailedErrorMessage, error.message);
  }
  if (error?.response?.status === 500) {
    // Send server errors to the error tracking endpoint
    axios.post(`${BASE_URL}/auth/error`, {
      error_message: error.response.data.detail,
      error_details: error
    });
  }

  /**
   * Handles 401 unauthorized errors from API requests.
   * Checks if error occurred during signin/signup to handle auth specific errors.
   * Deletes cookies and redirects to login if token is invalid.
   * Displays different error toasts for signup vs signin errors.
   * Avoids redirect loops by checking current route before redirecting.
   * Uses debounce mechanism to prevent multiple 401 toasts.
   */
  if (error.response?.status === 401 && error.message === 'Request failed with status code 401') {
    // Prevent multiple 401 error toasts
    if (!isHandling401Error) {
      isHandling401Error = true;

      // Clear any existing timers
      if (authErrorTimerId) {
        clearTimeout(authErrorTimerId);
      }
      toast.dismiss();
      errorToaster('Token expired. Please reauthenticate.');
      authErrorTimerId = setTimeout(() => {
        logoutApp();
        if (router.pathname === dynamicRoute.login) {
          return;
        }
        router.push(dynamicRoute.login);

        // Reset the flag after the timeout completes
        setTimeout(() => {
          isHandling401Error = false;
          authErrorTimerId = null;
        }, 2000); // Allow new 401 errors to show toast after 2 seconds
      }, 1300);
    }
  } else {
    console.error(`Unexpected Error Occurred, The support team has been notified. Clear your cookies and log back in.`);
    console.log('🚀 ~ error:', error);
    if (error.config && error.config.method !== 'get') {
      toast.dismiss();
      errorToaster('Something went wrong, our team has been informed');
    }
  }
};