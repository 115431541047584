/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-cycle */
import { FaRegClone } from 'react-icons/fa6';
import { MdDelete, MdModeEditOutline } from 'react-icons/md';
import { getGraphConfig } from '@/miscellaneous/constant/chartConfigContants/sharedConfig';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import type { OutputValues } from '@/miscellaneous/store/calulcationsStoreHelper';
import { generateCumulativeDateValuesFunc, getOutputValues } from '@/miscellaneous/store/calulcationsStoreHelper';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { chartData, timePeriodData } from '@/styles/variables/constant';
import { iconSizes } from '@/styles/variables/sizes';
import { getIsoString, parseUTCDateObject } from '@/utils/dateUtils';
import type { Output } from '@/utils/hooks/Outputs/useOutputs';
import type { ChartConfigProps, ChartTypesManual, DataGroup, DataGroupType, DataPoint, DonutConfigProps, Graph } from '@/utils/types/DashboardAndChartsTypes';
import { type Formula, FormulaInputType } from '@/utils/types/formulaTypes';
import type { TimePeriodType } from '@/utils/types/types';
// src/utils/chartUtils.ts

// Function to set type and configType in the state
export function createChartData(configType: string) {
  const chart = chartData.find(chartItem => chartItem.configType === configType);
  return {
    type: chart?.type,
    configType,
    label: chart?.label
  };
}
// Function to set time period in the state
export function getTimePeriod(timeType: string | undefined) {
  const timeData = timePeriodData.find(chartItem => chartItem?.type === timeType);
  return timeData;
}

// Create a function to remove properties
export function removeProperties(obj: any, propertiesToRemove: string[]) {
  const newObj: any = {};
  for (const key of Object.keys(obj)) {
    if (!propertiesToRemove.includes(key)) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

// Define the properties to remove
export const propertiesToRemove = ['data_group_id', 'graph_id', 'id'];
/**
 * Formats a given date to match the format used in the keys of the account values map object.
 *
 * @param {string} date - The input date in string format.
 * @returns {string} The formatted date in 'YYYY-MM-01' format.
 */
export const formateDateToMatchISOString = (date: string): string => {
  const inputDate = parseUTCDateObject(date);
  return getIsoString(inputDate);
};
/**
 * Generates an array of formatted dates between the given start and end timestamps.
 *
 * @function
 *
 * @param {number} startDateTimestamp - The timestamp representing the start date.
 * @param {number} endDateTimestamp - The timestamp representing the end date.
 * @returns {string[]} - An array of formatted dates in the format "MM/DD/YYYY".
 */
export const chartDateHelper = (startDateTimestamp: any, endDateTimestamp: any) => {
  const dates = [];
  const currentDate = parseUTCDateObject(startDateTimestamp);
  // Loop through dates from start to end
  while (currentDate <= parseUTCDateObject(endDateTimestamp)) {
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;
    // Add formatted date to the array
    dates.push(formattedDate);

    // Move to the next day
    currentDate.setMonth(currentDate.getMonth() + 1);
  }
  return dates;
};

/**
 * Retrieves the graph values based on the provided list of data points or data groups.
 *
 * @param list - The list of data points or data groups to retrieve the graph values for.
 * @returns An object containing the default total date values, the map of values, and the type of the list (formula or output).
 */
export const getGraphsValues = ({
  list,
  isCulmutative = false
}: {
  list: DataGroupType | DataPoint | undefined;
  isCulmutative?: boolean;
}) => {
  const {
    getAccountValuesByDateRange,
    formulaEntries
  } = CalculationsStore.getState();
  let output: Output | undefined;
  let showTotalAccountValues = false;
  const listType = (list as DataPoint)?.group || (list as DataPoint)?.formula_id ? 'formula' : 'output';
  const id = listType === 'output' ? (list as DataPoint)?.output_id || (list as DataPoint)?.id : (list as DataPoint)?.formula_id || (list as DataGroupType)?.id;
  const map = listType === 'formula' ? formulaEntries[id] : getAccountValuesByDateRange({
    outputID: id
  });
  let currentOutputValues: {
    [date: string]: number;
  } = {};
  if (listType === 'output') {
    output = (map?.output as Output);
    showTotalAccountValues = !output?.parent_output;
    currentOutputValues = (showTotalAccountValues ? (map?.outputTotalValues as {
      [date: string]: number;
    }) ?? {} : getOutputValues((map as OutputValues))) || {};
  }
  let seriesValues: {
    [date: string]: number;
  } = {};
  if (listType === 'formula') {
    // For formula type, ensure we have a proper date-value mapping
    seriesValues = Object.entries(map || {}).reduce((acc, [date, value]) => {
      if (date !== 'to_account' && !Number.isNaN(Number(value))) {
        acc[date] = Number(value);
      }
      return acc;
    }, ({} as {
      [date: string]: number;
    }));
  } else {
    seriesValues = currentOutputValues;
  }

  // Apply cumulative calculation using the existing helper function
  seriesValues = isCulmutative ? generateCumulativeDateValuesFunc(seriesValues) : seriesValues;
  return {
    defaultTotalDateValues: seriesValues,
    map,
    listType
  };
};
export function getSumValues({
  timePeriod,
  dates,
  list,
  isCulmutative = false
}: {
  timePeriod: TimePeriodType | undefined;
  list: DataGroupType | DataPoint | undefined;
  dates: (string | undefined)[];
  isCulmutative?: boolean;
}) {
  let sums: number[] = [];
  const {
    defaultTotalDateValues
  } = getGraphsValues({
    list,
    isCulmutative
  });
  switch (timePeriod?.type) {
    case 'is_quarterly':
      {
        const quarterTotals = dates.reduce((acc: {
          [key: string]: number;
        }, date) => {
          const [year, month] = date?.split('-') || [];
          if (year && month) {
            const quarterNum = Math.ceil(parseInt(month, 10) / 3);
            const quarterKey = `${year}-Q${quarterNum}`;
            acc[quarterKey] = (acc[quarterKey] || 0) + (defaultTotalDateValues[(date as string)] || 0);
          }
          return acc;
        }, {});
        sums = Object.values(quarterTotals);
        break;
      }
    case 'is_monthly':
      sums = dates.map(date => (defaultTotalDateValues[(date as string)] as number) ?? 0);
      break;
    case 'is_annualy':
      {
        const yearTotals = dates.reduce((acc: {
          [key: string]: number;
        }, date) => {
          const year = date?.split('-')[0];
          if (year) {
            acc[year] = (acc[year] || 0) + (defaultTotalDateValues[(date as string)] || 0);
          }
          return acc;
        }, {});
        sums = Object.values(yearTotals);
        break;
      }
    default:
      // Handle other cases if needed
      break;
  }
  if ((list as DataGroupType)?.invert) {
    sums = sums.map(value => -value);
  }
  return {
    sums
  };
}
function modifyData(chartType: ChartTypesManual, dataGroup: DataGroupType[] | DataGroup[] | undefined, dates: string[], timePeriod?: TimePeriodType, isCulmutative = false) {
  let series: any = [];
  let modifiedDates: (string | undefined)[] = [];
  const groupData = dataGroup?.flatMap(group => (group as DataGroupType).dataList || (group as DataGroup).data_points);
  if (chartType === 'BarCombo' || chartType === 'StackedCombo') {
    series = dataGroup?.map((group, index) => {
      return ((group as DataGroupType).dataList || (group as DataGroup).data_points)?.map(list => {
        const {
          sums
        } = getSumValues({
          timePeriod,
          dates,
          list,
          isCulmutative
        });
        return {
          type: index === 0 ? 'column' : 'line',
          name: list?.name || '',
          data: sums
        };
      });
    });
    series = series?.flatMap((d: any) => d);
  } else if (chartType === 'GroupedStackedCombo') {
    series = dataGroup?.map((group, index) => {
      return ((group as DataGroupType).dataList || (group as DataGroup).data_points)?.map(list => {
        const {
          sums
        } = getSumValues({
          timePeriod,
          dates,
          list,
          isCulmutative
        });
        return {
          type: 'column',
          name: list?.name || '',
          group: index === 0 ? 'Group 1' : 'Group 2',
          data: sums
        };
      });
    });
    series = series?.flatMap((d: any) => d);
  } else {
    series = groupData?.map(list => {
      const {
        sums
      } = getSumValues({
        timePeriod,
        dates,
        list,
        isCulmutative
      });
      return {
        name: list?.name || '',
        data: sums
      };
    });
  }
  // Extract graph colors from the data groups
  const graphColors = groupData?.map(list => list.color);
  if (timePeriod) {
    switch (timePeriod?.type) {
      case 'is_quarterly':
        for (let i = 0; i < dates.length; i += 3) {
          const firstDate = dates[i];
          modifiedDates.push(firstDate);
        }
        break;
      case 'is_monthly':
        modifiedDates = dates;
        break;
      case 'is_annualy':
        {
          modifiedDates = [...Array.from(new Set(dates.map(date => date.split('-')[0])))].map(year => dates.reduce((latest, date) =>
          // @ts-ignore
          date.startsWith(year) && date > latest ? date : latest, ''));
          break;
        }
      default:
        // Handle other cases if needed
        break;
    }
  }
  // modifiedDates =
  //   modifiedDates?.length > 5 ? modifiedDates.slice(0, 5) : modifiedDates;
  series = series && series.map((item: any) => ({
    ...item,
    data: item.data
  }));
  return {
    groupData,
    series,
    graphColors,
    modifiedDates
  };
}

/**
 * Get dynamic series data for a donut chart based on the provided configuration.
 *
 * @param {Object} config - Configuration object for the donut chart.
 * @param {Array} config.dataGroup - Array of data groups containing data points.
 * @param {Array} config.dates - Array of date strings.
 * @param {Object} config.timePeriod - Configuration object specifying the time period.
 * @param {string} config.timePeriod.type - Type of time period ('is_annualy', 'is_quarterly', 'is_monthly').
 * @returns {Object} - Object containing donut dynamic series data.
 * @returns {Array} .donutDynamicSeries - Array of numbers representing sums for each data point.
 *
 */
function getDonutSeries({
  dataGroup,
  dates,
  timePeriod,
  isCulmutative = false
}: DonutConfigProps) {
  // Extract data points from the data groups
  const groupData = dataGroup?.flatMap(group => (group as DataGroupType).dataList || (group as DataGroup).data_points);
  const donutDynamicSeries = groupData?.map(list => {
    const {
      defaultTotalDateValues
    } = getGraphsValues({
      list,
      isCulmutative
    });
    let sums: number[] = [];

    // filter out the to_account key
    const validKeys = Object.keys(defaultTotalDateValues).filter(key => key !== 'to_account');

    // Only include dates that have corresponding values to prevent NaN in calculations
    const filteredDates = dates.filter(date => validKeys.includes(date));

    // eslint-disable-next-line no-unreachable-loop
    for (let i = 0; i < filteredDates.length; i += timePeriod?.type === 'is_annualy' ? 12 : timePeriod?.type === 'is_quarterly' ? 3 : 1) {
      const periodDates = timePeriod?.type === 'is_annualy' ? filteredDates.slice(i, i + 12) : timePeriod?.type === 'is_quarterly' ? filteredDates.slice(i, i + 3) : [filteredDates[i]];
      const sum = periodDates.reduce((acc, date) => acc + ((defaultTotalDateValues[date || 0] as number) || 0), 0);
      sums.push(sum);
      break;
    }
    if (list?.invert) {
      sums = sums.map(value => -value);
    }
    return sums[0];
  });
  return {
    donutDynamicSeries: donutDynamicSeries?.map(value => value ?? 0)
  };
}

/**
Generates configuration for different types of charts based on specified parameters.
This function constructs a chart configuration object tailored to the provided chart type.
@param {ChartTypesManual} chartType - The type of chart to generate, such as 'Line', 'Bar', etc.
@param {string} [startDate] - The start date for the chart. Optional.
@param {string} [endDate] - The end date for the chart. Optional.
@param {DataGroupType[] | DataGroup[]} [dataGroup] - An array of data groups for chart data. Optional.
@returns {object} The configuration object for the specified chart type.
*/
export function getChartConfig(options: ChartConfigProps) {
  const {
    chartType,
    startDate,
    endDate,
    dataGroup,
    timePeriod,
    isCulmutative,
    inputDates
  } = options;

  // Calculate dates for the chart based on the provided start and end dates

  const dates = inputDates && inputDates?.length > 0 ? inputDates : chartDateHelper(startDate, endDate);
  const {
    groupData,
    series,
    graphColors,
    modifiedDates
  } = modifyData(chartType, dataGroup, dates, timePeriod, isCulmutative);

  /**
   * Common function to get configuration for line, bar, and stacked charts.
   *
   * @param {'line' | 'bar' | 'stacked'} type - The type of chart ('line', 'bar', 'stacked').
   * @returns {object} - The chart configuration object.
   */
  const commonConfig = (type: 'line' | 'bar' | 'stacked' | 'donut' | 'barCombo' | 'StackedCombo' | 'GroupedStackedCombo' | 'area', dynamicSeries?: (number | undefined)[] | undefined, labels?: string[]) => getGraphConfig(type, {
    dates: modifiedDates,
    dynamicSeries: dynamicSeries || series,
    graphColors,
    labels,
    timePeriodType: timePeriod?.type
  }, dataGroup);
  const {
    donutDynamicSeries
  } = getDonutSeries({
    dataGroup,
    dates,
    timePeriod,
    isCulmutative
  });

  // const donutDynamicSeries = Array(groupData?.length).fill(5);
  const donutLabels = groupData?.map(list => list.name || '');

  // Switch statement to determine the chart type and generate the corresponding configuration
  switch (chartType) {
    case 'Line':
      return commonConfig('line');
    case 'Area':
      return commonConfig('area');
    case 'Bar':
      return commonConfig('bar');
    case 'BarCombo':
      return commonConfig('barCombo');
    case 'Stacked':
      return commonConfig('stacked');
    case 'StackedCombo':
      return commonConfig('StackedCombo');
    case 'GroupedStackedCombo':
      return commonConfig('GroupedStackedCombo');
    case 'donut':
      // Configuration for the 'donut' chart.
      return commonConfig('donut', donutDynamicSeries, donutLabels);
    case 'rankedList':
      return groupData?.map((list, index) => ({
        name: list.name,
        value: donutDynamicSeries?.[index] || 0
      }));
    case 'Scorecard':
      return groupData?.map((list, index) => ({
        name: list.name,
        value: donutDynamicSeries?.[index] || 0
      }));
    default:
  }
}

/**
 * Generates an array of menu items for a chart context menu.
 *
 * @function
 *
 * @param {Object} t - The translation function for internationalization.
 * @returns {Array} - An array of menu items with properties like `id`, `icon`, and `itemName`.
 */
export function getMenuItems(t: any) {
  return [{
    id: 1,
    icon: <MdModeEditOutline size={iconSizes.lg} className="mx-0.5 !text-gray-500" />,
    itemName: t('addEditChartPopup:edit_chart')
  }, {
    id: 2,
    icon: <FaRegClone size={iconSizes.lg} className="mx-0.5 !text-gray-500" />,
    itemName: t('addEditChartPopup:clone_chart')
  }, {
    id: 4,
    icon: <MdDelete size={iconSizes.lg} className="mx-0.5 !text-gray-500" />,
    itemName: t('addEditChartPopup:delete_chart')
  }];
}

// this function recieves a data groups and adds a name to each data point
export function addNamesToDataPoints(item: DataGroup, outputData: Output[] | undefined, formulas: Formula[] | undefined) {
  const updatedDataPoints = item.data_points.map((point: DataPoint) => {
    let name = '';
    if (point.output_id) {
      // if the point is related to an output get output name
      name = outputData?.find((output: Output) => output.id === point.output_id)?.name ?? 'Unknown Variable';
    }
    if (point.formula_id) {
      // if the point is related to a formula get formula name
      const formula = formulas?.find((curFormula: Formula) => curFormula.id === point.formula_id);
      name = formula?.name ?? 'Unknown Variable';
    }
    return {
      ...point,
      name
    };
  });

  // Return the updated object
  return {
    ...item,
    data_points: updatedDataPoints
  };
}

// This function transforms API data into the desired format.
// It adds an output value name so that we can display it in the series name.
export function transformGraphData(graph: Graph, outputData: Output[] | undefined, startDate: string, endDate: string, formulas: Formula[] | undefined, isCulmutative = false, dates: string[] = [] // optional parameter to override the custom dates given by the system
) {
  //  Filters out data groups that are marked as data highlights.
  const dataGroup = graph?.data_groups?.filter(group => !group.data.is_data_highlights);
  // Updates the data points within each data group by adding a 'name' property to each data point.
  // The 'name' property is obtained by matching the output_id of each data point with the id of the corresponding outputData.
  const updatedData = dataGroup.map(item => {
    // Map over the data_points array and add the 'name' property to each object
    return addNamesToDataPoints(item, outputData, formulas);
  });

  //  Generates a new chart configuration using the provided parameters.
  const newChartConfig = getChartConfig({
    chartType: (graph?.graph_data?.type as ChartTypesManual) || 'GroupedStackedCombo',
    startDate,
    endDate,
    dataGroup: updatedData,
    timePeriod: getTimePeriod(graph?.graph_data?.is_monthly ? 'is_monthly' : graph?.graph_data?.is_quarterly ? 'is_quarterly' : graph?.graph_data?.is_annualy ? 'is_annualy' : 'is_monthly' // if not found, default to 'is_monthly'
    ),
    isCulmutative,
    inputDates: dates
  });
  return {
    newChartConfig
  };
}

/**
 * Extracts the values of a specified key from the data points in a data group.
 *
 * @param dataGroup - An array of data groups, where each data group contains an array of data points.
 * @param key - The key to extract the values from in each data point.
 * @returns An array of the extracted values, where each value corresponds to the value of the specified key in the respective data point. If a data point does not have the specified key, `null` is returned for that data point.
 */
function extractIdsFromDataGroup(dataGroup: DataGroup[], key: string): (number | null)[] {
  return dataGroup.flatMap(group => group?.data_points?.map((point: any) => point?.[key] ?? null));
}

/**
 * Retrieves the formula types for the given formula IDs.
 *
 * @param formulaIds - An array of formula IDs to retrieve the formula types for.
 * @returns An array of formula types, where each element corresponds to the formula type for the respective formula ID in the input array.
 */
export const getFormulaType = (formulaIds: number[]): string[] => {
  const {
    formulaIDToFormulaMap
  } = useZustandStore.getState();
  const {
    HPformulas
  } = CalculationsStore.getState();
  const formulasFromMap = formulaIds.map(id => formulaIDToFormulaMap[id]).filter(Boolean);
  const formulasFromHP = HPformulas.filter((formula: any) => formulaIds.includes(formula.id));
  const allFormulas = [...formulasFromMap, ...formulasFromHP];
  const formulaTypes: string[] = allFormulas.map((formula: Formula | undefined) => formula?.input_type || '');
  return formulaTypes;
};

/**
 * Determines the sign to use for the graph based on the data group.
 * If the data group contains any currency-type formulas or has an output ID, the sign will be returned as an empty string.
 * Otherwise, the sign will be returned as an empty string.
 *
 * @param dataGroup - The data group to analyze.
 * @returns The sign to use for the graph, or an empty string if the sign should not be displayed.
 */
export function getSignForGraph(dataGroup: DataGroup[]): string {
  const formulaIds = extractIdsFromDataGroup(dataGroup, 'formula_id').filter((id): id is number => id !== null);
  const formulaTypes = getFormulaType(formulaIds);
  const hasCurrencyType = formulaTypes.includes(FormulaInputType.Currency);
  const isOutputExist = extractIdsFromDataGroup(dataGroup, 'output_id').some(id => id !== null);
  return hasCurrencyType || isOutputExist ? '$' : '';
}
export function getAIGraphRepresentation(graph: Graph | undefined) {
  if (!graph) {
    return null;
  }
  return {
    name: graph.graph_data.name,
    type: graph.graph_data.type,
    time_period: graph.graph_data.is_monthly ? 'monthly' : graph.graph_data.is_quarterly ? 'quarterly' : graph.graph_data.is_annualy ? 'annually' : 'unknown',
    formulas: graph.data_groups.flatMap(group => group.data_points.map(point => point.formula_id))
  };
}