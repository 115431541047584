// You might need to import this for date formatting
import numeral from 'numeral';

import colors from '@/styles/scss/abstracts/_variables.module.scss';

// eslint-disable-next-line import/no-cycle
import styles from '../components/Graphs/AddEditChart/addEditChartPopup.module.scss';
import type {
  ChartType,
  DataGroupType,
  Graph,
  MinMaxValues,
  SeriesItem,
  ValidChartType,
} from './types/DashboardAndChartsTypes';
import type { ItemType } from './types/types';

export const resizableChartHW = {
  min: 200,
  max: 700,
  fixHeight: 88,
  fixWidth: 134,
  minW: 615,
  minH: 355,
};
/**
 * Handles the configuration and management of graph data for a dashboard chart.
 *
 * @param {DataGroupType[]} dataGroup - An array of data group objects.
 * @param {ItemType[]} dataListHighLights - An array of highlighted data items.
 * @param {Graph | undefined} graphData - The graph data object, which can be undefined.
 * @param {number} dashboardId - The id of the dashboard.
 * @param {string} rename - The name to be given to the graph.
 * @param {ChartType} chartType - The type of chart being used.
 * @param {boolean} isForecastActuals - Indicates whether the graph shows forecast vs. actuals.
 * @param {any} timePeriod - The selected time period for the graph.
 * @param {boolean} isSwitchHide - Indicates whether the single scenario is hidden.
 * @param {UseMutationResult<void, unknown, any, unknown>} updateGraph - A mutation result for updating the graph.
 * @param {UseMutationResult<void, unknown, any, unknown>} createGraph - A mutation result for creating a new graph.
 */

export const handleGraph = (
  dataGroup: DataGroupType[],
  dataListHighLights: ItemType[],
  graphData: Graph | undefined,
  dashboardId: number | undefined,
  branchId: number | undefined,
  rename: string,
  chartType: ChartType,
  isForecastActuals: boolean,
  timePeriod: any,
  isSwitchHide: boolean,
  updateGraph: { mutate: (params: any) => void },
  createGraph: { mutate: (params: any) => void },
  resetStore: any,
  isCulmutative: boolean,
) => {
  const groupAndHighlights = [];
  const getFormulaId = (item: ItemType) =>
    item.formula_id || (item.group ? item.id : null);
  const getOutputId = (item: ItemType) =>
    item?.output_id || (item.group ? null : item.id);

  dataGroup?.forEach((group) => {
    groupAndHighlights.push({
      data: {
        is_data_highlights: false,
        id: group?.group_id,
        graph_id: graphData?.options?.[0]?.graph_id,
      },
      data_points: group?.dataList?.map((item) => ({
        sign: 0,
        color: item.color,
        output_id: getOutputId(item),
        formula_id: getFormulaId(item),
        id: item?.list_id,
        graph_id: graphData?.options?.[0]?.graph_id,
        data_group_id: item?.data_group_id,
      })),
    });
  });

  groupAndHighlights.push({
    data: {
      id: dataListHighLights?.[0]?.group_id,
      graph_id: graphData?.options?.[0]?.graph_id,
      is_data_highlights: true,
    },
    data_points: dataListHighLights?.map((item) => ({
      sign: 0,
      color: item.color || styles.colorPalette1,
      output_id: getOutputId(item),
      formula_id: getFormulaId(item),
      id: item?.list_id,
      graph_id: graphData?.options?.[0]?.graph_id,
      data_group_id: item?.data_group_id,
    })),
  });

  // An object representing the complete configuration for a dashboard chart, which will be sent at the time of the ADD/Edit operation with a graph
  const body = {
    graph_data: {
      id: graphData?.options?.[0]?.graph_id,
      name: rename,
      dashboard_id: dashboardId,
      branch_id: branchId,
      type: chartType?.configType,
      is_forecast_vs_actuals: isForecastActuals,
      is_single_scenario: isSwitchHide,
      is_monthly: timePeriod?.type === 'is_monthly',
      is_quarterly: timePeriod?.type === 'is_quarterly',
      is_annualy: timePeriod?.type === 'is_annualy',
      is_culmutative: isCulmutative,
    },
    options: [
      {
        variable_ids: '1',
        planned_vs_actuals: isForecastActuals,
        time_scale: 'string',
        all_branches: isForecastActuals,
        max_value: 0,
        total_label: true,
        row_count: 0,
        id: graphData?.options?.[0]?.id,
        graph_id: graphData?.options?.[0]?.graph_id,
      },
    ],
    layout_data: [
      {
        id: graphData?.layout_data?.[0]?.id,
        graph_id: graphData?.options?.[0]?.graph_id,
        lg: resizableChartHW.minW,
        sm: resizableChartHW.minH,
      },
    ],
    data_groups: groupAndHighlights,
  };
  if (graphData && Object.keys(graphData)?.length > 0) {
    updateGraph.mutate(body);
    resetStore();
  } else {
    createGraph?.mutate(body);
    resetStore();
  }
};

interface ConfigPropsDataItem {
  name: string;
  data: number[];
}

export interface GraphConfig {
  configPropsData: ConfigPropsDataItem[];
  yaxisLabels: string[];
  xaxisLabels: string[];
}

export const disableGraphHover = {
  states: {
    hover: {
      filter: {
        type: 'none',
        value: 0,
      },
    },
  },
};
export const disableGraphClick = {
  states: {
    ...disableGraphHover.states, // Reuse properties from disableGraphHover.states
    active: {
      allowMultipleDataPointsSelection: false,
      filter: {
        type: 'none',
        value: 0,
      },
    },
  },
};
export const colorPallette = [
  colors.midBlue,
  colors.vividOrange,
  colors.vermilion,
  colors.desaturatedBlue,
  colors.bluish,
  colors.softGreen,
  colors.midBlue3,
  colors.peach,
  colors.midBlueOpac,
  colors.manatee,
];

/**
 * Converts a number to a string representation with abbreviated units (K for thousands, M for millions, and B for billions).
 * @param {number} number - The number to be converted.
 * @returns {string} The string representation of the number with abbreviated units.
 */
export function convertToKAndM(number: number, currency: string = '$') {
  const formatedNumber =
    numeral(number).format('0.0a') === 'NaN'
      ? 0
      : numeral(number).format('0.0a').replace(/\.0$/, '');

  return `${currency}${formatedNumber}`.toUpperCase();
}
export const validChartTypes: ValidChartType[] = [
  'Line',
  'Bar',
  'BarCombo',
  'Stacked',
  'StackedCombo',
  'GroupedStackedCombo',
  'donut',
];
/**
 * Calculates the minimum and maximum values from a stacked series.
 * @param {SeriesItem[] | string[] | number[] | undefined} series - The stacked series data.
 * @returns {MinMaxValues} - Object containing the minimum and maximum values.
 */
export function stackedMinMax(
  series: SeriesItem[] | string[] | number[] | undefined,
): MinMaxValues {
  let minValue = Infinity;
  let maxValue = -Infinity;
  if (Array.isArray(series) && series.length > 0) {
    // Iterate over the first dataset's data array
    for (let i = 0; i < (series as SeriesItem[])?.[0]?.data?.length!; i += 1) {
      const sum =
        series && series?.length > 0
          ? (series as any[])?.reduce(
              (acc, dataset) => acc + (dataset as any).data[i],
              0,
            )
          : 0; // Sum corresponding elements from all series
      minValue = Math.min(minValue, sum as number);
      maxValue = Math.max(maxValue, sum as number);
    }
  }

  return { minValue, maxValue };
}
/**
 * Calculates the minimum and maximum values from a stacked combo series.
 * @param {SeriesItem[] | string[] | number[] | undefined} dynamicSeries - The stacked combo series data.
 * @returns {Record<string, number>} - Object containing the minimum and maximum values.
 */
export function stackedComboMinMax(
  dynamicSeries: SeriesItem[] | string[] | number[] | undefined,
): Record<string, number | null> {
  let minValue = Infinity;
  let maxValue = -Infinity;

  dynamicSeries?.forEach((item) => {
    if (item instanceof Object && 'data' in item) {
      const { data } = item;
      if (data.length > 0) {
        minValue = Math.min(minValue, ...data);
        maxValue = Math.max(maxValue, ...data);
      }
    }
  });

  minValue = minValue === Infinity ? 0 : minValue;
  maxValue = maxValue === Infinity ? 0 : maxValue;

  return { minValue, maxValue };
}

/**
 * Converts a formatted value string with 'K' or 'M' suffixes to a number.
 * @param formattedValue - The formatted value string to convert.
 * @param currency - The currency symbol to remove from the formatted value (optional).
 * @returns The numeric value represented by the formatted string.
 */
export function convertToNumberFromKAndMStr(
  formattedValue: string,
  currency: string = '$',
): number {
  if (formattedValue && formattedValue.replace) {
    const valueWithoutCurrency = formattedValue
      ?.replace(currency, '')
      ?.replace(/[^\d.km]/gi, '')
      ?.toLowerCase();
    if (valueWithoutCurrency.endsWith('k')) {
      return parseFloat(valueWithoutCurrency.replace('k', '')) * 1000;
    }
    if (valueWithoutCurrency.endsWith('m')) {
      return parseFloat(valueWithoutCurrency.replace('m', '')) * 1000000;
    }
    return parseFloat(valueWithoutCurrency) || 0;
  }

  return 0;
}

// Helper function to validate series data
export const validateSeriesData = (series: any): any => {
  // If series is empty array, return default data to prevent errors
  if (!series || series.length === 0) {
    return series;
  }

  // Ignore "Create New Graph" Placeholder
  if (series[0] === 1 && series.length === 1) {
    return series;
  }

  // If series is an array of numbers, transform it to required format
  if (typeof series[0] === 'number') {
    return [{ data: series }];
  }

  // Check if each item in the series array has a data property
  const isValid = series.every(
    (item: any) =>
      item &&
      (Array.isArray(item.data) ||
        (typeof item.data === 'object' && item.data !== null)),
  );

  // If not valid, return a properly formatted series
  if (!isValid) {
    return series.map((item: any) => ({
      ...(item || {}),
      data: Array.isArray(item?.data) ? item.data : [0],
    }));
  }

  return series;
};

// Check if all series are empty
export const checkSeriesIsEmpty = (series: any) => {
  if (!series || !Array.isArray(series)) return true;
  return series.every(
    (s: any) =>
      !s.data || !Array.isArray(s.data) || s.data.every((d: any) => d === 0),
  );
};
