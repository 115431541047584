import mixpanel from 'mixpanel-browser';

// Import Mixpanel SDK
import { vercelEnv } from './utils/AppConfig';

const mixpanelToken =
  vercelEnv === 'production'
    ? process.env.NEXT_PUBLIC_MIXPANEL_TOKEN_LIVE
    : process.env.NEXT_PUBLIC_MIXPANEL_TOKEN_DEV;

const mixpanelApiHost =
  vercelEnv === 'production'
    ? process.env.NEXT_PUBLIC_MIXPANEL_API_HOST_LIVE
    : process.env.NEXT_PUBLIC_MIXPANEL_API_HOST_DEV;

// Near entry of your product, init Mixpanel
mixpanel.init(mixpanelToken as string, {
  api_host: mixpanelApiHost,
  debug: true,
  autotrack: false,
  ignore_dnt: true,
  persistence: 'localStorage',
  record_sessions_percent: 50,
});

export default mixpanel;
