import type { User } from '@auth0/auth0-react';
import router from 'next/router';

import useZustandStore from './miscellaneous/store/zustand_store';
import mixpanel from './mixpanelConfig';
import { parseUTCDateObject } from './utils/dateUtils';

export const MIXPANEL_EVENTS = {
  ONBOARDING_STARTED: 'Onboarding Started',
  NEW_COMPANY_STARTED: 'NewCompany Started',
  PLAN_SELECTED: 'Plan Selected',
  GENERATE_CLICKED: 'Generate Clicked',
  FINISHED: 'Finished',
  CLOSED_POPUP: 'Closed Popup',
  LOGOUT_CLICKED: 'Logout Clicked',
  USER_LANDED_ON_PLATFORM: 'User Landed on the platform',
  FINISHED_FAILED: 'Finished Failed',
  BACK_BUTTON_CLICKED: 'Back Button Clicked',

  // Integration's Events
  INTEGRATION_VAULT_WAS_OPENED: 'Integration Vault Was Opened',
  INTEGRATION_VAULT_FINISHED_SUCCESSFULLY:
    'Integration Vault Finished Successfully',
  INTEGRATION_VAULT_WAS_REJECTED: 'Integration Vault Was Rejected',

  // Plan Export's Events
  PLAN_WAS_EXPORTED: 'Plan Was Exported',
  // AI's Events
  AI_QUICK_MENU_OPERATION_WAS_CALLED: 'AI Quick Menu Operation Was Called',
  AI_CALL_WAS_TALKED_TO: 'AI Call Was Talked To',
  AI_CALL_WAS_MADE: 'AI Call Was Made',
  AI_CALL_WAS_FAILED: 'AI Call Was Failed',
  AI_CALL_WAS_SUCCEEDED: 'AI Call Was Succeeded',
  PROMPT_WAS_REMOVED: 'Prompt Was Removed',
  AI_VIEW_ACCEPTED: 'AI View Accepted',
  AI_VIEW_REJECTED: 'AI View Rejected',
  AI_VIEW_MERGED_TO_NEW_SCENARIO: 'AI View Merged To New Scenario',
  // Template's Events
  TEMPLATE_APPLIED: 'Template Applied',
  TEMPLATE_DELETED: 'Template Deleted',
  TEMPLATE_VIEW_ACCEPTED: 'Template View Accepted',
  TEMPLATE_VIEW_REJECTED: 'Template View Rejected',
  // Payment's Events
  USER_VIEWED_PAYMENT_MODAL: 'User Viewed Payment Modal',
  USER_CLICKED_START_FREE_TRIAL: 'User Clicked Start Free Trial',
  STRIPE_PAYMENT_PROCESS_ERROR: 'Stripe Payment Process Error',
  BACKEND_PAYMENT_PROCESS_ERROR: 'Backend Payment Process Error',
  PAYMENT_PROCESS_SUCCESS: 'Payment Process Success',
  USER_LOGGED_OUT_FROM_PAYMENT_MODAL: 'User Logged Out From Payment Modal',
  MAKE_PAYMENT: 'Make Payment',

  // Access Error's Events
  ACCESS_ERROR_REPORTED: 'Access Error Reported',

  // Upload Sheet's Events
  UPLOAD_SHEET_STARTED: 'Upload Sheet Started',
  UPLOAD_SHEET_FINISHED: 'Upload Sheet Finished',
  UPLOAD_SHEET_FAILED: 'Upload Sheet Failed',

  // Automated A.I Plan Events
  AUTOMATED_AI_STARTED: 'Automated A.I Plan Started',
  AUTOMATED_AI_COMPLETED: 'Automated A.I Plan Completed',

  // Page View's Events
  PAGE_VIEW: 'Page View',

  // Edit Report's Events
  EDIT_REPORT: 'Edit Report Started',
} as const;

export const MIXPANEL_KEYS = {
  ONBOARDING: 'Onboarding',
  NEW_COMPANY: 'NewCompany',
} as const;

export const identifyUserForMixpanel = (user: User) => {
  mixpanel.people.set({
    $userId: user?.sub,
    $email: user?.email,
    $name: user?.name,
    $last_login: parseUTCDateObject(),
  });
};

export const mixpanelTrackEventWrapper = (
  mixPanelEventString: string,
  context: Record<string, any> = {},
) => {
  const page = router?.pathname?.split('/')?.[3];
  const { activeBranch, activeCompany } = useZustandStore.getState();

  const companyDetails = {
    companyId: activeCompany?.id,
    branchId: activeBranch?.id,
  };

  mixpanel.track(mixPanelEventString, { ...context, page, companyDetails });
};
