/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { isArray } from 'lodash';
import { type FormulaEntries, type FormulaEntry, getFormulaExpressionForDate } from '@/components/modelCalculations/modelUtils/modelUtils';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { getFormattedDateFromformulaString } from '@/utils/dateUtils';
import { formatNumber } from '@/utils/helpers/formattingHelper';
import { getParsedFormulas } from '@/utils/hooks/formulas/useFormulas';
import type { Formula } from '@/utils/types/formulaTypes';
import { FormulaGroupType } from '@/utils/types/formulaTypes';
import { sortItemsByFieldName } from '../shared/TableHelpers/sharedTableRowRenderUtils';
import { formulaIDToRowID, type TableRow, type TableRowType } from '../shared/types';
export function pushAddFormulaRows(t: any, result: TableRow[], model_id: number) {
  const addRowButton = {
    name: t('models:addInput'),
    id: 'add-input-row',
    type: 'button',
    sidebar: t('models:addInput'),
    subRows: [],
    rowStyle: {},
    output_id: null,
    model_id
  };

  /**
   * Updates each row object in the result array to add a button to add a new subrow
   * of the same group type. Loops through each row, clones the row, adds the
   * addRowButton object to the ≥, and returns the updated row.
   */
  result.forEach(row => {
    const generateButtonText = row.group?.toLowerCase() === 'inputs' ? t('models:addInput') : row.group?.toLowerCase() === 'calculations' ? t('models:addCalculation') : t('models:addOutput');
    const curButtonRow = {
      ...addRowButton,
      id: `add-${row.group}-row-${generateButtonText}-${row.id}-${model_id}`,
      group: row.group,
      sidebar: generateButtonText,
      name: generateButtonText
    };
    row?.subRows?.push((curButtonRow as TableRow));
  });
}

/**
 * Formats the value of a formula based on its input type.
 *
 * @param formula - The formula object containing the input type.
 * @param parsedFormula - The value of the formula, which can be a string, an object with a 'formula' property, or a number.
 * @returns An object with the formatted formula value as a string and a flag indicating if it's numeric.
 */
const getFormattedFormulaValue = (formula: Formula, parsedFormula: string | {
  formula: string;
} | number) => {
  const type = formula?.input_type;
  let formulaValue = parsedFormula;
  let isNumeric = true;
  if (formula.input_type === 'Date') {
    return {
      value: getFormattedDateFromformulaString((formulaValue as string)),
      isNumeric: false
    }; // returning the date in mm/yyyy format
  }
  const symbol = type === 'Currency' ? '$' : type === 'Percentage' ? '%' : '';

  // Check if the value is numeric
  if (typeof formulaValue === 'object' && 'formula' in formulaValue) {
    isNumeric = !Number.isNaN(Number(formulaValue.formula));
    formulaValue = formulaValue.formula;
  } else {
    isNumeric = !Number.isNaN(Number(formulaValue));
  }
  formulaValue = type === 'Percentage' ? `${Number(formulaValue) * 100}` : formulaValue;
  const formattedValue = type === 'Currency' ? `${symbol}${formatNumber(formulaValue)}` : `${formatNumber(formulaValue)}${symbol}`;
  return {
    value: formattedValue,
    isNumeric
  };
};

/**
 * Retrieves the input value for a given formula and date.
 *
 * @param formula - The formula object containing the input type and other metadata.
 * @param formulaDate - The date for which to retrieve the input value.
 * @returns An object with the formatted input value and a flag indicating if it's numeric.
 */
export function getInputValue(formula: Formula, formulaDate: string) {
  const parsedFormulas = getParsedFormulas && getParsedFormulas(formula);
  const parsedFormula = isArray(parsedFormulas) ? getFormulaExpressionForDate(parsedFormulas, formulaDate) : {
    formula: '0'
  };
  if (!parsedFormula) return {
    value: '0',
    isNumeric: true
  };
  return getFormattedFormulaValue(formula, parsedFormula);
}
/**
 * Retrieves the rows for a given formula, including the formula's type and whether it is an output.
 *
 * @param curFormula - The formula object containing the formula details.
 * @param dates - An array of dates to retrieve the formula values for.
 * @param formulaEntries - An object containing the formula entries.
 * @returns An object with the formula row, the formula type, and a boolean indicating if the formula is an output.
 */
export function getFormulaRows(curFormula: Formula, dates: string[], formulaEntries: FormulaEntries): {
  row: TableRow;
  type: FormulaGroupType;
  isOutput: boolean;
} {
  const formula = curFormula;
  const groupType = formula.group;
  const rowType: TableRowType = curFormula.input_type === 'Group' ? 'formulaGroup' : 'formula';
  const formulaRow: TableRow = {
    ...formula,
    original_row_object: formula,
    formula: formula.expression_string,
    sidebar: formula?.name?.trim(),
    rowStyle: {},
    type: rowType,
    formula_id: formula.id,
    id: formulaIDToRowID(formula.id, groupType),
    displayCellDataWhenExpanded: true,
    isErrorRow: false
  };
  let hasNonNumericValue = false;
  if (groupType === FormulaGroupType.Inputs && rowType !== 'formulaGroup') {
    dates.forEach(date => {
      const result = getInputValue(formula, date);
      formulaRow[date] = result.value;
      if (result.value === 'NaN') {
        hasNonNumericValue = true;
      }
    });
  } else {
    // if its a calcualtion or a group it should be calculated from entries
    const entryObject = formulaEntries[formula.id] ?? ({} as FormulaEntry);
    dates.forEach(date => {
      const result = getFormattedFormulaValue(formula, entryObject[date] ?? 0);
      formulaRow[date] = result.value;
      if (result.value === 'NaN') {
        hasNonNumericValue = true;
      }
    });
  }

  // Set background color to yellow if any non-numeric values are detected
  if (hasNonNumericValue && groupType !== FormulaGroupType.Outputs) {
    formulaRow.rowStyle = {
      ...formulaRow.rowStyle,
      backgroundColor: colors.lightYellow5PaleLight
    };
    formulaRow.isErrorRow = true;
  }
  return {
    row: formulaRow,
    type: groupType,
    isOutput: !!formula?.output_id
  };
}

/**
 * Function to format the api data to model table's specifications.
 * @param branchEmployees
 * @returns Array of row element data
 */
const processedData = (dates: string[], formulas: Formula[], t: any, formulaEntries: FormulaEntries, model_id: number, sort_order?: string) => {
  const groupTypeMapToParentRowGroup: { [key in FormulaGroupType]: {
    groupType: FormulaGroupType;
    curFormulaRows: TableRow[];
  } } = {
    [FormulaGroupType.Inputs]: {
      groupType: FormulaGroupType.Inputs,
      curFormulaRows: ([] as TableRow[])
    },
    [FormulaGroupType.Calculations]: {
      groupType: FormulaGroupType.Calculations,
      curFormulaRows: ([] as TableRow[])
    },
    [FormulaGroupType.Outputs]: {
      groupType: FormulaGroupType.Outputs,
      curFormulaRows: ([] as TableRow[])
    }
  };
  // Create a map to store formulas by their parent_id
  const parentMap: {
    [key: number]: TableRow[];
  } = {};
  const allFormulaRows: TableRow[] = [];
  formulas.forEach(formula => {
    const {
      row,
      type,
      isOutput
    } = getFormulaRows(formula, dates, formulaEntries);
    allFormulaRows.push(row);
    // Populate parentMap with formulas that have a parent_id
    if (formula.parent_id !== null) {
      if (!parentMap[formula?.parent_id || 0]) {
        parentMap[formula?.parent_id || 0] = [];
      }
      parentMap[formula?.parent_id || 0]?.push(row);
    } else {
      groupTypeMapToParentRowGroup[type].curFormulaRows.push(row);
    }
    if (isOutput) {
      groupTypeMapToParentRowGroup[FormulaGroupType.Outputs].curFormulaRows.push({
        ...row,
        id: `${row.id}-output`,
        type: 'output',
        rowStyle: {}
      });
    }
  });

  // Iterate over the parentMap and add the ≥ to the parent row
  allFormulaRows.forEach(row => {
    const curFormula = (row.original_row_object as Formula);
    if (parentMap[curFormula.id]) {
      const sortedRows = (sortItemsByFieldName(
      // sorting by the id of the formula itself
      parentMap[curFormula.id] || [], curFormula?.sort_order || '', 'original_row_object') as TableRow[]);
      row.subRows = sortedRows;
    }
  });
  const rowGroups = Object.values(groupTypeMapToParentRowGroup);
  const result: TableRow[] = rowGroups?.map((group: {
    groupType: FormulaGroupType;
    curFormulaRows: TableRow[];
  }) => {
    const {
      groupType,
      curFormulaRows
    } = group;
    const sortedRows = (sortItemsByFieldName(
    // sorting by the id of the formula itself
    curFormulaRows || [], sort_order || '', 'original_row_object') as TableRow[]);
    const groupRow: TableRow = {
      sidebar: groupType,
      id: `${groupType}-parent-row`,
      type: 'section',
      group: groupType,
      // to identify what kind of row it is we are passing the type to the parent as well
      emptyCellsRow: true,
      rerenderOnDataChange: true,
      subRows: sortedRows,
      renameDisabled: true,
      rowStyle: {
        fontWeight: 700,
        backgroundColor: colors.culturedGray // ADIL Pull color from color variabls
      }
    };
    return groupRow;
  });

  // now iterativley we need to add depth to each row by iterating over all the rows using a stack going deeper from the result array
  function addDepthToRows(rows: any[], depth: number = 0) {
    return rows.map(row => {
      // Add depth to the current row
      row.depth = depth;

      // If the row has subRows, recursively process them
      if (row.subRows && row.subRows.length > 0) {
        row.subRows = addDepthToRows(row.subRows, depth + 1);
      }
      return row;
    });
  }

  // Use the function on your result array
  const resultWithDepth = addDepthToRows(result);

  // adding "Add Forumula" rows to the result array
  pushAddFormulaRows(t, resultWithDepth, model_id);
  return resultWithDepth;
};
export default processedData;