import ConfettiExplosion from 'react-confetti-explosion';
import useWindowSize from 'react-use/lib/useWindowSize';
import { useShallow } from 'zustand/react/shallow';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import styles from './confettiAnimation.module.scss';

/**
 * A React component that renders a confetti animation with four explosion effects.
 *
 * @param {object} props - The component props.
 * @param {number} [props.particleCount=300] - The number of confetti particles to be rendered.
 * @param {number} [props.duration=2200] - The duration of the confetti animation in milliseconds.
 * @returns {JSX.Element} - The rendered confetti animation.
 */
const ConfettiAnimation = ({
  particleCount = 300,
  duration = 2200
}: {
  particleCount?: number;
  duration?: number;
}) => {
  const {
    width,
    height
  } = useWindowSize();
  const {
    isConfettiAnimation,
    setConfettiAnimation,
    callFunctionAfterConfettiAnimation
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    isConfettiAnimation: state.isConfettiAnimation,
    setConfettiAnimation: state.setConfettiAnimation,
    callFunctionAfterConfettiAnimation: state.callFunctionAfterConfettiAnimation
  })));
  const confettiCompleteHandler = () => {
    if (isConfettiAnimation) {
      setConfettiAnimation(false);
    }
    if (callFunctionAfterConfettiAnimation) {
      callFunctionAfterConfettiAnimation();
    }
  };
  return <div className={styles.confettiContainer} data-sentry-component="ConfettiAnimation" data-sentry-source-file="ConfettiAnimation.tsx">
      <div className={styles.leftTopConfetti}>
        <ConfettiExplosion duration={duration} width={width} height={height} particleCount={particleCount} force={1} zIndex={1000} onComplete={confettiCompleteHandler} data-sentry-element="ConfettiExplosion" data-sentry-source-file="ConfettiAnimation.tsx" />
      </div>
      <div className={styles.rightTopConfetti}>
        <ConfettiExplosion duration={duration} width={width} height={height} force={1} particleCount={particleCount} zIndex={1000} onComplete={confettiCompleteHandler} data-sentry-element="ConfettiExplosion" data-sentry-source-file="ConfettiAnimation.tsx" />
      </div>
    </div>;
};
export default ConfettiAnimation;