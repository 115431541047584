import React from 'react';
/* eslint-disable import/no-extraneous-dependencies, no-console */
import ContentLoader from 'react-content-loader';

/* eslint-disable react/no-array-index-key */
import colors from '@/styles/scss/abstracts/_variables.module.scss';

/**
 * Various loader components to indicate loading state.
 *
 * defaultLoaderContent: Simple default loader component.
 *
 * companiesDropdownLoaderContent: Loader styled for company dropdown.
 *
 * subheaderLinksLoaderContent: Loader for subheader links section.
 *
 * createNewItemLoaderContent: Loader for create new item buttons.
 *
 * dateRangeSelectorLoaderContent: Loader for date range selector component.
 *
 * tableLoaderContent: More complex loader that generates a full table loader.
 */
const defaultLoaderContent = () => <rect x="0" y="0" rx="0" ry="0" width="50" height="25" data-sentry-element="rect" data-sentry-component="defaultLoaderContent" data-sentry-source-file="Loaders.tsx" />;
const companiesDropdownLoaderContent = () => <rect x="0" y="0" rx="4" ry="4" width="120" height="24" data-sentry-element="rect" data-sentry-component="companiesDropdownLoaderContent" data-sentry-source-file="Loaders.tsx" />;
const subheaderLinksLoaderContent = () => <rect x="0" y="0" rx="0" ry="0" width="300" height="40" data-sentry-element="rect" data-sentry-component="subheaderLinksLoaderContent" data-sentry-source-file="Loaders.tsx" />;
const createNewItemLoaderContent = () => <rect x="0" y="0" rx="2" ry="2" width="50" height="40" data-sentry-element="rect" data-sentry-component="createNewItemLoaderContent" data-sentry-source-file="Loaders.tsx" />;
const dateRangeSelectorLoaderContent = () => <rect x="0" y="0" rx="2" ry="2" width="190" height="40" data-sentry-element="rect" data-sentry-component="dateRangeSelectorLoaderContent" data-sentry-source-file="Loaders.tsx" />;
const tableLoaderContent = (columnwidths: {
  firstColumnWidth: number;
  standardColumnWidth: number;
  numberOfStandardColumns: number;
}) => {
  const sideBarWidth = columnwidths.firstColumnWidth; // Width of sidebar
  const topRowHeight = 50; // Top row height
  const secondRowHeight = 70; // Second row height (larger)
  const standardRowHeight = 50; // Height for other rows
  const standardCellWidth = columnwidths.standardColumnWidth; // Standard cell width
  const numberOfRows = columnwidths.numberOfStandardColumns + 2; // Number of rows
  const numberOfColumns = columnwidths.numberOfStandardColumns + 1; // Number of columns (including sidebar)
  const loaderRows = [];
  let currentY = 0;
  // Loader for top row
  loaderRows.push(<rect key="sidebar" x="5" y="5" rx="5" ry="5" width={sideBarWidth} height={topRowHeight} />);
  loaderRows.push(<rect key="main" x={sideBarWidth + 10} y="5" rx="5" ry="5" width={(standardCellWidth + 9) * (numberOfColumns - 1)} height={topRowHeight} />);
  currentY += topRowHeight + 10;
  // Loader for second row
  loaderRows.push(<rect key="second-sidebar" x="5" y={currentY} rx="5" ry="5" width={sideBarWidth} height={secondRowHeight} />);
  for (let i = 0; i < numberOfColumns - 1; i += 1) {
    loaderRows.push(<rect key={`second-main-${i}`} x={sideBarWidth + 10 + i * (standardCellWidth + 10)} y={currentY} rx="5" ry="5" width={standardCellWidth} height={secondRowHeight} />);
  }
  currentY += secondRowHeight + 10;
  // Loader for other lines
  for (let row = 2; row < numberOfRows; row += 1) {
    loaderRows.push(<rect key={`sidebar-${row}`} x="5" y={currentY} rx="5" ry="5" width={sideBarWidth} height={standardRowHeight} />);
    for (let col = 0; col < numberOfColumns - 1; col += 1) {
      loaderRows.push(<rect key={`main-${row}-${col}`} x={sideBarWidth + 10 + col * (standardCellWidth + 10)} y={currentY} rx="5" ry="5" width={standardCellWidth} height={standardRowHeight} />);
    }
    currentY += standardRowHeight + 10;
  }
  return loaderRows;
};

/**
 * Returns the content for the loader based on the given content type.
 *
 * @param content - The content type to return the loader for.
 */
const getLoaderContent = (content: string) => {
  switch (content) {
    case 'companiesDropdown':
      return companiesDropdownLoaderContent();
    case 'subheader-links':
      return subheaderLinksLoaderContent();
    case 'createNewItem':
      return createNewItemLoaderContent();
    case 'dateRangeSelector':
      return dateRangeSelectorLoaderContent();
    default:
      return defaultLoaderContent();
  }
};
export const ChartSkeleton = () => <ContentLoader speed={2} width="100%" height="100%" viewBox="0 0 400 300" backgroundColor="#f3f3f3" foregroundColor="#ecebeb" data-sentry-element="ContentLoader" data-sentry-component="ChartSkeleton" data-sentry-source-file="Loaders.tsx">
    {/* Y-axis line */}
    <rect x="40" y="20" width="2" height="230" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />

    {/* X-axis line */}
    <rect x="40" y="250" width="340" height="2" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />

    {/* Y-axis labels */}
    <rect x="10" y="40" width="25" height="8" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="10" y="100" width="25" height="8" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="10" y="160" width="25" height="8" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="10" y="220" width="25" height="8" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />

    {/* X-axis labels */}
    <rect x="60" y="260" width="30" height="8" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="140" y="260" width="30" height="8" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="220" y="260" width="30" height="8" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="300" y="260" width="30" height="8" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />

    {/* Bars */}
    <rect x="60" y="100" width="30" height="150" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="140" y="80" width="30" height="170" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="220" y="140" width="30" height="110" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="300" y="60" width="30" height="190" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />

    {/* Grid lines */}
    <rect x="40" y="100" width="340" height="1" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="40" y="160" width="340" height="1" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
    <rect x="40" y="220" width="340" height="1" data-sentry-element="rect" data-sentry-source-file="Loaders.tsx" />
  </ContentLoader>;

/**
 * Renders a loader if isLoading is true, otherwise renders the children.
 *
 * @param isLoading - Whether to show the loader.
 * @param children - The content to render if not loading.
 * @param content - The type of loader content to show.
 * @param props - Additional props like width and height.
 */
const LoaderWrapper = ({
  isLoading,
  children,
  content,
  ...props
}: any) => {
  if (isLoading) {
    return <ContentLoader speed={1} width={props.width} height={props.height} viewBox={`0 0 ${props.width} ${props.height}`} backgroundColor={colors.greyWhite} foregroundColor={colors.foregroundColor} data-testid="contentloader" {...props}>
        {content === 'table' && props.columnwidths ? tableLoaderContent(props.columnwidths) : getLoaderContent(content)}
      </ContentLoader>;
  }
  return children;
};
export default LoaderWrapper;