import { Ellipsis } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LuSquareSigma } from 'react-icons/lu';
import { MdArrowDropDown } from 'react-icons/md';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import OptionsMenu from '@/components/common/OptionsMenu/OptionsMenu';
import styles from '@/components/table/shared/table/GeneralTable/styles/tableSidebarStyles.module.scss';
import { generateTypeIconForTableRows } from '@/miscellaneous/helper/tableHelper';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { getDepth, getSidebarCellContainerStyle, getSidebarCellContentStyle, getSideBarCellDivStyle, getSidebarDropdownStyle } from '../shared/TableHelpers/sidebarCellsStyles/sidebarCellsStylesHelper';
import type { StylesVariables } from '../shared/TableHelpers/TableRowsComponents';
import { calculateWidth } from '../shared/TableHelpers/TableRowsComponents';
// eslint-disable-next-line import/no-cycle
import SidebarCellContent from './subComponent/SidebarCellContent';
const FinancialsOptionsMenuPopup = ({
  buttonsRef,
  children
}: {
  buttonsRef: any;
  children: any;
}) => {
  return <Popup trigger={<span className={styles.hoverable}>
          <Ellipsis size={20} />
        </span>} arrow={false} position={['bottom left', 'top left']} onOpen={() => {
    buttonsRef.current.classList.add(styles.unHoverable);
  }} onClose={() => {
    buttonsRef.current.classList.remove(styles.unHoverable);
  }} repositionOnResize data-sentry-element="Popup" data-sentry-component="FinancialsOptionsMenuPopup" data-sentry-source-file="financialsSideBarCells.tsx">
      {/* 
       Explanation: 
       We need to clone the children component instead of just
       passing the close handler directly to avoid mutating the original
       children passed in.
       The 'close' parameter is a callback function that will close the popup.
       It needs to be passed to the wrapped component that renders the popup so that component can call it to close itself.
             By cloning the element and adding the close prop, we can enhance the 
       child component's behavior without changing the component itself. This
       avoids coupling components and keeps them reusable. 
       */}
      {/* @ts-ignore */
    close => React.cloneElement(children, {
      close
    })}
    </Popup>;
};
export interface FinancialsSidebarCellProps {
  row: any;
  columnSpec: any;
  cell: any;
}

/**
 * Styled component that renders a div with a pseudo element before it to create a vertical bar.
 * The depth prop controls the width of the bar.
 */
const SideBarCellContainer = styled.div<{
  $depth: number;
}>`
  &::before {
    content: '';
    display: block;
    width: 0%;
    width: 0%;
    height: 1px;
    background-color: white;
    position: absolute;
    bottom: -2px;
    left: 0;
  }
`;
const FinancialsSidebarCell: React.FC<FinancialsSidebarCellProps> = ({
  row,
  columnSpec,
  cell
}: FinancialsSidebarCellProps) => {
  const buttonsRef = React.useRef(null);
  const [isRenaming, setIsRenaming] = React.useState(false);
  const [newName, setNewName] = React.useState(cell.getValue());
  const {
    t: translate
  } = useTranslation(['financialreport']);
  const curRowDepth = row?.original?.depth;
  const isCumulativeRow = row.original.original_row_object?.cumulative && !row.original.isTotalRow;

  /**
   * Updates a output name
   *
   * @param original - The original row object
   * @param value - The new name for the output
   */
  const renameRowObject = columnSpec.handlerFunctions.renameRow;

  /**
   * Checks if the row has sub rows.
   */
  const hasSubRows = row.subRows && row.subRows.length > 0;
  const sideBarCellDivStyle = getSideBarCellDivStyle(curRowDepth, styles);
  const sidebarDropdownStyle = getSidebarDropdownStyle(row, styles);
  const sidebarCellContentStyle = getSidebarCellContentStyle(columnSpec, hasSubRows, row.getIsExpanded(), curRowDepth, getDepth);
  const sidebarCellContainerStyle = getSidebarCellContainerStyle(columnSpec, curRowDepth, getDepth, row.original.rowStyle?.backgroundColor);
  const divStyle: React.CSSProperties = {
    fontWeight: row.original.rowStyle?.fontWeight || 'normal'
  };
  if (row.original.isTotalRow) {
    divStyle.color = colors.black;
    divStyle.fontWeight = 'bold';
  }
  if (row.original.rowStyle?.color) divStyle.color = row.original.rowStyle?.color;
  const isIntegrationOutput = row.original?.original_row_object?.ledger_account_id;
  return <SideBarCellContainer $depth={curRowDepth} className={`${sideBarCellDivStyle} ${styles.sideBarCellChild}`} style={sidebarCellContainerStyle} data-sentry-element="SideBarCellContainer" data-sentry-component="FinancialsSidebarCell" data-sentry-source-file="financialsSideBarCells.tsx">
      <Tooltip delayShow={1000} id="financials-sidebar-cell-tooltip" data-sentry-element="Tooltip" data-sentry-source-file="financialsSideBarCells.tsx" />
      {/* <DnDIdentifierWrapper row={row}> */}
      <div style={sidebarCellContentStyle} className={`${styles.subheader} `}>
        {hasSubRows && <button type="button" aria-label="Toggle Sub Rows" className={`${styles.toggleSubrowsButton} ${sidebarDropdownStyle}`} onClick={() => row.toggleExpanded(!row.getIsExpanded())}>
            <MdArrowDropDown size={22} />
          </button>}
        <div className={styles.sidebarCellContentContainer} style={divStyle}>
          <div>
            {!hasSubRows && generateTypeIconForTableRows(row.original.type)}
          </div>
          <div className={styles.sidebarCellContent} style={{
          width: calculateWidth(curRowDepth, (colors as StylesVariables), +(colors.sidebarAdditionalWidthFinancials as string).replace('px', ''))
        }}>
            <SidebarCellContent row={row} isRenaming={isRenaming} newName={newName} setNewName={setNewName} setIsRenaming={setIsRenaming} renameOutput={renameRowObject} data-sentry-element="SidebarCellContent" data-sentry-source-file="financialsSideBarCells.tsx" />
          </div>
        </div>{' '}
      </div>
      {/* </DnDIdentifierWrapper> */}

      {isCumulativeRow && <span className={styles.cumulativeRow}>
          <LuSquareSigma size={18} strokeWidth={1.5} />
        </span>}
      <span ref={buttonsRef} className={`${styles.actions}`} style={{
      margin: 'auto 0'
    }}>
        {columnSpec?.getOptions(row, setIsRenaming)?.length > 0 && <FinancialsOptionsMenuPopup buttonsRef={buttonsRef}>
            <>
              {isIntegrationOutput && <Tooltip anchorSelect="#delete-account">
                  {translate('cannot_delete_integration_output')}
                </Tooltip>}

              <OptionsMenu items={columnSpec?.getOptions(row, setIsRenaming)} />
            </>
          </FinancialsOptionsMenuPopup>}
      </span>
    </SideBarCellContainer>;
};
export default FinancialsSidebarCell;