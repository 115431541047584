import { deleteCookies } from '@/cookies';
import dynamicRoute from '@/miscellaneous/constant';
import mixpanel from '@/mixpanelConfig';

import queryClient from './queryClient';

const logoutUserFromThePlatform = (
  setIsLoggedIn: (isLoggedIn: boolean | null) => void,
  resetStore: () => void,
  setHasTrackedLanding: (hasTrackedLanding: boolean) => void,
  logout: any,
) => {
  // Store return URL for later use
  const returnUrl = `${window.location.origin}${dynamicRoute.login}`;

  // Only perform the absolutely critical security operations before redirecting
  deleteCookies();
  setIsLoggedIn(null);

  // Immediately start Auth0 logout process
  logout({
    logoutParams: {
      returnTo: returnUrl,
    },
  });

  // Immediately redirect to login page to make logout feel instant
  window.location.href = returnUrl;

  // Handle non-critical cleanup operations in the background
  // These will still execute but won't delay the user experience
  setTimeout(() => {
    // Reset Mixpanel identification
    mixpanel.reset();

    // Clean up application state
    resetStore();
    setHasTrackedLanding(false);
    queryClient.clear();
  }, 0);
};

export default logoutUserFromThePlatform;
