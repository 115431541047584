import Popup from 'reactjs-popup';
import { useShallow } from 'zustand/react/shallow';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import colors from '@/styles/scss/abstracts/_variables.module.scss';

// eslint-disable-next-line import/no-cycle
import IntegrationsPopupBody from './IntegrationsPopupBody';

// AddIntegrationsPopup is a component that displays a popup for adding integrations
const AddIntegrationsPopup = () => {
  const {
    isIntegrationPopUpOpen,
    setIsIntegrationPopUpOpen
  } = useUtilsStore(useShallow(state => ({
    isIntegrationPopUpOpen: state.isIntegrationPopUpOpen,
    setIsIntegrationPopUpOpen: state.setIsIntegrationPopUpOpen
  })));

  // popupContentStyle is a style object that defines the dimensions and appearance of the popup content
  const popupContentStyle = {
    maxWidth: '1100px',
    width: '1100px',
    height: '608px',
    padding: '24px',
    borderRadius: '20px',
    backgroundColor: colors.grayBg2
  };

  // popupOverlayStyles is a style object that defines the appearance of the popup overlay
  const popupOverlayStyles = {
    backgroundColor: colors.modalOcerlayColor
  };
  return <Popup contentStyle={popupContentStyle} overlayStyle={popupOverlayStyles} modal open={isIntegrationPopUpOpen} onClose={() => setIsIntegrationPopUpOpen(false)} data-sentry-element="Popup" data-sentry-component="AddIntegrationsPopup" data-sentry-source-file="AddIntegrationsPopup.tsx">
      <IntegrationsPopupBody isIntegrationPopUpOpen={isIntegrationPopUpOpen} setIsIntegrationPopUpOpen={setIsIntegrationPopUpOpen} isFilterEmptyTabs data-sentry-element="IntegrationsPopupBody" data-sentry-source-file="AddIntegrationsPopup.tsx" />
    </Popup>;
};
export default AddIntegrationsPopup;