import devtools from 'devtools-detect';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';

/**
 * Verifies if the payment modal exists and is visible on the page
 * @returns An object with isVisible and exists flags
 */
export const verifyPaymentModalStatus = () => {
  const paymentModal = document.getElementById('payment-modal');

  if (!paymentModal) {
    return {
      exists: false,
      isVisible: false,
    };
  }

  // Check if element or any parent is hidden
  const isVisible = (element: Element): boolean => {
    if (!element) return true; // Reached top of DOM

    const style = window.getComputedStyle(element);

    if (style.display === 'none') return false;
    if (style.visibility === 'hidden') return false;
    if (style.opacity === '0') return false;

    // Check if element is actually rendered with dimensions
    if (element === paymentModal) {
      const rect = element.getBoundingClientRect();
      if (rect.width === 0 || rect.height === 0) return false;
    }

    // Check parent element too
    return element.parentElement ? isVisible(element.parentElement) : true;
  };

  return {
    exists: true,
    isVisible: isVisible(paymentModal),
  };
};

const useProtectPaymentModal = () => {
  const { showPaymentModal } = useUtilsStore(
    useShallow((state) => ({
      showPaymentModal: state.showPaymentModal,
    })),
  );

  useEffect(() => {
    const handleDevToolsChange = (isOpen: boolean) => {
      if (
        !isOpen &&
        showPaymentModal &&
        (!verifyPaymentModalStatus().exists ||
          !verifyPaymentModalStatus().isVisible)
      ) {
        window.location.reload();
      }
    };

    // Set up handler for devtools-detect library
    const devToolsChangeHandler = (event: any) => {
      handleDevToolsChange(event.detail.isOpen);
    };

    // Initial check when component mounts
    if (devtools.isOpen) {
      handleDevToolsChange(true);
    }

    // Use the library's event
    window.addEventListener('devtoolschange', devToolsChangeHandler);

    return () => {
      window.removeEventListener('devtoolschange', devToolsChangeHandler);
    };
  }, [showPaymentModal]);
};

export default useProtectPaymentModal;
