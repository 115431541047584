/**
 * useChatGPTQuery Custom Hook
 *
 * Description:
 * This hook manages the chat interaction with the ChatGPT API. It maintains the history of messages and
 * allows sending new messages to the API. Successful responses are appended to the message history.
 *
 * Returns:
 * - `sendMessage`: A function obtained from the `useMutation` hook. Call this function with a new message
 *   string to initiate a POST request to the ChatGPT API.
 * - `data`: The latest response data received from the API.
 * - `isLoading`: A boolean indicating if the mutation (API request) is currently in progress (true) or not (false).
 * - `error`: An error object that contains API error information.
 * - `messageHistory`: An array of Message objects representing the ongoing chat with ChatGPT.
 * - `setMessageHistory`: A function to manually update the message history, useful if you need to directly control
 *   the conversation's state or implement additional features like undo/redo or specific message updates.
 *
 * Message Structure:
 * - `role`: A string representing who sent the message. Can be 'system', 'user', or 'assistant'.
 * - `content`: The text content of the message itself.
 *
 * API Communication:
 * - The `fetchChatResponse` function makes a POST request to the ChatGPT API, sending the current message
 *   and the entire message history for context-aware conversations.
 * - For the API call, the `model` parameter is set to "gpt-3.5-turbo". Replace with the appropriate model
 *   according to your OpenAI configuration.
 *
 * Usage:
 * ```jsx
 * const {
 *   sendMessage,
 *   data,
 *   isLoading,
 *   error,
 *   messageHistory,
 *   setMessageHistory
 * } = useChatGPTQuery();
 *
 * // Use `sendMessage` in your component to send messages:
 * sendMessage("Hello, ChatGPT!");
 * ```
 *
 * Caution:
 * - Ensure that you are handling user retry behavior in case of an error (network errors, API rate limits, etc.)
 * - DO NOT expose your OpenAI API key in your frontend code or repositories. It should only be used
 *   on the server side or within environment variables as shown.
 */

// eslint-disable-next-line import/no-extraneous-dependencies
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import {
  RUNWAY_EXPLAINER,
  systemMessage,
} from '@/miscellaneous/constant/AIConstants';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';

import type { Position, Prompt } from '../types/AITypes';
import type { Department } from '../types/HiringTypes';
import { useProcessedBranchEmployees } from './employees/useProcessedBranchEmployees';
import { usePrompts } from './employees/usePrompts';
import useMutations from './mutations/useMutations';
import { useChatStore } from './useChatStore';

// Type for a chat message
type Message = {
  role: 'system' | 'user' | 'assistant';
  content: string;
};

/**
 * Approximate function to count tokens in a string.
 * This is a simplification, as actual tokenization logic might be more complex.
 * @param text - The text to be tokenized.
 * @returns The approximate number of tokens.
 */
export function countTokens(text: string): number {
  // Simple approximation: one token per word
  return text.trim().split(/\s+/).length;
}

export function getDataContent(data: any, prompt?: string) {
  let dataContent = '';
  if (data) {
    const dataString = JSON.stringify(data); // todo add a new prompt
    dataContent = `
    ${prompt || ''} 
    ${dataString}`;
  }
  return dataContent;
}

export function convertPromptsToString(prompts: Prompt[]) {
  return prompts
    .map((prompt) => {
      const parts = [
        `prompt: ${prompt.prompt}`,
        `promptType: ${prompt.promptOperation}`,
        `AIModelResponse: ${prompt.response}`,
      ];

      if (prompt.originalPromptOption?.title) {
        parts.push(`title: ${prompt.originalPromptOption.title}`);
      }
      return `{ ${parts.join(', ')} }`;
    })
    .join(', ');
}

// runWay explainer system message
const runwayExplainerSystemMessage = (message: string) => {
  const { outputsEffectingFormulasMap } = CalculationsStore.getState();
  const { runwayMonthlyValues } = useZustandStore.getState();

  return `${RUNWAY_EXPLAINER}\n INPUT: ${JSON.stringify({
    freetext: message,
    'Runway Value (months)': runwayMonthlyValues,
    outputs: outputsEffectingFormulasMap,
  })}`.replace(/\n+/g, ' ');
};

/**
 * Asynchronously fetches a response from the ChatGPT API based on the provided message and message history.
 * @param message - The new message to be sent to the API.
 * @param messageHistory - The history of messages in the conversation.
 * @returns A Promise that resolves to the response from the ChatGPT API.
 * @throws If the API request fails.
 */
async function fetchChatResponse(
  getGeneralChatResponse: any,
  message: string,
  messageHistory: Message[],
  employeeTable?: Position[],
  activePrompts?: Prompt[],
  agent_type: 'default' | 'runway' = 'default',
) {
  const employeeSalariesYearly =
    (employeeTable
      ?.map((employee) => employee?.yearly_salary || 0)
      .reduce((a, b) => a + b, 0) || 0) * 1.5; // a year and a half of salaries total

  const dataContent = getDataContent(employeeTable, '');
  const acitvePromptsStringged = convertPromptsToString(activePrompts || []);

  const messages: Message[] = [];
  // concat all contents of messages to a string for toekn count
  const messageHistoryString = messageHistory
    .map((item) => item.content)
    .join(' ');

  // Calculate remaining token space after adding the system messages
  const remainingTokenLimit = countTokens(messageHistoryString);
  const maxTokenLimit = 120000; // Adjust based on the actual token limit of the model
  let remainingTokenSpace = maxTokenLimit - remainingTokenLimit;

  // Add as much of the recent conversation history as possible
  for (let i = messageHistory.length - 1; i >= 0; i -= 1) {
    const currentMessage = messageHistory[i];

    if (currentMessage !== undefined && currentMessage.content) {
      const messageTokens = countTokens(currentMessage.content);
      if (remainingTokenSpace >= messageTokens) {
        messages.unshift(currentMessage); // Keep the structure of Message
        remainingTokenSpace -= messageTokens;
      } else {
        break;
      }
    }
  }

  // Add the new user message at the end
  messages.push({ content: message, role: 'user' });

  const systemContent =
    agent_type === 'runway'
      ? runwayExplainerSystemMessage(message)
      : systemMessage(
          dataContent,
          acitvePromptsStringged,
          employeeSalariesYearly,
        );

  messages.unshift({
    content: systemContent,
    role: 'system',
  });

  const response = await getGeneralChatResponse.mutateAsync({
    input: message,
    messages,
  });

  if (!response) {
    throw new Error('Fetching response failed');
  }

  return response;
}

/**
 * Custom React Query hook for interacting with ChatGPT API.
 * @returns An object containing various properties and functions for managing chat state and sending messages.
 */

export const useGPTQuery = () => {
  const { clearChatHistory, promptType } = useChatStore();
  const [messageHistory, setMessageHistory] = useState<Message[]>([]);
  const { employees } = useProcessedBranchEmployees();
  const { hiringPrompts: activePrompts } = usePrompts();
  const { getGeneralChatResponse } = useMutations();

  // convert employees to Position[]
  const positions: Position[] = employees?.map((employee) => {
    const position: Position = {
      role: employee.role || '',
      department: employee.department as Department,
      yearly_salary: employee.yearly_salary || 0,
      bonus: employee.bonus || 0,
      start_date: employee.start_date || '',
      is_ai_employee: employee.is_ai_employee ?? false,
    };
    return position;
  });

  // useMutation hook to send a new message and update message history
  const sendMessage = useMutation({
    mutationFn: (newMessage: string) =>
      fetchChatResponse(
        getGeneralChatResponse,
        newMessage,
        messageHistory,
        positions,
        activePrompts,
        promptType,
      ),

    onSuccess: (data: any, variables: string) => {
      // Update message history with user and assistant messages
      setMessageHistory((prev) => [
        ...prev,
        { role: 'user', content: variables },
        { role: 'assistant', content: data },
      ]);
    },
  });

  return {
    sendMessage: sendMessage?.mutate, // Function to call when you want to send a message.
    data: sendMessage?.data, // Latest successful data.
    isLoading: sendMessage?.isPending, // Is the mutation in flight.
    error: sendMessage?.error, // Any error that might have occurred.
    messageHistory, // The history of messages for the currently maintained conversation.
    setMessageHistory, // Function to manually update the message history if needed.
    resetMessageState: () => {
      setMessageHistory([]);
      clearChatHistory();
    },
  };
};
