import React, { useEffect, useRef, useState } from 'react';
import Split from 'react-split';
import styles from '@/components/table/shared/table/GeneralTable/styles/baseTableStyles.module.scss';
import { FloatingBubble } from './FloatingBubble';
interface DraggableColumnProps {
  isActive: boolean;
  leftAreaSize: number;
  stepSize: number;
  minSteps: number;
  maxSize: number;
  onDrop: (newSize: number) => void;
  leftAreaClassName?: string;
  rightAreaClassName?: string;
}

/**
 * DraggableColumn component - Renders a resizable split view with draggable divider
 *
 * This component uses react-split to create a draggable interface between two panel areas.
 * It supports both direct dragging and floating bubble interaction when the left area is out of view.
 */
const DraggableColumn: React.FC<DraggableColumnProps> = ({
  isActive,
  leftAreaSize,
  stepSize,
  minSteps,
  maxSize,
  onDrop,
  leftAreaClassName = styles.actualDateArea,
  rightAreaClassName = styles.forecastDateArea
}) => {
  const splitElementRef = useRef<null>(null);
  const [rightAreaWidth, setRightAreaWidth] = useState(0);
  const [currentDragSize, setCurrentDragSize] = useState<number>(leftAreaSize);
  const isDraggingRef = useRef(false);
  const leftAreaRef = useRef<HTMLDivElement>(null);
  const [hideLeftArea, setHideLeftArea] = useState(false);

  // Update internal state when prop changes
  useEffect(() => {
    setCurrentDragSize(leftAreaSize);
  }, [leftAreaSize]);

  // Calculate and update right area width when split element or left area size changes
  useEffect(() => {
    if (splitElementRef.current) {
      // @ts-ignore
      const splitElement = (splitElementRef.current.parent as HTMLElement);
      const calculateWidths = () => {
        const widthOfSplitElement = splitElement.offsetWidth;
        setRightAreaWidth(widthOfSplitElement - currentDragSize - 1);
      };
      calculateWidths();
      const resizeObserver = new ResizeObserver(() => {
        calculateWidths();
      });
      resizeObserver.observe(splitElement);
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [splitElementRef.current, currentDragSize]);

  // Helper function to round size to the nearest interval
  const roundToNearestInterval = (size: number, interval: number) => {
    return Math.round(size / interval) * interval;
  };

  // Define the threshold as a static variable
  const LEFT_AREA_THRESHOLD = 365;

  // Check if left area is out of view and needs floating bubble
  useEffect(() => {
    if (leftAreaRef.current?.getBoundingClientRect() && leftAreaRef.current?.getBoundingClientRect().x && leftAreaRef.current?.getBoundingClientRect().x < LEFT_AREA_THRESHOLD - currentDragSize) {
      setHideLeftArea(true);
    } else {
      setHideLeftArea(false);
    }
  }, [leftAreaRef.current?.getBoundingClientRect(), currentDragSize]);

  // Helper function to update right area width based on left area size
  const updateRightAreaWidth = (newLeftSize: number) => {
    if (splitElementRef.current) {
      // @ts-ignore
      const splitElement = (splitElementRef.current.parent as HTMLElement);
      const widthOfSplitElement = splitElement.offsetWidth;
      setRightAreaWidth(widthOfSplitElement - newLeftSize);
    }
  };

  // Helper function to handle drag end operations
  const handleDragEnd = (newSize: number) => {
    const roundedSize = roundToNearestInterval(newSize, stepSize);
    setCurrentDragSize(roundedSize);
    updateRightAreaWidth(roundedSize);
    setTimeout(() => {
      isDraggingRef.current = false;
      onDrop(roundedSize);
    }, 50);
  };

  // Floating bubble drag handler - updates the current drag size and right area width
  const handleFloatingBubbleDrag = (deltaX: number) => {
    const newSize = currentDragSize + deltaX;
    const clampedSize = Math.max(minSteps * stepSize, Math.min(maxSize, newSize));
    setCurrentDragSize(clampedSize);
    updateRightAreaWidth(clampedSize);
  };

  // Don't render if the component is not active or if the left area size is 0
  return leftAreaSize > 0 && isActive ? <div className={styles.tableDragableArea} key={leftAreaSize} data-testid="draggable-column" data-sentry-component="DraggableColumn" data-sentry-source-file="DraggableColumn.tsx">
      <Split ref={splitElementRef} className="split" dragInterval={stepSize} minSize={minSteps * stepSize} maxSize={[maxSize, Infinity]} sizes={[currentDragSize, rightAreaWidth]} onDragStart={() => {
      isDraggingRef.current = true;
    }} style={{
      visibility: hideLeftArea ? 'hidden' : 'visible'
    }} onDrag={sizes => {
      const newSize = (sizes[0] as number);
      setCurrentDragSize(newSize);
      updateRightAreaWidth(newSize);
    }} onDragEnd={sizes => {
      handleDragEnd((sizes[0] as number));
    }} data-sentry-element="Split" data-sentry-source-file="DraggableColumn.tsx">
        <div className={leftAreaClassName} ref={leftAreaRef} />
        <div className={rightAreaClassName} />
        {/* Floating bubble appears when left area is scrolled out of view */}
        <FloatingBubble hide={hideLeftArea} targetRef={leftAreaRef} onDrag={handleFloatingBubbleDrag} onDragEnd={newSize => {
        handleDragEnd(newSize);
      }} currentWidth={currentDragSize} stepSize={stepSize} minSize={minSteps * stepSize} maxSize={maxSize} data-sentry-element="FloatingBubble" data-sentry-source-file="DraggableColumn.tsx" />
      </Split>
    </div> : null;
};
export default DraggableColumn;