import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSparklesOutline } from 'react-icons/io5';
import { useShallow } from 'zustand/react/shallow';
import { companySectorsData } from '@/miscellaneous/detailsConfirmationData/companySectors';
import { companyStagesData } from '@/miscellaneous/detailsConfirmationData/companyStages';
import { countriesData } from '@/miscellaneous/detailsConfirmationData/countries';
import { employeeData } from '@/miscellaneous/detailsConfirmationData/employees';
import { fundingStagesData } from '@/miscellaneous/detailsConfirmationData/fundingStages';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, MIXPANEL_KEYS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import useMutations from '@/utils/hooks/mutations/useMutations';
import type { Integration } from '@/utils/hooks/useIntegrations/useIntegrations';
import useShallowRoute from '@/utils/hooks/useShallowRoute';
import { isValidUrl } from '@/utils/regex';
import DeletePopup from '../DeletePopup/DeletePopup';
import type { Steps } from '../GenericStagePopup/GenericStagePopup';
import styles from './OnboardingPopup.module.scss';
import { buttonDisabledHandler, fastOnboardingProcess, getNextButtonText, getStepComponent, goToNextStep } from './onBoardingPopupBodyFunctions';
import type { CompanyDetails, CompanyGoals, CompanyProducts, CompanyState, CompanyWebsite, Question, StepConfig, TemplateGeneration } from './onboardingPopupTypes';
import { getBreadcrumbPath, getPlanConfigs } from './onboardingUtils';
import CompanyDetailsStage from './Stages/CompanyDetailsStage/CompanyDetailsStage';
import { CompanyNameStage, IntegrationsStage } from './Stages/CompanyNameOrIntegrationsStage/CompanyNameOrIntegrationsStage';
import CompanyProductsStage from './Stages/CompanyProductsStage/CompanyProductsStage';
import CompanyWebsiteStage from './Stages/CompanyWebsiteStage/CompanyWebsiteStage';
import DetailsConfirmationStage from './Stages/DetailsConfirmationStage/DetailsConfirmationStage';
import type { FastPlanOptionsType } from './Stages/NewCompanySetupStage/NewCompanySetupStage';
import NewCompanySetupStage, { AutomatedAiPlanBuilderSubPlanType, CompanyPlanType } from './Stages/NewCompanySetupStage/NewCompanySetupStage';
import TemplateGenerationStage from './Stages/TemplateGenerationStage/TemplateGenerationStage';
import UploadFileStage from './Stages/UploadFileStage/UploadFileStage';

/**
 * Renders the body of the OnboardingPopup component, which contains the various steps of the onboarding process.
 *
 * The body component manages the state of the company information being entered by the user, and renders the appropriate step component based on the active step. It also handles the logic for navigating between steps and disabling the "Next" button when required.
 *
 * @param {Object} props - The component props.
 * @param {string} props.activeStep - The currently active step in the onboarding process.
 * @param {function} props.setActiveStep - A function to set the active step.
 * @param {function} props.close - A function to close the onboarding popup.
 * @param {Object} props.stageState - The current state of the company information.
 * @param {function} props.setStageState - A function to update the state of the company information.
 */
const OnboardingPopupBody = ({
  activeStep,
  setActiveStep,
  close,
  stageState,
  setStageState,
  steps,
  hideCloseButton: isFirstCompany
}: {
  activeStep: keyof CompanyState;
  setActiveStep: Dispatch<SetStateAction<string>>;
  close: () => void;
  stageState: CompanyState;
  setStageState: (state: object) => void;
  steps?: Steps;
  hideCloseButton?: boolean;
}) => {
  const {
    t: translate
  } = useTranslation('company');
  const [companyState, setCompanyState] = useState<CompanyState>(stageState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Define the questions for the company details stage
  const [companyDetailsQuestions, setCompanyDetailsQuestions] = useState<Question[]>([{
    question: translate('what_are_you_building'),
    isChecked: false
  }, {
    question: translate('who_are_you_building_it_for'),
    isChecked: false
  }, {
    question: translate('when_and_where_will_it_launch'),
    isChecked: false
  }, {
    question: translate('how_will_it_be_delivered'),
    isChecked: false
  }]);
  const {
    activeCompany,
    setActiveCompany,
    onboardingState
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany,
    setActiveCompany: state.setActiveCompany,
    onboardingState: state.onboardingState
  })));
  const [mixpanelKey, setMixpanelKey] = useState<string | null>(null);
  useEffect(() => {
    setMixpanelKey(isFirstCompany ? MIXPANEL_KEYS.ONBOARDING : MIXPANEL_KEYS.NEW_COMPANY);
  }, [isFirstCompany]);

  // Reference to the company description textarea element
  const companyDescriptionRef = useRef<string | null>(null);
  const {
    updateCompany,
    finishOnboarding,
    scrapeCompany,
    fastOnboarding
  } = useMutations();
  const shallowRoute = useShallowRoute();
  const [modalContent, setModalContent] = useState<string>('');
  const {
    setAppLoader
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    setAppLoader: state.setAppLoader
  })));
  useEffect(() => {
    if (stageState) {
      setCompanyState(stageState);
    }
  }, [stageState]);
  const {
    planConfig,
    automatedAiPlanBuilderPlanConfig,
    fastPlanOptionsConfig
  } = getPlanConfigs(translate, (isFirstCompany as boolean));

  /**
   * Defines the configuration for the different steps in the onboarding process.
   * Each step has a corresponding component, an onChange handler to update the company state, and initial data.
   * The configuration also includes additional data specific to each step, such as select options and question arrays.
   */
  const stepConfig: Record<string, StepConfig<any>> = {
    'Choose your financial plan': {
      component: NewCompanySetupStage,
      onChange: (data: {
        selectedPlan: CompanyPlanType;
        subSelectedPlan: AutomatedAiPlanBuilderSubPlanType | null;
      }) => {
        setCompanyState({
          ...stageState,
          'Choose your financial plan': data
        });
      },
      data: companyState['Choose your financial plan'],
      planConfig,
      mixpanelConfig: {
        mixpanelEventString: MIXPANEL_EVENTS.PLAN_SELECTED,
        getContextFunction: (currentCompanyState: CompanyState) => ({
          plan: currentCompanyState['Choose your financial plan'].selectedPlan
        })
      },
      title: translate('lets_set_up_your_plan'),
      breadcrumbPath: (getBreadcrumbPath('Choose your financial plan', translate, isFirstCompany) as Array<{
        label: string;
      }>)
    },
    'Automated AI Plan Builder': {
      component: NewCompanySetupStage,
      onChange: (data: {
        selectedPlan: AutomatedAiPlanBuilderSubPlanType;
      }) => {
        setCompanyState({
          ...stageState,
          'Automated AI Plan Builder': data
        });
      },
      planConfig: automatedAiPlanBuilderPlanConfig,
      data: companyState['Automated AI Plan Builder'],
      mixpanelConfig: {
        mixpanelEventString: MIXPANEL_EVENTS.PLAN_SELECTED,
        getContextFunction: (currentCompanyState: CompanyState) => ({
          plan: currentCompanyState['Automated AI Plan Builder'].selectedPlan
        })
      },
      backButtonPath: 'Choose your financial plan',
      title: translate('choose_how_to_create_your_ai_financial_plan'),
      breadcrumbPath: (getBreadcrumbPath('Automated AI Plan Builder', translate, isFirstCompany) as Array<{
        label: string;
      }>)
    },
    'Fast Plan Options': {
      component: NewCompanySetupStage,
      onChange: (data: {
        selectedPlan: FastPlanOptionsType;
      }) => {
        setCompanyState({
          ...stageState,
          'Fast Plan Options': data
        });
      },
      planConfig: fastPlanOptionsConfig,
      data: companyState['Fast Plan Options'],
      mixpanelConfig: {
        mixpanelEventString: MIXPANEL_EVENTS.PLAN_SELECTED,
        getContextFunction: (currentCompanyState: CompanyState) => ({
          plan: currentCompanyState['Fast Plan Options'].selectedPlan
        })
      },
      backButtonPath: 'Choose your financial plan',
      title: translate('choose_simple_or_detailed_financial_forecast'),
      breadcrumbPath: (getBreadcrumbPath('Fast Plan Options', translate, isFirstCompany) as Array<{
        label: string;
      }>)
    },
    'Company Website': {
      component: CompanyWebsiteStage,
      onChange: (data: CompanyWebsite) => {
        setCompanyState({
          ...stageState,
          'Company Website': data
        });
      },
      data: companyState['Company Website'].website,
      nextStep: () => {
        goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany, fastOnboarding, (mixpanelKey as string), (steps as Steps));
      },
      backButtonPath: mixpanelKey === MIXPANEL_KEYS.ONBOARDING ? 'Choose your financial plan' : 'Automated AI Plan Builder',
      breadcrumbPath: (getBreadcrumbPath('Company Website', translate, isFirstCompany) as Array<{
        label: string;
      }>)
    },
    'Company Name': {
      component: CompanyNameStage,
      onChange: (data: {
        companyName: string;
      }) => {
        setCompanyState({
          ...stageState,
          'Company Name': data
        });
      },
      data: companyState['Company Name'],
      nextStep: () => {
        goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany, fastOnboarding, (mixpanelKey as string), (steps as Steps));
      }
    },
    Integrations: {
      component: IntegrationsStage,
      onChange: (data: {
        connectedIntegrations: Integration[];
      }) => {
        setCompanyState({
          ...stageState,
          Integrations: data
        });
      }
    },
    'Company Details': {
      component: CompanyDetailsStage,
      onChange: (data: CompanyDetails) => {
        setCompanyState({
          ...stageState,
          'Company Details': data
        });
      },
      data: companyState['Company Details'],
      companyDetailsQuestions,
      setCompanyDetailsQuestions,
      companyState,
      setCompanyState,
      companyDescriptionRef
    },
    'Details Confirmation': {
      component: DetailsConfirmationStage,
      onChange: (data: {
        teamLocation: string;
        companySectors: string[];
        howMuchFunding: string;
        companyStage: string;
      }) => {
        setCompanyState({
          ...stageState,
          'Details Confirmation': data
        });
      },
      data: companyState['Details Confirmation'],
      type: 'Details Confirmation',
      selectData: [{
        question: translate('team_location'),
        isMultiSelect: false,
        data: countriesData,
        key: 'teamLocation',
        type: 'string'
      }, {
        question: translate('company_sectors'),
        isMultiSelect: true,
        data: companySectorsData,
        key: 'companySectors',
        selectLimit: 3,
        type: 'string'
      }, {
        question: translate('how_much_cash_do_you_have'),
        isMultiSelect: false,
        data: fundingStagesData,
        key: 'howMuchFunding',
        type: 'currency'
      }, {
        question: translate('what_is_the_company_stage'),
        isMultiSelect: false,
        data: companyStagesData,
        key: 'companyStage',
        type: 'string'
      }],
      companyState,
      activeStepIndex: steps?.findIndex(step => (step.route || step.label) === activeStep),
      companyDescriptionRef
    },
    'Company Goals': {
      component: DetailsConfirmationStage,
      onChange: (data: CompanyGoals) => {
        setCompanyState({
          ...stageState,
          'Company Goals': data
        });
      },
      data: companyState['Company Goals'],
      type: 'Company Goals',
      selectData: [{
        question: translate('how_many_employees'),
        isMultiSelect: false,
        data: employeeData,
        key: 'howManyEmployees',
        type: 'number'
      }, {
        question: translate('revenue_a_year_from_now'),
        isMultiSelect: false,
        data: fundingStagesData,
        key: 'revenueAYearFromNow',
        type: 'currency'
      }, {
        question: translate('when_you_raise_funding'),
        isMultiSelect: false,
        data: fundingStagesData,
        key: 'whenYouRaiseFunding',
        type: 'date'
      }, {
        question: translate('how_much_capital_plan'),
        isMultiSelect: false,
        data: fundingStagesData,
        key: 'howMuchFunding',
        type: 'currency'
      }],
      companyState,
      companyDescriptionRef
    },
    'Company Products': {
      component: CompanyProductsStage,
      data: companyState['Company Products'],
      onChange: (data: CompanyProducts) => {
        setCompanyState({
          ...stageState,
          'Company Products': data
        });
      },
      activeStepIndex: steps?.findIndex(step => (step.route || step.label) === activeStep)
    },
    'Your Custom Plan': {
      component: TemplateGenerationStage,
      onChange: (data: TemplateGeneration) => {
        setCompanyState({
          ...stageState,
          'Your Custom Plan': data
        });
      },
      data: companyState['Your Custom Plan'],
      mixpanelConfig: {
        mixpanelEventString: MIXPANEL_EVENTS.GENERATE_CLICKED
      }
    },
    'Upload Your Plan': {
      component: UploadFileStage,
      nextStep: () => {
        goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany, fastOnboarding, (mixpanelKey as string), (steps as Steps));
      },
      backButtonPath: 'Choose your financial plan',
      mixpanelKey: (mixpanelKey as string),
      setActiveStep,
      setCompanyState,
      companyState
    }
  };

  /**
   * Renders a "Generate Plan" button if certain conditions are met, which triggers a fast onboarding process when clicked.
   *
   * The button is only shown if:
   * - The user has more than one company
   * - The active step is "Company Website"
   *
   * The button is disabled if:
   * - The active step is "Company Website"
   * - The company website URL is not valid
   *
   * When the button is clicked, the `fastOnboardingProcess` function is called, passing in the necessary parameters.
   */
  const fastPlanButton = () => {
    // Check if the user has more than one company and the active step is "Company Website"
    const isShowButton = activeStep === 'Company Website' && companyState['Choose your financial plan'].selectedPlan === CompanyPlanType.AutomatedAiPlanBuilder && companyState['Automated AI Plan Builder'].selectedPlan === AutomatedAiPlanBuilderSubPlanType.OneClickPlan && onboardingState?.ai_plan_type !== null;

    // Check if the company website URL is not valid
    const isButtonActive = activeStep === 'Company Website' && !isValidUrl(companyState['Company Website'].website) && companyState['Company Website'].website !== '';

    // Handle the click event for the "Generate Plan" button
    const fastPlanOnclickHandler = () => {
      if (companyState['Company Website'].website !== '') {
        fastOnboardingProcess(fastOnboarding, (companyState as CompanyState), close, shallowRoute, setActiveCompany, (mixpanelKey as string), translate);
      } else {
        setModalContent(translate('company:website_required'));
        setIsModalOpen(true);
      }
    };

    // Check if the user has more than one company and the active step is "Company Website" to render the "Fast Plan" button
    return isShowButton ? <button type="button" aria-label="button" className={styles.fastPlanButton} disabled={isButtonActive} onClick={fastPlanOnclickHandler} data-sentry-component="fastPlanButton" data-sentry-source-file="OnboardingPopupBody.tsx">
        <IoSparklesOutline data-sentry-element="IoSparklesOutline" data-sentry-source-file="OnboardingPopupBody.tsx" /> <span>{translate('complete_and_generate')}</span>
      </button> : null;
  };
  const shouldHideNextButton = () => {
    const isAutomatedAiPlan = companyState['Choose your financial plan'].selectedPlan === CompanyPlanType.AutomatedAiPlanBuilder;
    const isOneClickPlan = companyState['Automated AI Plan Builder'].selectedPlan === AutomatedAiPlanBuilderSubPlanType.OneClickPlan;
    const isWebsiteStep = activeStep === 'Company Website';
    const hasAiPlanType = onboardingState?.ai_plan_type !== null;
    const isUploadFileStep = activeStep === 'Upload Your Plan';
    return isAutomatedAiPlan && isOneClickPlan && isWebsiteStep && hasAiPlanType || isUploadFileStep;
  };
  const backToPreviousStepButton = useCallback(() => {
    const currentStepConfig = stepConfig[activeStep];
    const backPath = currentStepConfig?.backButtonPath;
    if (!backPath) {
      return null;
    }
    const onBackClick = () => {
      if (backPath) {
        mixpanelTrackEventWrapper(`${mixpanelKey} ${MIXPANEL_EVENTS.BACK_BUTTON_CLICKED}`, {
          previousStep: backPath,
          currentStep: activeStep
        });
        setActiveStep(() => {
          return backPath;
        });
      }
    };
    return <span aria-label="button" className={styles.backToPreviousStepButton} onClick={onBackClick} role="button" tabIndex={0}>
        Back
      </span>;
  }, [activeStep, setActiveStep, companyState, stepConfig]);
  return <div className={styles.onboardingPopupBody} data-sentry-component="OnboardingPopupBody" data-sentry-source-file="OnboardingPopupBody.tsx">
      <div className={styles.onboardingPopupBodyContent}>
        {getStepComponent(activeStep, stepConfig)}
      </div>
      <div className={styles.onboardingPopupBodyFooter}>
        {backToPreviousStepButton()}
        {fastPlanButton()}

        <button data-testid="next-button" disabled={buttonDisabledHandler(activeStep, (companyState as CompanyState))} style={{
        display: shouldHideNextButton() ? 'none' : 'block'
      }} className={
      // Check if the next button text starts with "Next" and if it does, add the "continue" class
      (getNextButtonText(activeStep, stepConfig, translate, (steps as Steps)) as string).toString()?.split(' ')[0] !== 'Next' ? styles.continue : ''} onClick={() => {
        goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany, fastOnboarding, (mixpanelKey as string), (steps as Steps));
        setStageState(companyState);
      }} type="button">
          {getNextButtonText(activeStep, stepConfig, translate, (steps as Steps))}
        </button>
      </div>

      <DeletePopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={modalContent} sumbitTitle={translate('company:skip_this_step')} closeModal={() => setIsModalOpen(false)} onDelete={() => {
      goToNextStep(activeStep, (companyState as CompanyState), isModalOpen, setIsModalOpen, setActiveStep, stepConfig, close, updateCompany, shallowRoute, finishOnboarding, activeCompany, setAppLoader, setActiveCompany, setModalContent, translate, scrapeCompany, fastOnboarding, (mixpanelKey as string), (steps as Steps));
      setIsModalOpen(false);
    }} data-sentry-element="DeletePopup" data-sentry-source-file="OnboardingPopupBody.tsx" />
    </div>;
};
export default OnboardingPopupBody;