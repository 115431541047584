import moment from 'moment';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import type { FormulaEntries } from '@/components/modelCalculations/modelUtils/modelUtils';
import type { CalculationsStoreState } from '@/miscellaneous/store/CalculationsStore';
import CalculationsStore from '@/miscellaneous/store/CalculationsStore';
import useZustandStore, { type ZustandState } from '@/miscellaneous/store/zustand_store';
import { parseUTCDateObject } from '@/utils/dateUtils';
import { getEmployeesCalculatedData, isEmployeeDataDefined } from '@/utils/HiringPlanUtils';
import type { Formula } from '@/utils/types/formulaTypes';
import { useProcessedBranchEmployees } from '../employees/useProcessedBranchEmployees';
import type { HiringBranchSettings } from '../settings/useHiringBranchSettings';
import useHiringBranchSettings from '../settings/useHiringBranchSettings';
import { calculateHPFormulaAtDate, GetHPFormulas } from './SystemFormulas';
export const useHiringPlanFormulas = () => {
  const {
    activeBranchId,
    timelineStartDate,
    timelineEndDate,
    hiringDonutDateState
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeBranchId: state.activeBranch?.id ?? null,
    timelineStartDate: state.timelineStartDate,
    timelineEndDate: state.timelineEndDate,
    hiringDonutDateState: state.hiringDonutDateState
  })));
  const {
    branchHiringSetting
  } = useHiringBranchSettings();
  const {
    setHPFormulas,
    setHPFormulaEntries
  } = CalculationsStore(useShallow((state: CalculationsStoreState) => ({
    setHPFormulas: state.setHPFormulas,
    setHPFormulaEntries: state.setHPFormulaEntries
  })));
  const {
    employees
  } = useProcessedBranchEmployees();
  const {
    setCalculatedEmployeeData,
    calculatedEmployeeData,
    setHPGraphDataCost,
    setHPGraphDataHeadcount,
    setHPCostPerMonthHeadcount,
    setHPCostPerMonthPayroll
  } = CalculationsStore((state: CalculationsStoreState) => state);
  useEffect(() => {
    // calculate employee data based on current Employees
    const calculatedData = getEmployeesCalculatedData(employees, moment(timelineStartDate).toDate(), moment(timelineEndDate).toDate(), (branchHiringSetting as HiringBranchSettings));
    setCalculatedEmployeeData(calculatedData);
    setHPGraphDataCost(calculatedData);
    setHPGraphDataHeadcount(calculatedData);
  }, [employees, timelineStartDate, timelineEndDate, setCalculatedEmployeeData, branchHiringSetting]);
  useEffect(() => {
    setHPCostPerMonthHeadcount(calculatedEmployeeData, (hiringDonutDateState as Date));
    setHPCostPerMonthPayroll(calculatedEmployeeData, (hiringDonutDateState as Date));
  }, [calculatedEmployeeData, hiringDonutDateState, setHPCostPerMonthHeadcount, setHPCostPerMonthPayroll]);
  useEffect(() => {
    const outputId = branchHiringSetting?.payroll_output_id || 0;
    const formulas = GetHPFormulas(activeBranchId, outputId);
    setHPFormulas(formulas);

    // check if calculatedEmployeeData.headCountByDepartment has any keys
    const hasData = isEmployeeDataDefined(calculatedEmployeeData);
    if (!hasData) {
      setHPFormulaEntries({}); // we need to handle the case where there is no data.
    } else if (activeBranchId && hasData) {
      // create FormulaEntries for all the HPformulas calculating each date at a time with calculatedEmployeeData and calculateHPFormulaAtDate
      const HPformulaEntries: FormulaEntries = {};
      const newStartDate = parseUTCDateObject(timelineStartDate);
      const newEndDate = parseUTCDateObject(timelineEndDate);
      // iterate over the list of dates in format  YYYY-MM-DD
      for (let date = newStartDate; date <= newEndDate;
      // move date up 1 month
      date.setMonth(date.getMonth() + 1)) {
        const currentDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`; // getting yyyy-mm-dd

        if (currentDate) formulas.forEach((formula: Formula) => {
          const formulaValue = calculateHPFormulaAtDate(formula, currentDate || '', calculatedEmployeeData);
          const curFormulaEntries = HPformulaEntries[formula.id];
          if (!curFormulaEntries) {
            HPformulaEntries[formula.id] = {
              to_account: formula.output_id || 0,
              [currentDate]: formulaValue
            };
          } else {
            curFormulaEntries[currentDate] = formulaValue;
          }
        });
      }
      setHPFormulaEntries(HPformulaEntries);
    }
  }, [activeBranchId, branchHiringSetting, calculatedEmployeeData, timelineEndDate, timelineStartDate]);
};