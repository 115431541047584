// eslint-disable-next-line simple-import-sort/imports
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import dynamicRoute from '@/miscellaneous/constant';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import styles from '@/styles/scss/header.module.scss'; // Import your SCSS styles
import { staticStyles } from '@/styles/variables/sizes';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import { MIXPANEL_EVENTS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import { Mic, MicOff } from 'lucide-react';
import { useMemo, useState } from 'react';
import { AiOutlineYoutube } from 'react-icons/ai';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { useShallow } from 'zustand/react/shallow';
import avatarImg from '@/public/assets/images/avatarImg.png';
import MenuWrapper from '../MenuComponent/wrapper/MenuWrapper';

/**
 * AvatarImage component displays the user's profile image with fallback handling.
 * This component fetches the user's profile image from the global state and displays it.
 * If the image fails to load or doesn't exist, it falls back to a default avatar image.
 */
export const AvatarImage = ({
  className = ''
}: {
  className?: string;
}) => {
  const [imageError, setImageError] = useState(false);
  const {
    userMe
  } = useZustandStore(useShallow((state: ZustandState) => ({
    userMe: state.userMe
  })));

  /**
   * Handles image loading errors by setting the error state to true,
   * which triggers the fallback to the default avatar image.
   */
  const handleImageError = () => {
    setImageError(true);
  };

  // Determine the avatar URL with proper error handling
  const avatarUrl = imageError || !userMe?.image ? avatarImg.src : userMe.image;
  return <img className={className} src={avatarUrl} alt="avatar" onError={handleImageError} data-sentry-component="AvatarImage" data-sentry-source-file="UserSetting.tsx" />;
};
const UserSetting = () => {
  const {
    t
  } = useTranslation(['common', 'models', 'personalinfo']);
  const {
    activeCompany,
    activeBranch,
    logoutApp
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany,
    activeBranch: state.activeBranch,
    logoutApp: state.logoutApp
  })));
  const {
    setIsTutorialsModalOpen,
    setIsHotWordListener,
    isHotWordListener
  } = useUtilsStore(useShallow(state => ({
    setIsTutorialsModalOpen: state.setIsTutorialsModalOpen,
    setIsHotWordListener: state.setIsHotWordListener,
    isHotWordListener: state.isHotWordListener
  })));
  const router = useRouter();

  // Handle hot word toggle
  const handleHotWordToggle = () => {
    setIsHotWordListener(!isHotWordListener);
  };

  /**
   * User settings menu items.
   * @type {Array}
   */
  const userSettingsMenu = useMemo(() => [
  // {
  //   id: 1,
  //   icon: (
  //     <img
  //       height={24}
  //       width={24}
  //       src="/assets/icons/profile.svg"
  //       alt="profile"
  //     />
  //   ),
  //   itemName: t('personalinfo:profile'),
  //   onClick: () => {},
  //   isDisabled: true,
  // },
  {
    id: 2,
    icon: <img height={24} width={24} src="/assets/icons/integrations.svg" alt="integrations" />,
    itemName: t('common:integration'),
    onClick: () => {
      router.push(`/${activeCompany.slug}/${activeBranch.slug}${dynamicRoute?.integrations}`);
    },
    isDisabled: false
  }, {
    id: 3,
    icon: <img height={24} width={24} src="/assets/icons/settings.svg" alt="settings" />,
    itemName: t('common:settings'),
    onClick: () => {
      router.push(`/${activeCompany.slug}/${activeBranch.slug}${dynamicRoute?.templates}`);
    },
    isDisabled: false
  },
  // {
  //   id: 4,
  //   icon: (
  //     <img
  //       height={24}
  //       width={24}
  //       src="/assets/icons/refer_friend.svg"
  //       alt="refer_friend"
  //     />
  //   ),
  //   itemName: t('common:refer_to_friend'),
  //   onClick: () => {},
  //   isDisabled: true,
  // },

  {
    id: 4,
    itemName: t('common:hot_words'),
    icon: isHotWordListener ? <MicOff /> : <Mic />,
    onClick: handleHotWordToggle,
    isDisabled: false
  }, {
    id: 5,
    itemName: t('common:documentation'),
    onClick: () => {
      const url = 'https://lucid-dreams.gitbook.io/lucid-financial';
      window.open(url, '_blank');
    },
    icon: <IoDocumentTextOutline size={24} />
  }, {
    id: 6,
    itemName: t('common:tutorials'),
    onClick: () => {
      setIsTutorialsModalOpen(true);
    },
    icon: <AiOutlineYoutube size={24} />
  }, {
    id: 7,
    icon: <img height={24} width={24} src="/assets/icons/logout.svg" alt="logout" />,
    itemName: t('common:logout'),
    onClick: () => {
      mixpanelTrackEventWrapper(MIXPANEL_EVENTS.LOGOUT_CLICKED);
      logoutApp();
    },
    isDisabled: false
  }], [isHotWordListener, activeCompany, activeBranch]);

  /**
   * Handle item click based on the given event.
   * @param {any} e - The event or item identifier.
   * @description This function handles item clicks based on the provided event or item identifier. If the identifier is '2', it navigates to the settings page using the router.
   */

  const onItemClick = (e: any) => {
    const itemId = parseInt(e, 10);
    const item = userSettingsMenu.find((menuItem: any) => menuItem.id === itemId);
    if (item?.onClick) {
      item.onClick();
    }
  };
  return <div className="mt-1 shrink-0" data-sentry-component="UserSetting" data-sentry-source-file="UserSetting.tsx">
      <MenuWrapper menu={userSettingsMenu} radius={`${staticStyles.r_2}`} align="p-0" width="w-38" Icon={<AvatarImage className={`${styles.user} h-8`} />} top={`${staticStyles.t_40}`} leftPlacement={` ${staticStyles.l_190}`} listItemStyles={styles.userSettingsLi || ''} onAction={(e: any) => onItemClick(e)} data-sentry-element="MenuWrapper" data-sentry-source-file="UserSetting.tsx" />
    </div>;
};
export default UserSetting;