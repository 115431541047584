import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from '@/components/table/shared/table/GeneralTable/styles/baseTableStyles.module.scss';

/**
 * FloatingBubble component provides a draggable indicator that follows a target element
 * and allows resizing through drag interactions.
 */
export const FloatingBubble: React.FC<{
  targetRef: React.RefObject<HTMLDivElement>; // Reference to the target element to follow
  onDrag: (deltaX: number) => void; // Callback during drag with the change amount
  currentWidth: number; // Current width of the target element
  stepSize: number; // Size of each step when rounding final position
  minSize: number; // Minimum allowed size
  maxSize: number; // Maximum allowed size
  onDragEnd?: (newSize: number) => void; // Callback when drag completes
  hide?: boolean; // Whether to hide the bubble
}> = ({
  targetRef,
  onDrag,
  currentWidth,
  stepSize,
  minSize,
  maxSize,
  onDragEnd,
  hide
}) => {
  // Position and visibility state
  const [position, setPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [isVisible, setIsVisible] = useState(true);

  // Core drag state
  const dragState = useRef({
    isDragging: false,
    startX: 0,
    initialWidth: 0,
    deltaX: 0
  });

  // Position tracking state
  const trackingState = useRef({
    targetRect: (null as DOMRect | null),
    lastMoveTime: Date.now(),
    lastUpdateTime: 0,
    isForceShown: false
  });

  // Animation and timeout refs
  const animationFrame = useRef<number | null>(null);
  const showTimeout = useRef<number | null>(null);

  /**
   * Rounds a size to the nearest interval (step size)
   */
  const roundToStepSize = (size: number): number => {
    return Math.round(size / stepSize) * stepSize;
  };

  /**
   * Updates the bubble position based on the target element
   */
  const updatePosition = useCallback(() => {
    if (!targetRef.current) return;
    const rect = targetRef.current.getBoundingClientRect();

    // Check for horizontal movement (e.g., during scrolling)
    if (!dragState.current.isDragging && trackingState.current.targetRect) {
      const xDifference = Math.abs(trackingState.current.targetRect.left - rect.left);
      if (xDifference > 3) {
        trackingState.current.lastMoveTime = Date.now();

        // Hide bubble during horizontal movement, unless forced visible
        if (isVisible && !trackingState.current.isForceShown) {
          setIsVisible(false);

          // Schedule showing the bubble again after movement stops
          if (showTimeout.current) clearTimeout(showTimeout.current);
          showTimeout.current = window.setTimeout(() => {
            setIsVisible(true);
            trackingState.current.isForceShown = true;
            showTimeout.current = null;
          }, 300);
        }
      }
    }

    // Update tracking state
    trackingState.current.targetRect = rect;
    trackingState.current.lastUpdateTime = Date.now();

    // Set new position
    const newPosition = {
      x: rect.left + currentWidth,
      y: rect.top - 50
    };
    setPosition(newPosition);
  }, [targetRef, currentWidth, isVisible]);

  /**
   * Monitor for movement stopping to show the bubble again
   */
  useEffect(() => {
    if (!isVisible) {
      const checkIfMovementStopped = () => {
        const timeSinceLastMove = Date.now() - trackingState.current.lastMoveTime;
        if (timeSinceLastMove > 300) {
          // No movement for 300ms, show the bubble
          setIsVisible(true);
          trackingState.current.isForceShown = false;
        } else {
          // Check again soon
          setTimeout(checkIfMovementStopped, 100);
        }
      };
      checkIfMovementStopped();
    } else {
      trackingState.current.isForceShown = false;
    }
  }, [isVisible]);

  /**
   * Continuous position monitoring using requestAnimationFrame
   */
  const monitorPosition = useCallback(() => {
    if (targetRef.current && !dragState.current.isDragging) {
      // Skip if we just updated recently (performance optimization)
      const now = Date.now();
      if (now - trackingState.current.lastUpdateTime < 50) {
        animationFrame.current = requestAnimationFrame(monitorPosition);
        return;
      }
      const newRect = targetRef.current.getBoundingClientRect();

      // Only update if position changed significantly
      if (trackingState.current.targetRect) {
        const xDiff = Math.abs(trackingState.current.targetRect.left - newRect.left);
        const yDiff = Math.abs(trackingState.current.targetRect.top - newRect.top);
        if (xDiff > 3 || yDiff > 3) {
          updatePosition();
        }
      }
    }
    animationFrame.current = requestAnimationFrame(monitorPosition);
  }, [targetRef, updatePosition]);

  // Update position when width changes
  useEffect(() => {
    updatePosition();
  }, [currentWidth, updatePosition]);

  // Update position when target changes
  useEffect(() => {
    updatePosition();
  }, [targetRef, updatePosition]);

  // Set up event listeners and animation loop
  useEffect(() => {
    // Event handlers
    const handleScroll = () => {
      if (!dragState.current.isDragging) updatePosition();
    };
    const handleResize = () => {
      if (!dragState.current.isDragging) updatePosition();
    };

    // Initialize target observer
    let resizeObserver: ResizeObserver | null = null;
    if (targetRef.current) {
      resizeObserver = new ResizeObserver(() => {
        if (!dragState.current.isDragging) updatePosition();
      });
      resizeObserver.observe(targetRef.current);
    }

    // Set up listeners
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });
    window.addEventListener('resize', handleResize);

    // Start animation frame loop
    animationFrame.current = requestAnimationFrame(monitorPosition);

    // Cleanup on unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }
      if (showTimeout.current) {
        clearTimeout(showTimeout.current);
      }
      if (resizeObserver && targetRef.current) {
        resizeObserver.disconnect();
      }
    };
  }, [targetRef, updatePosition, monitorPosition]);

  /**
   * Handle mouse movement during drag
   */
  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!dragState.current.isDragging) return;

    // Calculate how far we've moved
    const rawDeltaX = e.clientX - dragState.current.startX;
    dragState.current.deltaX = rawDeltaX;

    // Calculate and constrain the new width
    const newWidth = dragState.current.initialWidth + rawDeltaX;
    const clampedWidth = Math.max(minSize, Math.min(maxSize, newWidth));
    const actualDeltaX = clampedWidth - dragState.current.initialWidth;

    // Update bubble position
    if (position && targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({
        x: rect.left + clampedWidth,
        y: position.y
      });
    }

    // Notify parent of drag
    onDrag(actualDeltaX);
  }, [onDrag, minSize, maxSize, position, targetRef]);

  /**
   * Handle end of drag operation
   */
  const handleMouseUp = useCallback(() => {
    if (dragState.current.isDragging && onDragEnd) {
      // Calculate final size, rounded to step size
      const newSize = roundToStepSize(dragState.current.initialWidth + dragState.current.deltaX);
      const finalSize = Math.max(minSize, Math.min(maxSize, newSize));

      // Update bubble position
      if (position && targetRef.current) {
        const rect = targetRef.current.getBoundingClientRect();
        setPosition({
          x: rect.left + finalSize,
          y: position.y
        });
      }

      // Notify parent of drag end
      onDragEnd(finalSize);
    }

    // Reset drag state
    dragState.current.isDragging = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    // Show bubble again
    setIsVisible(true);
  }, [onDragEnd, handleMouseMove, position, targetRef, minSize, maxSize, stepSize]);

  /**
   * Start drag operation
   */
  const handleMouseDown = useCallback((e: MouseEvent) => {
    if (!position) return;
    e.preventDefault();
    e.stopPropagation();

    // Initialize drag state
    dragState.current.isDragging = true;
    dragState.current.startX = e.clientX;
    dragState.current.initialWidth = currentWidth;
    dragState.current.deltaX = 0;

    // Add event listeners for drag operation
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }, [currentWidth, handleMouseMove, handleMouseUp, position]);

  // Don't render if we don't have position or should be hidden
  if (!position || !isVisible) return null;
  return ReactDOM.createPortal(<div className={styles.floatingBubble} style={{
    position: 'fixed',
    top: position.y,
    left: position.x,
    cursor: 'col-resize',
    userSelect: 'none',
    transition: dragState.current.isDragging ? 'none' : 'left 0.05s linear',
    visibility: hide ? 'hidden' : 'visible'
  }} onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => handleMouseDown(e.nativeEvent)}>
      <div className={styles.floatingBubbleContent}>
        <span>Current Date</span>
      </div>
    </div>, document.body);
};