import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbLogout2 } from 'react-icons/tb';
import { useShallow } from 'zustand/react/shallow';
import styles from '@/components/common/GenericStagePopup/genericStagePopup.module.scss';
import type { ITemplate } from '@/components/Settings/Template/components';
import { AvatarImage } from '@/components/Settings/UserSetting';
import DataStore from '@/miscellaneous/store/DataStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, MIXPANEL_KEYS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import useCompany from '@/utils/hooks/company/useCompany';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { useActiveTemplatesQuery } from '@/utils/hooks/template/useTemplateHooks';
import type { Integration } from '@/utils/hooks/useIntegrations/useIntegrations';
import DeletePopup from '../DeletePopup/DeletePopup';
import type { StepObject } from '../GenericStagePopup/GenericStagePopup';
import GenericStagePopup from '../GenericStagePopup/GenericStagePopup';
import OnboardingPopupBody from './OnboardingPopupBody';
import type { CompanyState } from './onboardingPopupTypes';
import { getInitialSteps, getNextStep, getPopupStyles, handlePlanSelection, initialCompanyState } from './onboardingUtils';
import { AutomatedAiPlanBuilderSubPlanType, FastPlanOptionsType } from './Stages/NewCompanySetupStage/NewCompanySetupStage';
type OnboardingPopupProps = {
  /** Controls visibility of the onboarding popup */
  open: boolean;
  /** Function to toggle popup visibility */
  setOpen: (open: boolean) => void;
};

/**
 * Popup component that guides users through company onboarding process.
 * Handles multi-step form flow including company setup, integrations, details and plan selection.
 * Uses GenericStagePopup for the base popup structure and manages onboarding state.
 *
 * @param {OnboardingPopupProps} props - The props for the `OnboardingPopup` component.
 * @param {boolean} props.open - Whether the popup is open or not.
 * @param {(open: boolean) => void} props.setOpen - A function to set the open state of the popup.
 * @returns {JSX.Element} - The rendered `OnboardingPopup` component.
 */
const OnboardingPopup = ({
  open,
  setOpen
}: OnboardingPopupProps) => {
  const {
    t: translate
  } = useTranslation('company');
  const {
    deleteCompany
  } = useMutations();
  const {
    userCompanies,
    setActiveCompany
  } = useCompany();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    activeIntegrations: integrations
  } = DataStore(useShallow(state => ({
    activeIntegrations: state.activeIntegrations
  })));
  const {
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => ({
    activeCompany: state.activeCompany
  })));
  const {
    activeTemplates
  } = useActiveTemplatesQuery();

  // Get styles from utils
  const {
    popupStyles,
    overlayStyles
  } = getPopupStyles();
  const isOnboarding = userCompanies.length === 1;

  // Get initial steps from utils
  const allSteps = useMemo(() => getInitialSteps(translate, isOnboarding), [translate, isOnboarding]);
  const [complatedStepCount, setComplatedStepCount] = useState<number>(0);
  const {
    logoutApp,
    setOnboardingState,
    userMe
  } = useZustandStore((state: ZustandState) => {
    return {
      logoutApp: state.logoutApp,
      setOnboardingState: state.setOnboardingState,
      onboardingState: state.onboardingState,
      userMe: state.userMe
    };
  });
  const [activeStep, setActiveStep] = useState<string>(((allSteps[0] as StepObject).route as string));
  const [companyState, setCompanyState] = useState<CompanyState>(() => {
    return {
      'Choose your financial plan': {
        selectedPlan: null
      },
      'Upload Your Plan': {},
      'Automated AI Plan Builder': {
        selectedPlan: AutomatedAiPlanBuilderSubPlanType.OneClickPlan
      },
      'Fast Plan Options': {
        selectedPlan: FastPlanOptionsType.SimpleFastPlan
      },
      'Company Name': {
        companyName: (activeCompany.name as string) || initialCompanyState['Company Name'].companyName
      },
      Integrations: {
        connectedIntegrations: (integrations as Integration[]) || initialCompanyState.Integrations.connectedIntegrations
      },
      'Company Website': {
        website: (activeCompany.website as string) || initialCompanyState['Company Website'].website
      },
      'Details Confirmation': {
        teamLocation: (activeCompany.location as string) || initialCompanyState['Details Confirmation'].teamLocation,
        companySectors: activeCompany.sectors?.split('|') || initialCompanyState['Details Confirmation'].companySectors,
        howMuchFunding: (activeCompany.funding_raise as string) || initialCompanyState['Details Confirmation'].howMuchFunding,
        companyStage: (activeCompany.company_stage as string) || initialCompanyState['Details Confirmation'].companyStage
      },
      'Company Goals': {
        howManyEmployees: (activeCompany.target_employees_in_one_year as string) || initialCompanyState['Company Goals'].howManyEmployees,
        revenueAYearFromNow: (activeCompany.target_revenue_in_one_year as string) || initialCompanyState['Company Goals'].revenueAYearFromNow,
        whenYouRaiseFunding: (activeCompany.raise_next_round_date as string) || initialCompanyState['Company Goals'].whenYouRaiseFunding,
        howMuchFunding: (activeCompany.target_round_funding as string) || initialCompanyState['Company Goals'].howMuchFunding
      },
      'Company Products': {
        products: activeCompany.products?.map(product => ({
          name: product.name,
          price: product.price,
          sold_last_month: product.amount_sold_last_m,
          sold_1_year_ago: product.amount_sold_y_ago,
          subscription_type: product.subscription_type,
          cac: product.CAC
        })) || initialCompanyState['Company Products'].products
      },
      'Company Details': {
        text: (activeCompany.description as string) || initialCompanyState['Company Details'].text
      },
      'Your Custom Plan': {
        chosenTemplates: activeTemplates?.map((template: ITemplate) => template)
      }
    };
  });

  /**
   * Effect to initialize the onboarding state and track events
   */
  useEffect(() => {
    const mixPanelEventString = userCompanies.length === 1 ? MIXPANEL_EVENTS.ONBOARDING_STARTED : MIXPANEL_EVENTS.NEW_COMPANY_STARTED;
    mixpanelTrackEventWrapper(mixPanelEventString);
  }, []);

  // Define the function to reset the step to the first step
  const resetStep = () => {
    setActiveStep((allSteps[0] as StepObject).label);
    setOpen(false);
    setCompanyState(initialCompanyState);
  };

  /**
   * Closes the onboarding popup
   * @param isClosePopup - Whether the popup is being closed by the user
   */
  const closePopup = (isClosePopup?: boolean) => {
    if (activeStep === 'Your Custom Plan' || isClosePopup) {
      resetStep();
      return;
    }
    setIsModalOpen(true);
  };

  /**
   * Effect to update the active step and company state based on the active company's onboarding step
   */
  useEffect(() => {
    if (activeCompany && activeCompany.onboarding_step) {
      const currentStep = (allSteps[activeCompany.onboarding_step || 0] as StepObject)?.route;
      setActiveStep(currentStep || '');
      setComplatedStepCount(activeCompany.onboarding_step || 0);
      setCompanyState({
        ...companyState,
        'Company Name': {
          companyName: (activeCompany.name as string)
        },
        Integrations: {
          connectedIntegrations: (integrations as Integration[])
        },
        'Company Website': {
          website: (activeCompany.website as string)
        },
        'Details Confirmation': {
          teamLocation: (activeCompany.location as string),
          companySectors: activeCompany.sectors?.split('|') || [],
          howMuchFunding: (activeCompany.funding_raise as string),
          companyStage: (activeCompany.company_stage as string)
        },
        'Company Goals': {
          howManyEmployees: (activeCompany.target_employees_in_one_year as string),
          revenueAYearFromNow: (activeCompany.target_revenue_in_one_year as string),
          whenYouRaiseFunding: (activeCompany.raise_next_round_date as string),
          howMuchFunding: (activeCompany.target_round_funding as string)
        },
        'Company Products': {
          ...companyState['Company Products'],
          products: activeCompany.products?.map(product => ({
            name: product.name,
            price: product.price,
            sold_last_month: product.amount_sold_last_m,
            sold_1_year_ago: product.amount_sold_y_ago,
            subscription_type: product.subscription_type,
            cac: product.CAC
          })) || []
        },
        'Company Details': {
          text: (activeCompany.description as string)
        },
        'Your Custom Plan': {
          chosenTemplates: activeTemplates?.map((template: ITemplate) => template)
        }
      });
    }
  }, [activeCompany]);
  const navigateToStep = (step: StepObject) => {
    setActiveStep(step.route || step.label);
  };

  /**
   * Additional header content for the onboarding popup
   */
  const additionalHeaderContent = userCompanies.length === 1 ? <div className={styles.logoutButton} onClick={() => {
    closePopup(true);
    mixpanelTrackEventWrapper(`${MIXPANEL_KEYS.ONBOARDING} ${MIXPANEL_EVENTS.LOGOUT_CLICKED}`);
    logoutApp();
  }}>
        <TbLogout2 size={22} />
        <span>Logout</span>
      </div> : undefined;

  /**
   * Sets the active step handler for the onboarding popup
   * @param step - The step to set as active
   */
  const setActiveStepHandler = useCallback((step: string) => {
    const currentStep = allSteps.find(s => s.route === step || s.label === step);
    if (currentStep?.is_plan_selection_step) {
      setOnboardingState(handlePlanSelection(step, companyState));
    }
    setActiveStep(getNextStep(step, companyState));
  }, [companyState]);
  const username = userMe?.first_name?.trim() || userMe?.full_name?.trim() || userMe?.email?.split('@')[0]?.trim() || userMe?.username || '';
  const usernameString = username ? `, ${username}!` : '!';
  const titleContent = <div className={styles.titleContent}>
      <AvatarImage />
      <h1>{`${translate('welcome')}${usernameString}`}</h1>
    </div>;
  return <>
      <GenericStagePopup title={translate('create_your_plan')} titleContent={titleContent} steps={allSteps} overlayStyles={overlayStyles} poupStyles={popupStyles} setActiveStep={setActiveStepHandler} activeStep={activeStep} resetStep={closePopup} setStageState={state => setCompanyState((state as CompanyState))} stageState={companyState} stageComponent={OnboardingPopupBody} isModal open={open} hideCloseButton={userCompanies.length === 1} navigateToStep={navigateToStep} complatedStepCount={complatedStepCount || activeCompany.onboarding_step} isOnboarding additionalHeaderContent={additionalHeaderContent} data-sentry-element="GenericStagePopup" data-sentry-source-file="OnboardingPopup.tsx" />
      <DeletePopup isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} title={translate('company:are_you_sure_close_popup')} sumbitTitle={translate('company:close')} closeModal={() => setIsModalOpen(false)} onDelete={() => {
      mixpanelTrackEventWrapper(`${MIXPANEL_KEYS.NEW_COMPANY} ${MIXPANEL_EVENTS.CLOSED_POPUP}`);
      resetStep();
      setIsModalOpen(false);
      setActiveCompany(userCompanies[0]);
      deleteCompany.mutate({
        id: activeCompany?.id
      });
    }} data-sentry-element="DeletePopup" data-sentry-source-file="OnboardingPopup.tsx" />
    </>;
};
export default OnboardingPopup;