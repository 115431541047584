import { useShallow } from 'zustand/react/shallow';
import ApiUtils from '@/api/ApiUtils';
import { QUERYKEYS } from '@/miscellaneous/constant/reactQueryKeyConfig';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import type { Formula } from '@/utils/types/formulaTypes';
import type { Company } from '../company/useCompany';
import { processFormula } from '../formulas/useFormulas';
import { useBulkDeleteMutation, useCreateMutation, useDeleteMutation, useUpdateMutation } from '../useGenericMutation';
const useCreateMutations = () => {
  // console.time('useCreateMutations');
  const {
    userName,
    activeBranchId,
    activeCompanyId
  } = useZustandStore(useShallow((state: ZustandState) => ({
    userName: state.userMe?.email,
    activeBranchId: state.activeBranch?.id,
    activeCompanyId: state.activeCompany?.id
  })));
  const aiExplainer = useCreateMutation('aiExplainer', ApiUtils.aiExplainer);

  /**
   * Applies a template using the provided API utility function.
   *
   * @param {string} QUERYKEYS.ACTIVETEMPLATES - The query key for the active templates.
   * @param {typeof ApiUtils.applyTemplate} ApiUtils.applyTemplate - The API utility function to apply the template.
   * @returns {ReturnType<typeof useCreateMutation>} - The result of the `useCreateMutation` hook.
   */
  const applyTemplate = useCreateMutation(QUERYKEYS.ACTIVETEMPLATES, ApiUtils.applyTemplate);
  const createTemplate = useCreateMutation(QUERYKEYS.CUSTOM_TEMPLATES, ApiUtils.createTemplate);

  /**
   * Removes a template using the provided `ApiUtils.removeTemplate` function.
   * This mutation is associated with the `QUERYKEYS.ACTIVETEMPLATES` query key.
   */
  const removeTemplate = useDeleteMutation([QUERYKEYS.ACTIVETEMPLATES, activeCompanyId, activeBranchId], ApiUtils.removeTemplate);

  /**
   * Custom react query hook to update a branch's hiring settings.
   * Uses the generic useUpdateMutation hook with the branch hiring settings query key
   * and the ApiUtils.updateBranchHiringSettings API method.
   */
  const updateOutput = useUpdateMutation(QUERYKEYS.GETALLOUTPUTS, ApiUtils.updateOutput);
  /**
   * Custom react query hook to create new branch hiring settings.
   * Uses the generic useCreateMutation hook with the branch hiring settings query key
   * and the ApiUtils.createBranchHiringSettings API method.
   */
  const createOutput = useCreateMutation(QUERYKEYS.GETALLOUTPUTS, ApiUtils.createOutput);
  const deleteOutput = useDeleteMutation(QUERYKEYS.GETALLOUTPUTS, ApiUtils.deleteOutput);

  /**
   * Custom react query hook to update a branch's hiring settings.
   * Uses the generic useUpdateMutation hook with the branch hiring settings query key
   * and the ApiUtils.updateBranchHiringSettings API method.
   */
  const updateBranchHiringSettings = useUpdateMutation(QUERYKEYS.BRANCHESHIRINGSETTINGS, ApiUtils.updateBranchHiringSettings);
  /**
   * Custom react query hook to create new branch hiring settings.
   * Uses the generic useCreateMutation hook with the branch hiring settings query key
   * and the ApiUtils.createBranchHiringSettings API method.
   */
  const createBranchHiringSettings = useCreateMutation(QUERYKEYS.BRANCHESHIRINGSETTINGS, ApiUtils.createBranchHiringSettings);
  /**
   * Custom react query hook to delete an employee column.
   * Uses the generic useDeleteMutation hook with the employee columns query key
   * and the ApiUtils.deleteEmployeeColumn API method.
   */
  const deleteEmployeeColumn = useDeleteMutation(QUERYKEYS.EMPLOYEE_COLUMNS, ApiUtils.deleteEmployeeColumn);

  /**
   * Custom react query hook to create a new employee column.
   * Uses the generic useCreateMutation hook with the employee columns query key
   * and the ApiUtils.createEmployeeColumn API method.
   */
  const createEmployeeColumn = useCreateMutation(QUERYKEYS.EMPLOYEE_COLUMNS, ApiUtils.createEmployeeColumn);

  /**
   * Custom react query hook to update an employee column.
   * Uses the generic useUpdateMutation hook with the employee columns query key
   * and the ApiUtils.updateEmployeeColumn API method.
   */
  const updateEmployeeColumn = useUpdateMutation(QUERYKEYS.EMPLOYEE_COLUMNS, ApiUtils.updateEmployeeColumn);
  const createBulkGraph = useCreateMutation(QUERYKEYS.GRAPHSBYCOMPANYID, ApiUtils.createBulkGraph);
  const updateBulkGraph = useCreateMutation(QUERYKEYS.GRAPHSBYCOMPANYID, ApiUtils.updateBulkGraph);
  const createGraph = useCreateMutation(QUERYKEYS.GRAPHSBYCOMPANYID, ApiUtils.createGraph);
  const updateGraph = useUpdateMutation(QUERYKEYS.GRAPHSBYCOMPANYID, ApiUtils.updateGraph);
  const deleteGraph = useDeleteMutation(QUERYKEYS.GRAPHSBYCOMPANYID, ApiUtils.deleteGraph);
  const createBranch = useCreateMutation(QUERYKEYS.BRANCHBYCOMPANYID, ApiUtils.createBranch);
  const updateBranch = useUpdateMutation(QUERYKEYS.BRANCHBYCOMPANYID, ApiUtils.updateBranch);
  const deleteBranch = useDeleteMutation(QUERYKEYS.BRANCHBYCOMPANYID, ApiUtils.deleteBranch);
  const createModel = useCreateMutation(QUERYKEYS.ALLMODELS, ApiUtils.createModel);
  const createCompany = useCreateMutation([QUERYKEYS.ALLCOMPANIES, userName], ApiUtils.createCompany);
  const updateCompany = useUpdateMutation([QUERYKEYS.ALLCOMPANIES, userName], ApiUtils.updateCompany, (data: Company) => {
    const {
      setActiveCompany,
      activeCompany
    } = useZustandStore.getState();
    if (data.id === activeCompany?.id) {
      setActiveCompany(data);
    }
  });
  const deleteCompany = useDeleteMutation([QUERYKEYS.ALLCOMPANIES, userName], ApiUtils.deleteCompany);
  const updateModels = useUpdateMutation(QUERYKEYS.ALLMODELS, ApiUtils.updateModel);
  const updateBulkModels = useUpdateMutation(QUERYKEYS.ALLMODELS, ApiUtils.updateBulkModels);
  const deleteModel = useDeleteMutation(QUERYKEYS.ALLMODELS, ApiUtils.deleteModel);
  const createHiringPlan = useCreateMutation(QUERYKEYS.HIRING_PLANS, ApiUtils.createHiringPlan);
  const updateHiringPlan = useUpdateMutation(QUERYKEYS.HIRING_PLANS, ApiUtils.updateHiringPlan);
  const updateReport = useUpdateMutation(QUERYKEYS.REPORTS, ApiUtils.updateReport, undefined,
  // passing undefined as onSuccessCallback
  'reports' // passing dataKey for reports update cache due to call structure
  );
  const createReport = useCreateMutation(QUERYKEYS.REPORTS, ApiUtils.createReport, undefined,
  // passing undefined as onSuccessCallback
  undefined,
  // Assuming onErrorCallback is not used
  'reports' // passing dataKey for reports update cache due to call structure
  );
  const deleteReport = useDeleteMutation(QUERYKEYS.REPORTS, ApiUtils.deleteReport, undefined, 'reports');
  const updateReportItem = useUpdateMutation(QUERYKEYS.REPORTS, ApiUtils.updateReportItem, undefined,
  // passing undefined as onSuccessCallback
  'report_items' // passing dataKey for reports update cache due to call structure
  );
  const createReportItem = useCreateMutation(QUERYKEYS.REPORTS, ApiUtils.createReportItem, undefined,
  // passing undefined as onSuccessCallback
  undefined,
  // Assuming onErrorCallback is not used
  'report_items' // passing dataKey for reports update cache due to call structure
  );
  const deleteReportItem = useDeleteMutation(QUERYKEYS.REPORTS, ApiUtils.deleteReportItem, undefined, 'report_items');
  const deleteHiringPlan = useDeleteMutation(QUERYKEYS.HIRING_PLANS, ApiUtils.deleteHiringPlan);
  const updateEmployee = useUpdateMutation(QUERYKEYS.ALLEMPLOYEES, ApiUtils.updateEmployee);
  const deleteEmployee = useDeleteMutation(QUERYKEYS.ALLEMPLOYEES, ApiUtils.deleteEmployee);
  const createEmployee = useCreateMutation(QUERYKEYS.ALLEMPLOYEES, ApiUtils.createEmployee);
  const createFormula = useCreateMutation(QUERYKEYS.ALLFORMULAS, ApiUtils.createFormula);
  const deleteFormula = useDeleteMutation(QUERYKEYS.ALLFORMULAS, ApiUtils.deleteFormula);
  const updateFormula = useUpdateMutation(QUERYKEYS.ALLFORMULAS, ApiUtils.updateFormula, (data: any) => data,
  // no onSuccess
  undefined,
  // Assuming dataKey is not used
  (formula: Formula) => {
    processFormula(formula);
    return formula;
  } // Passing the preprocess function
  );
  const updateBulkFormula = useUpdateMutation(QUERYKEYS.ALLFORMULAS, ApiUtils.updateBulkFormula);
  const createBulkEmployee = useCreateMutation(QUERYKEYS.ALLEMPLOYEES, ApiUtils.createBulkEmployee);
  const updateBulkEmployee = useUpdateMutation(QUERYKEYS.ALLEMPLOYEES, ApiUtils.updateBulkEmployee);
  const deleteBulkEmployee = useBulkDeleteMutation(QUERYKEYS.ALLEMPLOYEES, ({
    ids
  }) => ApiUtils.deleteBulkEmployee({
    ids
  }));
  const createDashboard = (onSuccess: any) =>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useCreateMutation(QUERYKEYS.DASHBOARDSBYCOMPANY, ApiUtils.createDahboard, onSuccess);
  const updateUserDates = useUpdateMutation(QUERYKEYS.DATES_UPDATE, ApiUtils.updateUserDates);
  const removeIntegration = useDeleteMutation(QUERYKEYS.INTEGRATIONS, ApiUtils.removeIntegration);
  const createIntegration = useCreateMutation(QUERYKEYS.INTEGRATIONS, ApiUtils.createIntegration);
  const syncIntegration = useDeleteMutation(QUERYKEYS.INTEGRATIONS, ApiUtils.syncIntegration);
  const updateIntegration = useUpdateMutation(QUERYKEYS.INTEGRATIONS, ApiUtils.updateIntegration);
  const validateQuestionsAndAnswers = useCreateMutation(QUERYKEYS.VALIDATE_QUESTIONS_AND_ANSWERS, ApiUtils.validateQuestionsAndAnswers);
  const aiExpandText = useCreateMutation(QUERYKEYS.AI_EXPAND_TEXT, ApiUtils.aiExpandText);
  const templateAssigner = useCreateMutation(QUERYKEYS.TEMPLATE_ASSIGNER, ApiUtils.templateAssigner);
  const finishOnboarding = useUpdateMutation(QUERYKEYS.FINISH_ONBOARDING, ApiUtils.finishOnboarding);
  const scrapeCompany = useCreateMutation(QUERYKEYS.SCRAPE_COMPANY, ApiUtils.scrapeCompany);
  const extractFieldsCompanyDescriptions = useCreateMutation(QUERYKEYS.EXTRACT_FIELDS_COMPANY_DESCRIPTIONS, ApiUtils.extractFieldsCompanyDescriptions);
  const extractGoalsCompanyDescriptions = useCreateMutation(QUERYKEYS.EXTRACT_GOALS_COMPANY_DESCRIPTIONS, ApiUtils.extractGoalsCompanyDescriptions);
  const predictCompanyProducts = useCreateMutation(QUERYKEYS.PREDICT_COMPANY_PRODUCTS, ApiUtils.predictCompanyProducts);
  const fastOnboarding = useCreateMutation(QUERYKEYS.FAST_ONBOARDING, ApiUtils.fastOnboarding);
  const sendErrorReport = useCreateMutation(QUERYKEYS.SEND_ERROR_REPORT, ApiUtils.sendErrorReport);
  const getAiVoiceResponse = useCreateMutation(QUERYKEYS.AI_VOICE_RESPONSE, ApiUtils.getAiVoiceResponse);
  const getPromptSummary = useCreateMutation(QUERYKEYS.GET_PROMPT_SUMMARY, ApiUtils.getPromptSummary);
  const paymentIntent = useCreateMutation(QUERYKEYS.PAYMENT_INTENT, ApiUtils.paymentIntent);
  const createSubscription = useCreateMutation(QUERYKEYS.SUBSCRIPTION_STATUS, ApiUtils.createSubscription);
  const createBillingPortalLink = useCreateMutation(QUERYKEYS.BILLING_PORTAL_LINK, ApiUtils.getBillingPortalLink);
  const getGeneralChatResponse = useCreateMutation(QUERYKEYS.GET_GENERAL_CHAT_RESPONSE, ApiUtils.getGeneralChatResponse);
  const endTrialSubscription = useCreateMutation(QUERYKEYS.END_TRIAL_SUBSCRIPTION, ApiUtils.endTrialSubscription);
  const uploadFinancialPlan = useCreateMutation(QUERYKEYS.UPLOAD_FINANCIAL_PLAN, ApiUtils.uploadFinancialPlan);
  return {
    uploadFinancialPlan,
    endTrialSubscription,
    getGeneralChatResponse,
    createBillingPortalLink,
    createSubscription,
    paymentIntent,
    getPromptSummary,
    getAiVoiceResponse,
    sendErrorReport,
    updateBulkModels,
    fastOnboarding,
    scrapeCompany,
    predictCompanyProducts,
    extractFieldsCompanyDescriptions,
    extractGoalsCompanyDescriptions,
    finishOnboarding,
    templateAssigner,
    aiExpandText,
    validateQuestionsAndAnswers,
    aiExplainer,
    createTemplate,
    applyTemplate,
    removeTemplate,
    createGraph,
    updateGraph,
    deleteGraph,
    createBulkGraph,
    updateBulkGraph,
    createBranch,
    deleteBranch,
    updateBranch,
    createModel,
    deleteModel,
    updateModels,
    createHiringPlan,
    updateHiringPlan,
    deleteHiringPlan,
    updateEmployee,
    deleteEmployee,
    createEmployee,
    createBulkEmployee,
    updateBulkEmployee,
    deleteBulkEmployee,
    createCompany,
    updateCompany,
    deleteCompany,
    deleteEmployeeColumn,
    createEmployeeColumn,
    updateEmployeeColumn,
    createBranchHiringSettings,
    updateBranchHiringSettings,
    createFormula,
    deleteFormula,
    updateFormula,
    updateBulkFormula,
    updateReport,
    createOutput,
    updateOutput,
    deleteOutput,
    createDashboard,
    updateReportItem,
    updateUserDates,
    createReportItem,
    deleteReportItem,
    createReport,
    deleteReport,
    removeIntegration,
    syncIntegration,
    updateIntegration,
    createIntegration
  };
};
export default useCreateMutations;