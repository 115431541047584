import type { ModelGroupType } from '@/utils/types/types';

export const variables = {
  lucid_user: 'lucid_user',
  lucid_user_companies: 'lucid_user_companies',
  lucid_user_active_company: 'lucid_user_active_company',
  lucid_user_branches: 'lucid_user_branches',
  lucid_user_active_branch: 'lucid_user_active_branch',
  financial: 'Financial',
  hiring: 'Hiring',
  models: 'Models',
  ADD_VARIABLE: 'add_variable',
  departmentCell: 'hiring_department_cell',
  rollCell: 'hiring_role_cell',
  actual: 'Actual',
  forecast: 'Forecast',
  roles: ['Manager', 'Accountant', 'Staff'],
  employeeMenu: ['addEmployee', 'addMultipleEmployee', 'addDepartment'],
  monthsObj: {
    0: [
      { month: 'Jan', value: 1 },
      { month: 'Feb', value: 2 },
      { month: 'Mar', value: 3 },
      { month: 'Apr', value: 4 },
    ],
    1: [
      { month: 'May', value: 5 },
      { month: 'Jun', value: 6 },
      { month: 'Jul', value: 7 },
      { month: 'Aug', value: 8 },
    ],
    2: [
      { month: 'Sep', value: 9 },
      { month: 'Oct', value: 10 },
      { month: 'Nov', value: 11 },
      { month: 'Dec', value: 12 },
    ],
  },
  sampleArray: [{ id: 1 }, { id: 2 }, { id: 3 }],
};

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'Decemeber',
];

export const bonusTypes = ['Dollar', 'Percentage'];

export const payRollAccounts = [
  'Advertisement',
  'Bank Fees',
  'Education',
  'Entertainment',
];
export const boxStyling = {
  background: 'white',
  boxShadow:
    'hsl(206deg 22% 7% / 35%) 0 10px 38px -10px, hsl(206deg 22% 7% / 20%) 0 10px 20px -15px',
};

export const fakeCompanyMenu = [
  { id: '1', companyName: 'ABX Inc.' },
  { id: '2', companyName: 'ABC Inc.' },
  { id: '3', companyName: 'Artimis Co.' },
  { id: '4', companyName: 'Urban Empire Co.' },
];

export const modelGroupType: Record<string, ModelGroupType> = {
  CALCULATIONS: 'calculations',
  OUTPUTS: 'outputs',
  INPUTS: 'inputs',
};

export const links = {
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
};

export const templateAll = [
  {
    id: 1,
    tipText: 'Beginner',
    title: 'B2C SaaS Template',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Add Template to Project',
    list: [
      {
        text: '1 Dashboard',
      },
      {
        text: '4 Models',
      },
      {
        text: '10 Charts',
      },
      {
        text: '44 Variables',
      },
    ],
  },
  {
    id: 2,
    tipText: 'Intermediate',
    title: 'For VC Sharing',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Add Template to Project',
    list: [
      {
        text: '14 Dashboard',
      },
      {
        text: '94 Models',
      },
      {
        text: '20 Charts',
      },
      {
        text: '84 Variables',
      },
    ],
  },
  {
    id: 3,
    tipText: 'Beginner',
    title: 'Fundraising Template',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Add Template to Project',
    list: [
      {
        text: '13 Dashboard',
      },
      {
        text: '41 Models',
      },
      {
        text: '40 Charts',
      },
      {
        text: '44 Variables',
      },
    ],
  },
  {
    id: 3,
    tipText: 'Beginner',
    title: 'Google Ads Funnel Template',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Add Template to Project',
    list: [
      {
        text: '13 Dashboard',
      },
      {
        text: '41 Models',
      },
      {
        text: '70 Charts',
      },
      {
        text: '84 Variables',
      },
    ],
  },
  {
    id: 5,
    tipText: 'Advanced',
    title: 'Monthly SaaS Model',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Add Template to Project',
    list: [
      {
        text: '12 Dashboard',
      },
      {
        text: '42 Models',
      },
      {
        text: '14 Charts',
      },
      {
        text: '44 Variables',
      },
    ],
  },
];
export const templateInUse = [
  {
    id: 1,
    tipText: 'Beginner',
    title: 'Monthly SaaS Model',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Remove Template',
    list: [
      {
        text: '3 Dashboard',
      },
      {
        text: '41 Models',
      },
      {
        text: '11 Charts',
      },
      {
        text: '54 Variables',
      },
    ],
  },
  {
    id: 2,
    tipText: 'Intermediate',
    title: 'For VC Sharing',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Remove Template',
    list: [
      {
        text: '10 Dashboard',
      },
      {
        text: '49 Models',
      },
      {
        text: '6 Charts',
      },
      {
        text: '94 Variables',
      },
    ],
  },
  {
    id: 3,
    tipText: 'Beginner',
    title: 'Fundraising Template',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Remove Template',
    list: [
      {
        text: '3 Dashboard',
      },
      {
        text: '6 Models',
      },
      {
        text: '30 Charts',
      },
      {
        text: '40 Variables',
      },
    ],
  },
  {
    id: 3,
    tipText: 'Beginner',
    title: 'Google Ads Funnel Template',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Remove Template',
    list: [
      {
        text: '11 Dashboard',
      },
      {
        text: '41 Models',
      },
      {
        text: '70 Charts',
      },
      {
        text: '94 Variables',
      },
    ],
  },
  {
    id: 4,
    tipText: 'Advanced',
    title: 'Monthly SaaS Model',
    subTitle:
      '1 dashboard and 4 simple models to create common B2C SaaS monitoring',
    text: 'SaaS',
    buttonText: 'Remove Template',
    list: [
      {
        text: '11 Dashboard',
      },
      {
        text: '47 Models',
      },
      {
        text: '50 Charts',
      },
      {
        text: '44 Variables',
      },
    ],
  },
];

export const timePeriodData = [
  {
    id: 1,
    label: 'Monthly',
    type: 'is_monthly',
  },
  {
    id: 2,
    label: 'Quarterly',
    type: 'is_quarterly',
  },
  {
    id: 3,
    label: 'Annual',
    type: 'is_annualy',
  },
];

export const chartData = [
  {
    id: 1,
    label: 'Line Chart',
    icon: '/assets/icons/lineChart.svg',
    type: 'line',
    configType: 'Line',
  },
  {
    id: 2,
    label: 'Bar Chart',
    icon: '/assets/icons/barChart.svg',
    type: 'bar',
    configType: 'Bar',
  },
  {
    id: 3,
    label: 'Bar Combo Chart',
    icon: '/assets/icons/barComboChart.svg',
    type: 'bar',
    configType: 'BarCombo',
  },
  {
    id: 4,
    label: 'Grouped Stacked Bar Chart',
    icon: '/assets/icons/stackBarChart.svg',
    type: 'bar',
    configType: 'GroupedStackedCombo',
  },
  {
    id: 5,
    label: 'Stacked Bar Chart',
    icon: '/assets/icons/stackBarChart.svg',
    type: 'bar',
    configType: 'Stacked',
  },
  {
    id: 6,
    label: 'Stacked Bar Combo Chart',
    icon: '/assets/icons/stackBarComboChart.svg',
    type: 'bar',
    configType: 'StackedCombo',
  },
  {
    id: 7,
    label: 'Donut Chart',
    icon: '/assets/icons/donutChart.svg',
    type: 'donut',
    configType: 'donut',
  },
  // ! This is not used in the project, temporarily commented out
  // {
  //   id: 7,
  //   label: 'Scorecard',
  //   icon: '/assets/icons/scorCardChart.svg',
  //   type: 'scorecard',
  //   configType: 'Scorecard',
  // },
  // {
  //   id: 8,
  //   label: 'Ranked List',
  //   icon: '/assets/icons/rankListChart.svg',
  //   type: 'rankedList',
  //   configType: 'rankedList',
  // },
];

export const graphOperation = {
  edit: 1,
  clone: 2,
  regenerate: 3,
  delete: 4,
};
