import Image from 'next/image';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import csvIcon from '@/public/assets/images/csv.png';
import excelIcon from '@/public/assets/images/excel.png';
import googleSheetIcon from '@/public/assets/images/sheets.png';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import styles from './fileUploadWithUrl.module.scss';

// Define accepted MIME types and file extensions for spreadsheets
const acceptedTypes = {
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.oasis.opendocument.spreadsheet': ['.ods']
};

/** File data structure for uploaded files */
interface FileData {
  filename: string;
  file_content: string;
}

/** Props for FileUploadWithUrl component */
interface FileUploadWithUrlProps {
  onSubmit: (payload: {
    filename: string;
    file_content: string;
    google_drive_link: string;
  }) => void;
  isLoading: boolean;
  infoTextForButton?: string;
}

/** Validates if a URL is a valid Google Spreadsheet URL */
const isValidGoogleSpreadsheetUrl = (url: string): boolean => {
  if (!url) return true; // Empty URL is considered valid to not show error
  const newFormat = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/[A-Za-z0-9-_]+(\/.*)?$/;
  const oldFormat = /^https:\/\/docs\.google\.com\/spreadsheet\/ccc\?key=[A-Za-z0-9-_]+.*$/;
  const googleDocsFormat = /^https:\/\/docs\.google\.com\/document\/d\/[A-Za-z0-9-_]+(\/.*)?$/;
  return newFormat.test(url) || oldFormat.test(url) || googleDocsFormat.test(url);
};

/** Renders the dropzone content and upload instructions */
const DropzoneContent: React.FC<{
  isDragActive: boolean;
  fileData: FileData | null;
  translate: (key: string) => string;
}> = ({
  isDragActive,
  fileData,
  translate
}) => <div className={styles.dropzoneContent} data-sentry-component="DropzoneContent" data-sentry-source-file="FileUploadWithUrl.tsx">
    <div className={styles.uploadIcon}>
      <Image src={googleSheetIcon} alt="google sheet icon" width={40} height={40} data-sentry-element="Image" data-sentry-source-file="FileUploadWithUrl.tsx" />
      <Image src={excelIcon} alt="excel icon" width={40} height={40} data-sentry-element="Image" data-sentry-source-file="FileUploadWithUrl.tsx" />
      <Image src={csvIcon} alt="csv icon" width={40} height={40} data-sentry-element="Image" data-sentry-source-file="FileUploadWithUrl.tsx" />
    </div>
    <p className={styles.dropzoneText}>
      {isDragActive ? translate('drop_file_here') : translate('drag_and_drop_file_here')}
    </p>
    {fileData && <p className={styles.fileName}>
        {translate('selected_file')}: {fileData.filename}
      </p>}
  </div>;

/** Renders the Google Spreadsheet URL input field */
const UrlInput: React.FC<{
  sheetUrl: string;
  isValidUrl: boolean;
  setSheetUrl: (url: string) => void;
  translate: (key: string) => string;
}> = ({
  sheetUrl,
  isValidUrl,
  setSheetUrl,
  translate
}) => <div className={styles.urlInputContainer} data-sentry-component="UrlInput" data-sentry-source-file="FileUploadWithUrl.tsx">
    <label htmlFor="sheetUrl" className={styles.urlLabel}>
      {translate('or_enter_a_public_google_spreadsheet_url')}:
      <input id="sheetUrl" type="text" value={sheetUrl} onChange={e => setSheetUrl(e.target.value)} placeholder="https://docs.google.com/spreadsheets/..." className={`${styles.urlInput} ${sheetUrl && !isValidUrl ? styles.errorInput : ''}`} />
      {sheetUrl && !isValidUrl && <p className={styles.errorMessage}>
          {translate('please_enter_a_valid_google_spreadsheet_url')}
        </p>}
    </label>
  </div>;

/** Renders the submit button with loading state */
const SubmitButton: React.FC<{
  isLoading: boolean;
  disabled: boolean;
  onClick: () => void;
  translate: (key: string) => string;
}> = ({
  isLoading,
  disabled,
  onClick,
  translate
}) => <button onClick={onClick} className={styles.submitButton} type="button" disabled={disabled} data-sentry-component="SubmitButton" data-sentry-source-file="FileUploadWithUrl.tsx">
    <Oval visible={isLoading} height="20" width="20" strokeWidth={2} color={colors.lightGrey} secondaryColor={colors.white} ariaLabel="oval-loading" wrapperStyle={{}} wrapperClass="" data-sentry-element="Oval" data-sentry-source-file="FileUploadWithUrl.tsx" />
    {isLoading ? translate('submitting') : translate('submit')}
  </button>;

/**
 * Component for uploading files via drag-and-drop or Google Spreadsheet URL
 * Supports CSV, XLS, XLSX, and ODS file formats
 */
const FileUploadWithUrl: React.FC<FileUploadWithUrlProps> = ({
  onSubmit,
  isLoading,
  infoTextForButton
}) => {
  const [fileData, setFileData] = useState<FileData | null>(null);
  const [sheetUrl, setSheetUrl] = useState<string>('');
  const isValidUrl = isValidGoogleSpreadsheetUrl(sheetUrl);
  const {
    t: translate
  } = useTranslation(['common']);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (!file) return;
      const {
        result
      } = reader;
      if (typeof result !== 'string') return;
      const base64String = result.split(',')[1];
      if (!base64String) return;
      setFileData({
        filename: file.name,
        file_content: base64String
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    accept: acceptedTypes
  });
  const handleSubmit = () => {
    const payload = {
      filename: fileData?.filename || '',
      file_content: fileData?.file_content || '',
      google_drive_link: sheetUrl
    };
    onSubmit(payload);
  };
  const isDisabledSubmitButton = !fileData && !sheetUrl || sheetUrl && !isValidUrl || isLoading;
  return <div className={styles.uploadContainer} data-sentry-component="FileUploadWithUrl" data-sentry-source-file="FileUploadWithUrl.tsx">
      <div {...getRootProps()} className={`${styles.dropzone} ${isDragActive ? styles.dragActive : ''}`}>
        <input {...getInputProps()} disabled={isLoading} />
        <DropzoneContent isDragActive={isDragActive} fileData={fileData} translate={translate} data-sentry-element="DropzoneContent" data-sentry-source-file="FileUploadWithUrl.tsx" />
      </div>

      <UrlInput sheetUrl={sheetUrl} isValidUrl={isValidUrl} setSheetUrl={setSheetUrl} translate={translate} data-sentry-element="UrlInput" data-sentry-source-file="FileUploadWithUrl.tsx" />

      <div className={styles.submitButtonContainer}>
        <SubmitButton isLoading={isLoading} disabled={isDisabledSubmitButton} onClick={handleSubmit} translate={translate} data-sentry-element="SubmitButton" data-sentry-source-file="FileUploadWithUrl.tsx" />
        <span>{infoTextForButton}</span>
      </div>
    </div>;
};
export default FileUploadWithUrl;