import clsx from 'clsx';
import React from 'react';
import { Item } from 'react-stately';
import { backgroundColors } from '@/styles/variables/color';
import { menuWrapperStaticProps } from '@/styles/variables/constantProps';
import { MenuButton } from '../MenuComponent';
import styles from './MenuWrapper.module.scss';
interface MenuProps {
  menu?: Array<any>;
  Icon: any;
  top?: string;
  radius: string;
  width: string;
  align?: string;
  leftPlacement: string;
  onAction: (e: any) => void;
  listItemStyles: string;
  children?: React.ReactNode;
}
const MenuWrpper: React.FC<MenuProps> = ({
  menu,
  radius,
  width,
  Icon,
  top,
  leftPlacement,
  onAction,
  align,
  listItemStyles,
  children
}) => {
  return <MenuButton label="" Icon={Icon} radius={radius} leftPlacement={leftPlacement} width={width} top={top || `${menuWrapperStaticProps.top}`} align={align || 'p-4'} listItemStyles={listItemStyles} onAction={onAction} data-sentry-element="MenuButton" data-sentry-component="MenuWrpper" data-sentry-source-file="MenuWrapper.tsx">
      {!children ? menu?.map((item: any) => {
      return <Item key={item.id} data-custom-prop={item?.isDisabled}>
                <div className={`hover: flex items-center rounded p-2 ${backgroundColors.darkGreyBackground}/50`}>
                  {item.icon && <div className={clsx({
            [styles.disabledIconFilter!]: item.isDisabled
          })}>
                      {item.icon}
                    </div>}
                  <p className={`${listItemStyles} ml-2`}>
                    {item.itemName ? item.itemName : item}
                  </p>
                </div>
              </Item>;
    }) : children}
    </MenuButton>;
};
export default MenuWrpper;