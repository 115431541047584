import React from 'react';
import { mainColors } from '../../styles/variables/color';
import { links } from '../../styles/variables/constant';
export const NewChartSvg: React.FC = () => {
  return <svg width={207} height={207} viewBox="0 0 207 207" fill="none" xmlns={links.xmlns} xmlnsXlink={links.xmlnsXlink} className="h-48 w-48" data-sentry-element="svg" data-sentry-component="NewChartSvg" data-sentry-source-file="NewChartSvg.tsx">
      <rect x="0.5" y="0.5" width={206} height={206} rx={103} fill="#F6F8FA" data-sentry-element="rect" data-sentry-source-file="NewChartSvg.tsx" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x={1} y={1} width={205} height={205} data-sentry-element="mask" data-sentry-source-file="NewChartSvg.tsx">
        <circle cx="103.5" cy="103.5" r="102.5" fill="#F6F8FA" data-sentry-element="circle" data-sentry-source-file="NewChartSvg.tsx" />
      </mask>
      <g mask="url(#mask0)" data-sentry-element="g" data-sentry-source-file="NewChartSvg.tsx">
        <g filter="url(#filter0_d)" data-sentry-element="g" data-sentry-source-file="NewChartSvg.tsx">
          <path d="M49.9644 121.316C51.3097 120.349 51.6162 118.475 50.6489 117.129C49.6817 115.784 47.8073 115.477 46.4619 116.445L40.7783 120.531C34.0523 125.367 32.5197 134.74 37.3555 141.466C42.1913 148.192 51.5647 149.725 58.2907 144.889L63.9743 140.803C65.3197 139.836 65.6261 137.961 64.6589 136.616C63.6916 135.27 61.8172 134.964 60.4718 135.931L62.0957 134.764C63.4411 133.796 63.7475 131.922 62.7802 130.577C61.813 129.231 59.9386 128.925 58.5932 129.892L60.2171 128.725C61.5624 127.757 61.8689 125.883 60.9016 124.538C59.9344 123.192 58.06 122.886 56.7146 123.853L66.4578 116.848C67.8032 115.881 68.1097 114.006 67.1424 112.661C66.1751 111.316 64.3007 111.009 62.9553 111.976L45.0928 124.819L49.9644 121.316Z" fill="white" data-sentry-element="path" data-sentry-source-file="NewChartSvg.tsx" />
          <path d="M49.9644 121.316C51.3097 120.349 51.6162 118.475 50.6489 117.129C49.6817 115.784 47.8073 115.477 46.4619 116.445L40.7783 120.531C34.0523 125.367 32.5197 134.74 37.3555 141.466C42.1913 148.192 51.5647 149.725 58.2907 144.889L63.9743 140.803C65.3197 139.836 65.6261 137.961 64.6589 136.616C63.6916 135.27 61.8172 134.964 60.4718 135.931L62.0957 134.764C63.4411 133.796 63.7475 131.922 62.7802 130.577C61.813 129.231 59.9386 128.925 58.5932 129.892L60.2171 128.725C61.5624 127.757 61.8689 125.883 60.9016 124.538C59.9344 123.192 58.06 122.886 56.7146 123.853L66.4578 116.848C67.8032 115.881 68.1097 114.006 67.1424 112.661C66.1751 111.316 64.3007 111.009 62.9553 111.976L45.0928 124.819L49.9644 121.316Z" stroke="#D1D8E0" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="NewChartSvg.tsx" />
        </g>
        <rect x={79} y={6} width={156} height={304} fill="url(#pattern0)" data-sentry-element="rect" data-sentry-source-file="NewChartSvg.tsx" />
      </g>
      <rect x="0.5" y="0.5" width={206} height={206} rx={103} stroke="#D1D8E0" data-sentry-element="rect" data-sentry-source-file="NewChartSvg.tsx" />
      <defs data-sentry-element="defs" data-sentry-source-file="NewChartSvg.tsx">
        <filter id="filter0_d" x="23.2041" y="101.958" width="61.5931" height="59.0831" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="NewChartSvg.tsx">
          <feFlood floodOpacity={0} result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="NewChartSvg.tsx" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" data-sentry-element="feColorMatrix" data-sentry-source-file="NewChartSvg.tsx" />
          <feOffset dy={2} data-sentry-element="feOffset" data-sentry-source-file="NewChartSvg.tsx" />
          <feGaussianBlur stdDeviation={2} data-sentry-element="feGaussianBlur" data-sentry-source-file="NewChartSvg.tsx" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0784314 0 0 0 0 0.12549 0 0 0 0 0.14902 0 0 0 0.1 0" data-sentry-element="feColorMatrix" data-sentry-source-file="NewChartSvg.tsx" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" data-sentry-element="feBlend" data-sentry-source-file="NewChartSvg.tsx" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" data-sentry-element="feBlend" data-sentry-source-file="NewChartSvg.tsx" />
        </filter>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width={1} height={1} data-sentry-element="pattern" data-sentry-source-file="NewChartSvg.tsx">
          <use xlinkHref="#image0" transform="scale(0.00320513 0.00164474)" data-sentry-element="use" data-sentry-source-file="NewChartSvg.tsx" />
        </pattern>
        <image id="image0" width={312} height={608} xlinkHref="/assets/images/chart_settings.png" data-sentry-element="image" data-sentry-source-file="NewChartSvg.tsx" />
      </defs>
    </svg>;
};
type SwitchSvgProps = {
  isSelected: boolean;
  bgColor: string | undefined;
  isFocusVisible: boolean;
  size?: 'small' | 'medium' | 'large';
};
export const SwitchSvg = ({
  isSelected,
  bgColor,
  isFocusVisible,
  size = 'medium'
}: SwitchSvgProps) => {
  const dimensions = {
    small: {
      width: 32,
      height: 20,
      circleR: 4
    },
    medium: {
      width: 40,
      height: 24,
      circleR: 5
    },
    large: {
      width: 48,
      height: 28,
      circleR: 6
    }
  };
  const {
    width,
    height,
    circleR
  } = dimensions[size];
  const rectHeight = height - 8;
  const rectWidth = width - 8;
  const circleX = isSelected ? width - 12 : 12;
  const circleY = height / 2;
  return <svg width={width} height={height} aria-hidden="true" style={{
    marginRight: 4
  }} data-sentry-element="svg" data-sentry-component="SwitchSvg" data-sentry-source-file="NewChartSvg.tsx">
      <rect x={4} y={(height - rectHeight) / 2} width={rectWidth} height={rectHeight} rx={rectHeight / 2} fill={isSelected ? `${bgColor || 'orange'}` : `${mainColors.componentBgGray}`} data-sentry-element="rect" data-sentry-source-file="NewChartSvg.tsx" />
      <circle cx={circleX} cy={circleY} r={circleR} fill="white" data-sentry-element="circle" data-sentry-source-file="NewChartSvg.tsx" />
      {isFocusVisible && <rect x={1} y={1} width={width - 2} height={height - 2} rx={(height - 2) / 2} fill="none" stroke={bgColor || 'orange'} strokeWidth={2} />}
    </svg>;
};