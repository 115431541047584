import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Check, CircleEllipsis } from 'lucide-react';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { TbLogout2 } from 'react-icons/tb';
import { Oval } from 'react-loader-spinner';
import Slider from 'react-slick';
import { useShallow } from 'zustand/react/shallow';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import variables from '@/styles/scss/abstracts/_variables.module.scss';
import { vercelEnv } from '@/utils/AppConfig';
import useCreateMutations from '@/utils/hooks/mutations/useCreateAllMutations';
import GenericStagePopup from '../GenericStagePopup/GenericStagePopup';
import ShineBorder from '../ShineBorder/ShineBorder';
import styles from './StripeCheckout/stripeCheckout.module.scss';

// get stripe key based on the vercel env
const stripeKey = vercelEnv === 'production' ? process.env.NEXT_PUBLIC_STRIP_LIVE_KEY : process.env.NEXT_PUBLIC_STRIP_DEV_KEY;

/**
 * Initializes the Stripe library with the provided public API key.
 * This promise-based initialization allows the Stripe library to be used
 * throughout the application.
 *
 * @param {string} stripeKey - The Stripe public API key used to initialize the Stripe library.
 *   The Stripe public API key used to initialize the Stripe library.
 * @returns {Promise<Stripe>} A promise that resolves to the initialized Stripe object.
 */
const stripePromise = loadStripe((stripeKey as string));

// PaymentModalContext is a context that provides the error message and setErrorMessage function to the StripeCheckout component
export const PaymentModalContext = createContext<{
  errorMessage: string | null;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
}>({
  errorMessage: null,
  setErrorMessage: () => null
});

/**
 * Renders the Stripe checkout content, including a loading spinner or the Stripe Elements component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.clientSecret - The Stripe client secret.
 * @param {React.ReactNode} props.children - The child components to render within the Stripe Elements.
 * @param {boolean} props.isLoaded - Indicates whether the Stripe client secret has been loaded.
 * @returns {React.ReactElement} The Stripe checkout content.
 */
const StripeCheckoutContent = ({
  clientSecret,
  children,
  isLoaded
}: {
  clientSecret: string;
  children: React.ReactNode;
  isLoaded: boolean;
}) => {
  const {
    t: translate
  } = useTranslation(['common']);
  return <div className={styles.stripePopupContainer} data-sentry-component="StripeCheckoutContent" data-sentry-source-file="StripeProvider.tsx">
      <div className={styles.stripePopupTextContainer}>
        <h1>{translate('your_plan_is_ready')}</h1>
      </div>
      {isLoaded ? <Oval visible height="30" width="30" color={variables.midBlue} secondaryColor={variables.lavendeGray} strokeWidth={4} /> : <Elements stripe={stripePromise} options={{
      clientSecret
    }}>
          {children}
        </Elements>}
    </div>;
};
interface PlanFeature {
  text: string;
  iconSize?: number;
}
const PLAN_FEATURES: PlanFeature[] = [{
  text: 'AI Financial Copilot – Your Intelligent Strategic Assistant'
}, {
  text: 'Unlimited Custom Financial Reports – Tailored Analytics for In-Depth Insights'
}, {
  text: 'AI Financials Generation – Automated, Intelligent Financial Analysis'
}, {
  text: 'Unlimited Scenarios – Comprehensive Stress-Testing and Forecasting'
}, {
  text: 'QuickBooks Integration – Seamless Accounting Data Synchronization'
}];
const ChoosePlan = ({
  onNextStep
}: {
  onNextStep: () => void;
}) => {
  const {
    t: translate
  } = useTranslation(['common']);

  // Open the pricing page in a new tab
  const handleViewTeamPlans = () => {
    window.open('https://lucid.now/pricing/', '_blank', 'noopener,noreferrer');
  };
  return <div className={styles.choosePlanContainer} data-sentry-component="ChoosePlan" data-sentry-source-file="StripeProvider.tsx">
      <header className={styles.choosePlanTextContainer}>
        <h1>{translate('choose_plan_header')}</h1>
        <p>{translate('choose_plan_description')}</p>
      </header>

      <section className={styles.choosePlanFeaturesContainer}>
        <h1 className={styles.featuresHeader}>
          {translate('choose_plan_card_title')}
        </h1>
        <ul>
          {PLAN_FEATURES.map(({
          text,
          iconSize = 18
        }) => <li key={text}>
              <FaCheckCircle size={iconSize} />
              <p>{text}</p>
            </li>)}
        </ul>

        <p className={styles.choosePlanDescription}>
          {translate('lucid_membership_costs_message')}
        </p>

        <footer className={styles.choosePlanButtonContainer}>
          <button type="button" onClick={handleViewTeamPlans}>
            {translate('view_team_plans')}
          </button>
          <ShineBorder borderRadius={4} borderWidth={2.5} color={[(variables.midBlue as string), (variables.livelyPink as string), (variables.paleOrange as string)]} className="w-full overflow-hidden" data-sentry-element="ShineBorder" data-sentry-source-file="StripeProvider.tsx">
            <button type="button" onClick={onNextStep}>
              {translate('choose_plan_header')}
            </button>
          </ShineBorder>
        </footer>
      </section>
    </div>;
};
interface TestimonialData {
  image: string;
  name: string;
  position: string;
  testimonial: string;
}
const testimonials: TestimonialData[] = [{
  image: 'https://unicorn-images.b-cdn.net/1439cd62-d54c-4068-bcab-e4595b9b5340?optimizer=gif&width=50&height=50',
  name: 'Refael Shamir',
  position: 'Founder and CEO @Letos',
  testimonial: 'We found a powerful yet simple solution for our financial planning needs, which has been a great addition to our business.'
}, {
  image: 'https://unicorn-images.b-cdn.net/79418358-0553-4b80-a7c8-ae0582f4d259?optimizer=gif&width=50&height=50',
  name: 'Luka Mutinda',
  position: 'Founder and CEO @Dukapaq',
  testimonial: 'As our company grows, budgeting and cash flow management have become crucial. The features provided are essential and have greatly streamlined these processes for us.'
}, {
  image: 'https://unicorn-images.b-cdn.net/88031494-7ea8-4b20-99d5-68d87310ca9d?optimizer=gif&width=50&height=50',
  name: 'Erez Lugashi',
  position: 'Founder and CEO @Abilisense',
  testimonial: "Keeping track of my finances used to be overwhelming, but now it's much simpler. The platform is well-organized and easy to navigate"
}, {
  image: 'https://unicorn-images.b-cdn.net/a3f7336b-4753-47b0-b53f-e274719b4af0?optimizer=gif&width=150&height=150',
  name: 'Aviv Farhi',
  position: 'Founder and CEO @Showcase',
  testimonial: "Lucid has made it incredibly easy to track spending, plan ahead, and handle our growth. It’s straightforward and effective. Managing my finances has become much easier with this platform. It's user-friendly and efficient, saving me a lot of time and effort."
}];
const TestimonialCard = ({
  data
}: {
  data: TestimonialData;
}) => <div className={styles.testimonialCard} data-sentry-component="TestimonialCard" data-sentry-source-file="StripeProvider.tsx">
    <div className={styles.testimonialCardHeader}>
      <div className={styles.testimonialCardHeaderLeft}>
        <img src={data.image} alt={`${data.name}'s testimonial`} width={100} height={100} />
      </div>
      <div className={styles.testimonialCardHeaderRight}>
        <h1>{data.name}</h1>
        <p>{data.position}</p>
      </div>
    </div>
    <div className={styles.testimonialCardBody}>
      <p>{data.testimonial}</p>
    </div>
  </div>;
const SidebarBottomCarouselContent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000
  };
  return <div className={styles.carouselContainer} data-sentry-component="SidebarBottomCarouselContent" data-sentry-source-file="StripeProvider.tsx">
      <Slider {...settings} data-sentry-element="Slider" data-sentry-source-file="StripeProvider.tsx">
        {testimonials.map(testimonial => <div key={testimonial.name} className={styles.slide}>
            <TestimonialCard data={testimonial} />
          </div>)}
      </Slider>
    </div>;
};

/**
 * Renders a payment modal with Stripe checkout functionality.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components to render within the Stripe Elements.
 * @returns {React.ReactElement} The payment modal component.
 */
export default function PaymentModal({
  children
}: {
  children: React.ReactNode;
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    paymentIntent
  } = useCreateMutations();
  const {
    t: translate
  } = useTranslation(['common']);
  const [step, setStep] = useState(translate('choose_plan_step'));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    logoutApp,
    clientSecret,
    setClientSecret
  } = useZustandStore(useShallow(state => ({
    logoutApp: state.logoutApp,
    clientSecret: state.stripeClientSecret,
    setClientSecret: state.setStripeClientSecret
  })));
  const {
    showPaymentModal,
    setShowPaymentModal
  } = useUtilsStore(useShallow(state => ({
    showPaymentModal: state.showPaymentModal,
    setShowPaymentModal: state.setShowPaymentModal
  })));

  // Steps for the popup
  const steps = [{
    label: translate('choose_plan_step'),
    icon: <CircleEllipsis size={16} />,
    checkIcon: <Check size={16} />
  }, {
    label: translate('free_trial_step'),
    icon: <CircleEllipsis size={16} />,
    checkIcon: <Check size={16} />
  }];
  useEffect(() => {
    setIsLoaded(true);
    // paymentIntent is a mutation function that creates a payment intent on the server side and returns the client secret
    paymentIntent.mutate({}, {
      onSuccess: data => {
        setClientSecret(data.paymentIntent.client_secret);
        setIsLoaded(false);
      },
      onError: () => {
        setIsLoaded(false);
      }
    });
  }, []);

  // Styles for the popup
  const popupStyles = {
    borderRadius: '20px',
    padding: '24px',
    gap: '16px',
    width: '1100px',
    height: 'auto',
    minHeight: '608px',
    backgroundColors: variables.white
  };

  // Reset the step and close the popup
  const resetStep = () => {
    setShowPaymentModal(false);
  };
  const onNextStep = () => {
    setStep('Free Trial');
  };

  // Stage component for the popup
  const stageComponent = useCallback(() => clientSecret && <div id="payment-modal">
          {step === 'Choose Plan' ? <ChoosePlan onNextStep={onNextStep} /> : <StripeCheckoutContent clientSecret={clientSecret} isLoaded={isLoaded}>
              {children}
            </StripeCheckoutContent>}
        </div>, [clientSecret, isLoaded, step]);

  // Logout button
  const logoutButton = <div className={styles.logoutButton} onClick={() => {
    mixpanelTrackEventWrapper(MIXPANEL_EVENTS.USER_LOGGED_OUT_FROM_PAYMENT_MODAL);
    logoutApp();
    setShowPaymentModal(false);
  }}>
      <TbLogout2 size={22} />
      <span>Logout</span>
    </div>;
  const contextValue = useMemo(() => ({
    errorMessage,
    setErrorMessage
  }), [errorMessage, setErrorMessage]);
  return <PaymentModalContext.Provider value={contextValue} data-sentry-element="unknown" data-sentry-component="PaymentModal" data-sentry-source-file="StripeProvider.tsx">
      <GenericStagePopup title={translate('activate_your_plan')} overlayStyles={{}} open={showPaymentModal} poupStyles={popupStyles} isModal activeStep={step} setActiveStep={setStep} resetStep={resetStep} steps={steps} stageComponent={(stageComponent as React.FC<any>)} stageState={({} as any)} setStageState={() => {}} additionalHeaderContent={logoutButton} sidebarBottomContent={<SidebarBottomCarouselContent />} onOpen={() => {
      mixpanelTrackEventWrapper(MIXPANEL_EVENTS.USER_VIEWED_PAYMENT_MODAL);
    }} data-sentry-element="GenericStagePopup" data-sentry-source-file="StripeProvider.tsx" />
    </PaymentModalContext.Provider>;
}