/* eslint-disable import/no-cycle */
import { get as idbGet, set as idbSet } from 'idb-keyval';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

// eslint-disable-next-line import/no-cycle
import {
  failValue,
  type FormulaEntries,
  type FormulaEntry,
} from '@/components/modelCalculations/modelUtils/modelUtils';
import { parseUTCDateObject } from '@/utils/dateUtils';
import {
  type CalculatedEmployeeData,
  transformEmployeeDataForGraph,
} from '@/utils/HiringPlanUtils';
import {
  HPFormulaDateRange,
  SystemFormulaIdsRange,
  systemFormulasMap,
} from '@/utils/hooks/formulas/SystemFormulas';
import type { Formula } from '@/utils/types/formulaTypes';

import { getCostPerMonth } from '../constant/chartConfig';
import type {
  GetAccountValuesByDateRangeProps,
  OutputValues,
  OutputValuesMap,
} from './calulcationsStoreHelper';
// eslint-disable-next-line import/no-cycle
import {
  createBothDataMap,
  getAccountValuesByDateRangeHelper,
} from './calulcationsStoreHelper';
// eslint-disable-next-line import/no-cycle
import useZustandStore from './zustand_store';

type CalculationsStoreDataState = {
  formulaEntries: FormulaEntries;
  formulaDependencyMap: Record<number, number[]>;
  formulaParentMap: Record<number, number[]>;
  calculatedEmployeeData: CalculatedEmployeeData;
  HPformulaEntries: FormulaEntries;
  HPformulas: Formula[];
  HPgraphDataCost: object | null;
  HPgraphDataHeadcount: object | null;
  HPcostPerMonthHeadcount: object | null;
  HPcostPerMonthPayroll: object | null;
  liveDataMap: OutputValuesMap;
  bothDataMap: OutputValuesMap; // this is the both default data map by current date in zustand store.
  forecastDataMap: OutputValuesMap;
  outputsEffectingFormulasMap: any;
};

type CalculationsStoreActionsState = {
  resetFormulaEntries: () => void;
  resetDependencyMap: () => void;
  addFormulaDependencies: (formulaId: number, dependencies: number[]) => void;
  addFormulaEntry: (formulaId: number, entry: FormulaEntry) => void;
  setFormulaDependencyMap: (map: Record<number, number[]>) => void;
  setFormulaParentMap: (map: Record<number, number[]>) => void;
  resetStore: () => void;
  setFormulaEntries: (entries: FormulaEntries) => void;
  setHPFormulaEntries: (entries: FormulaEntries) => void;
  setHPFormulas: (formulas: Formula[]) => void;
  setCalculatedEmployeeData: (data: CalculatedEmployeeData) => void;
  setHPGraphDataCost: (data: CalculatedEmployeeData) => void;
  setHPGraphDataHeadcount: (data: CalculatedEmployeeData) => void;
  updatBothDataMap: () => void;
  setHPCostPerMonthHeadcount: (
    data: CalculatedEmployeeData,
    date: Date,
  ) => void;
  setHPCostPerMonthPayroll: (data: CalculatedEmployeeData, date: Date) => void;
  setLiveDataMap: (newMap: OutputValuesMap) => void;
  setForecastDataMap: (newMap: OutputValuesMap) => void;
  getAccountValuesByDateRange: (
    props: GetAccountValuesByDateRangeProps,
  ) => OutputValues | undefined;
  getSystemFormulaValueAt: ({
    formulaId,
    dateValue,
    formulaEntries,
    currentRowFormula,
  }: {
    formulaId: number;
    dateValue: string;
    formulaEntries?: FormulaEntries;
    currentRowFormula?: Formula;
  }) => number;
  calculateOutputsEffectingFormulas: () => void;
};

export type CalculationsStoreState = CalculationsStoreDataState &
  CalculationsStoreActionsState;

export const defaultState: CalculationsStoreDataState = {
  formulaEntries: {} as FormulaEntries,
  formulaDependencyMap: {} as Record<number, number[]>,
  formulaParentMap: {} as Record<number, number[]>,
  calculatedEmployeeData: {} as CalculatedEmployeeData,
  HPformulaEntries: {} as FormulaEntries,
  liveDataMap: {} as OutputValuesMap,
  bothDataMap: {} as OutputValuesMap,
  forecastDataMap: {} as OutputValuesMap,
  HPformulas: [],
  HPgraphDataCost: null,
  HPgraphDataHeadcount: null,
  HPcostPerMonthHeadcount: null,
  HPcostPerMonthPayroll: null,
  outputsEffectingFormulasMap: {},
};

// Define an enum for the model names
export enum ModelName {
  System = 'System',
  Hiring = 'Hiring',
  Model = 'Model',
}

// Update the getModelNameById function to use the enum
export const getModelNameById = (id: number): ModelName => {
  if (id < SystemFormulaIdsRange && id >= HPFormulaDateRange) {
    return ModelName.System;
  }
  if (id < HPFormulaDateRange) {
    return ModelName.Hiring;
  }
  return ModelName.Model;
};

const CalculationsStore = create(
  persist<CalculationsStoreState>(
    (set, get) => ({
      ...defaultState,
      calculateOutputsEffectingFormulas: () => {
        const { forecastDataMap, formulaEntries } = get();
        const { formulaIDToFormulaMap, runwayMonthlyValues } =
          useZustandStore.getState();
        const runwayDates = Object.keys(runwayMonthlyValues || {});

        const outputs = Object.values(forecastDataMap || {})
          .map((outputDetails) => {
            const outputId = outputDetails?.output?.id;

            const filteredValues = Object.entries(
              outputDetails?.outputTotalValues || {},
            )
              .filter(([date]) => runwayDates.includes(date))
              .reduce((acc, [date, value]) => ({ ...acc, [date]: value }), {});

            const relatedFormulas = Object.entries(formulaIDToFormulaMap || {})
              .filter(([_, formula]) => formula.output_id === outputId)
              .map(([formulaId, formula]) => ({
                formula_name: formula.name,
                values: formulaEntries?.[formulaId as any] || {},
              }));

            return {
              output_name: outputDetails?.output?.name,
              values: filteredValues,
              formulas_affecting: relatedFormulas,
            };
          })
          .filter((output) => output.formulas_affecting.length > 0);

        set({ outputsEffectingFormulasMap: outputs });
      },
      setFormulaDependencyMap: (map: Record<number, number[]>) =>
        set({ formulaDependencyMap: map }),
      setFormulaParentMap: (map: Record<number, number[]>) =>
        set({ formulaParentMap: map }),
      resetFormulaEntries: () => set({ formulaEntries: {} }),
      resetDependencyMap: () => set({ formulaDependencyMap: {} }),
      addFormulaDependencies: (formulaId: number, dependencies: number[]) =>
        set((state) => {
          return {
            formulaDependencyMap: {
              ...state.formulaDependencyMap,
              [formulaId]: dependencies,
            },
          };
        }),
      addFormulaEntry: (formulaId: number, entry: FormulaEntry) =>
        set((state) => {
          return {
            formulaEntries: {
              ...state.formulaEntries,
              [formulaId]: entry,
            },
          };
        }),
      setFormulaEntries: (entries: FormulaEntries) => {
        set({
          formulaEntries: {
            ...entries,
            ...get().HPformulaEntries,
          },
        });
        get().calculateOutputsEffectingFormulas();
      },
      setCalculatedEmployeeData: (data: CalculatedEmployeeData) =>
        set(() => {
          return {
            calculatedEmployeeData: data,
          };
        }),

      setHPFormulaEntries: (entries: FormulaEntries) =>
        set({
          HPformulaEntries: entries,
          formulaEntries: { ...get().formulaEntries, ...entries },
        }),
      setHPFormulas: (formulas: Formula[]) => set({ HPformulas: formulas }),

      setHPGraphDataCost: (data: CalculatedEmployeeData) =>
        set({
          HPgraphDataCost: transformEmployeeDataForGraph(data, 'cost'),
        }),
      setHPGraphDataHeadcount: (data: CalculatedEmployeeData) => {
        set({
          HPgraphDataHeadcount: transformEmployeeDataForGraph(
            data,
            'headcount',
          ),
        });
      },
      setLiveDataMap: (newMap: OutputValuesMap) => {
        const { forecastDataMap } = get();
        const { currentDate: zustandCurrentDate } = useZustandStore.getState(); // setting default values if missing
        // iterate over the monthlyDates
        const newBothDataMap: OutputValuesMap = createBothDataMap(
          newMap,
          forecastDataMap,
          zustandCurrentDate,
        );
        set({ liveDataMap: newMap, bothDataMap: newBothDataMap });
      },
      updatBothDataMap: () => {
        const { liveDataMap, forecastDataMap } = get();
        const { currentDate: zustandCurrentDate } = useZustandStore.getState(); // setting default values if missing
        // iterate over the monthlyDates
        const newBothDataMap: OutputValuesMap = createBothDataMap(
          liveDataMap,
          forecastDataMap,
          zustandCurrentDate,
        );
        set({ bothDataMap: newBothDataMap });
      },
      setForecastDataMap: (newMap: OutputValuesMap) => {
        const { liveDataMap } = get();
        const { currentDate: zustandCurrentDate } = useZustandStore.getState(); // setting default values if missing
        // iterate over the monthlyDates
        const newBothDataMap: OutputValuesMap = createBothDataMap(
          liveDataMap,
          newMap,
          zustandCurrentDate,
        );

        set({ forecastDataMap: newMap, bothDataMap: newBothDataMap });
        get().calculateOutputsEffectingFormulas();
      },
      setHPCostPerMonthHeadcount: (
        data: CalculatedEmployeeData,
        date: Date,
      ) => {
        set({
          HPcostPerMonthHeadcount: getCostPerMonth(data, date, 'headcount'),
        });
      },
      getSystemFormulaValueAt: ({
        // this function takes a formulaId and a dateValue and returns the value of that system formula at that date
        formulaId,
        dateValue,
        formulaEntries,
        currentRowFormula,
      }: {
        formulaId: number;
        dateValue: string;
        formulaEntries?: FormulaEntries;
        currentRowFormula?: Formula;
      }) => {
        const formula = systemFormulasMap[formulaId];
        const evalFunction = formula?.evalFunction;
        if (!formula || !evalFunction) {
          console.error('Formula not in entries id:', formulaId);
          return failValue;
        }
        return (
          evalFunction(
            dateValue,
            formulaId,
            formulaEntries,
            currentRowFormula,
          ) || failValue
        );
      },
      getAccountValuesByDateRange: ({
        outputID: accountID,
        sortedDateRange,
        currentDate,
        filterValuesFromEntries,
        dataType = 'both',
      }: GetAccountValuesByDateRangeProps): OutputValues | undefined => {
        // get the live and forecast data maps
        const { liveDataMap, forecastDataMap, bothDataMap } = get();
        const { currentDate: zustandCurrentDate, fullTimelineMonthlyDates } =
          useZustandStore.getState(); // setting default values if missing
        const isCurrentZustandDate =
          currentDate === undefined ||
          !!currentDate ||
          currentDate === zustandCurrentDate; // if no date is provided, use the current zustand date
        const zustandParseDate = parseUTCDateObject(zustandCurrentDate);
        return getAccountValuesByDateRangeHelper({
          liveDataMap,
          forecastDataMap,
          bothDataMap,
          outputID: accountID,
          sortedDateRange: sortedDateRange || fullTimelineMonthlyDates,
          currentDate: currentDate || zustandParseDate,
          filterValuesFromEntries,
          dataType,
          isCurrentZustandDate,
        });
      },
      setHPCostPerMonthPayroll: (data: CalculatedEmployeeData, date: Date) => {
        set({
          HPcostPerMonthPayroll: getCostPerMonth(data, date, 'payroll'),
        });
      },
      resetStore: () => set({ ...defaultState }),
    }),
    {
      name: 'calculations-store',
      storage: createJSONStorage(() => ({
        getItem: async (name) => {
          const value = await idbGet(name);
          return value ? JSON.stringify(value) : null;
        },
        setItem: async (name, value) => {
          await idbSet(name, JSON.parse(value));
        },
        removeItem: async (name) => {
          await idbSet(name, undefined);
        },
      })),
    },
  ),
);

export default CalculationsStore;
