import { Search, X } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOpenVault } from '@/components/authComponents/useOpenVault';
import type { ApiDeckConnector } from '@/utils/hooks/useGetConnectors/useGetConnectors';
import useGetConnectors from '@/utils/hooks/useGetConnectors/useGetConnectors';

// eslint-disable-next-line import/no-cycle
import { IntegrationPageBody } from '../Integrations';
import styles from '../integrations.module.scss';
const IntegrationsPopupBody = ({
  isIntegrationPopUpOpen,
  setIsIntegrationPopUpOpen,
  isFilterEmptyTabs,
  onConnected
}: {
  isIntegrationPopUpOpen?: boolean;
  setIsIntegrationPopUpOpen?: (value: boolean) => void;
  isFilterEmptyTabs?: boolean;
  onConnected?: (item: ApiDeckConnector) => void;
}) => {
  const {
    t: translate
  } = useTranslation(['integrations']);
  const [searchValue, setSearchValue] = useState('');
  const {
    connectorsData
  } = useGetConnectors();
  const openVault = useOpenVault();

  // handleSearch is a function that filters the connectorsData based on the searchValue
  const handleSearch = () => {
    if (connectorsData) {
      return connectorsData.filter((item: ApiDeckConnector) => item?.service_id?.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return [];
  };

  // onConnectIntegration is a function that is called when the user clicks on the connect button for an integration
  const onConnectIntegration = (item: ApiDeckConnector) => {
    openVault(item.service_id, item.unified_api, () => {
      setIsIntegrationPopUpOpen?.(false);
      onConnected?.(item);
    });
  };
  return <div className={styles.addIntegrationsPoupContainer} data-testid="add-integrations-popup" data-sentry-component="IntegrationsPopupBody" data-sentry-source-file="IntegrationsPopupBody.tsx">
      <div className={styles.addIntegrationsPoupHeader}>
        <h1 data-testid="popup-title">{translate('new_integration')}</h1>
        <div className={styles.addIntegrationsPoupInputContainer}>
          <Search className={styles.searchIcon} size={16} data-sentry-element="Search" data-sentry-source-file="IntegrationsPopupBody.tsx" />
          <input type="text" value={searchValue} placeholder={translate('searchPlaceholder')} onChange={e => setSearchValue(e.target.value)} data-testid="search-input"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus />
          {searchValue && <X className={styles.clearIcon} size={16} onClick={() => setSearchValue('')} data-testid="clear-icon" />}
        </div>
        {isIntegrationPopUpOpen && <X size={20} onClick={() => setIsIntegrationPopUpOpen?.(false)} data-testid="close-button" />}
      </div>
      <div className={styles.addIntegrationsPoupBody}>
        <IntegrationPageBody apiDeckData={handleSearch()} isIntegrationPopUpOpen={isIntegrationPopUpOpen ?? true} onConnectIntegration={onConnectIntegration} isFilterEmptyTabs={isFilterEmptyTabs} data-sentry-element="IntegrationPageBody" data-sentry-source-file="IntegrationsPopupBody.tsx" />
      </div>
    </div>;
};
export default IntegrationsPopupBody;