import { useRouter } from 'next/router';
import { useShallow } from 'zustand/react/shallow';
import ChatDrawer from '@/components/common/CopilotChat/CopilotChatComponents/ChatDrawer';
import StripeCheckout from '@/components/common/StripeComponents/StripeCheckout/StripeCheckout';
import PaymentModal from '@/components/common/StripeComponents/StripeProvider';
import AddIntegrationsPopup from '@/components/Settings/Integrations/IntegrationsPoup/AddIntegrationsPopup';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useHotWordListener from '@/utils/hooks/useHotWordListener/useHotWordListener';
import useProtectPaymentModal from '@/utils/hooks/useProtectPaymentModal';
import ProductFruitsLayout from '../ProductFruitsLayout/ProductFruitsLayout';

// Global components layout wrapper
const GlobalComponentsLayout = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();

  // get the state from the utils store
  const {
    isHotWordListener,
    isIntegrationPopUpOpen,
    showPaymentModal
  } = useUtilsStore(useShallow(state => ({
    isHotWordListener: state.isHotWordListener,
    isIntegrationPopUpOpen: state.isIntegrationPopUpOpen,
    showPaymentModal: state.showPaymentModal
  })));

  // listen for the trigger phrases and set the state accordingly
  useHotWordListener(isHotWordListener);
  // protect the payment modal from dev tools
  useProtectPaymentModal();
  return <ProductFruitsLayout data-sentry-element="ProductFruitsLayout" data-sentry-component="GlobalComponentsLayout" data-sentry-source-file="GlobalComponentsLayout.tsx">
      {children}
      {isIntegrationPopUpOpen && <AddIntegrationsPopup />}
      {showPaymentModal && <PaymentModal key={router.pathname}>
          <StripeCheckout />
        </PaymentModal>}
      <ChatDrawer data-sentry-element="ChatDrawer" data-sentry-source-file="GlobalComponentsLayout.tsx" />
    </ProductFruitsLayout>;
};
export default GlobalComponentsLayout;