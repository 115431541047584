import _ from 'lodash';

import { convertNumberToCurrencyString } from '@/components/table/shared/utils';
import colors from '@/styles/scss/abstracts/_variables.module.scss';

import type { GraphConfig } from './ChartUtils';
import { createAdjustedUTCDate, parseUTCDateObject } from './dateUtils';
import type { HiringBranchSettings } from './hooks/settings/useHiringBranchSettings';
import type { Department, Employee } from './types/HiringTypes';

interface DepartmentData {
  [date: string]: number;
}

export interface CalculatedEmployeeData {
  costByDepartment: Record<Department, DepartmentData>;
  headCountByDepartment: Record<Department, DepartmentData>;
}

export function isDepartment(value: any): value is Department {
  return ['rnd', 'snm', 'gna', 'cogs'].includes(value);
}
function fillEmptyDepartment(
  costByDepartment: Record<Department, DepartmentData>,
  monthKey: string,
  headCountByDepartment: Record<Department, DepartmentData>,
) {
  Object.keys(costByDepartment).forEach((department) => {
    if (!isDepartment(department)) {
      return; // Skip this department if department is not defined
    }

    if (!costByDepartment[department][monthKey]) {
      // eslint-disable-next-line no-param-reassign
      costByDepartment[department][monthKey] = 0;
    }
    if (!headCountByDepartment[department][monthKey]) {
      // eslint-disable-next-line no-param-reassign
      headCountByDepartment[department][monthKey] = 0;
    }
  });
}
// This function is used to create the employee breakdown to costByDepartment and headCountByDepartment
function createEmployeeBreakdown(
  employees: Employee[],
  d: Date,
  costByDepartment: Record<Department, DepartmentData>,
  monthKey: string,
  headCountByDepartment: Record<Department, DepartmentData>,
  end_date: Date,
) {
  if (!employees || employees === undefined || employees === null) return;

  employees.forEach((employee) => {
    if (!employee.start_date || !isDepartment(employee.department)) {
      return; // Skip this employee if start_date/department is not defined
    }

    const yearlySalary = employee.yearly_salary ? employee.yearly_salary : 0;
    const { department } = employee;
    const empStartDate = parseUTCDateObject(employee.start_date);

    const empEndDate = employee.end_date
      ? parseUTCDateObject(employee.end_date)
      : end_date;

    // If the employee is active in the current month, add the cost and headcount
    if (empStartDate <= d && (!empEndDate || empEndDate >= d)) {
      // eslint-disable-next-line no-param-reassign
      costByDepartment[department][monthKey] = Math.round(
        (costByDepartment[department][monthKey] || 0) + yearlySalary / 12,
      );

      // eslint-disable-next-line no-param-reassign
      headCountByDepartment[department][monthKey] =
        (headCountByDepartment[department][monthKey] || 0) + 1;
    }
  });
}
/**
 * This function checks if CalculatedEmployeeData has any employees at all. return true  if has some employee data,otherwise returns false
 * @param data
 * @returns
 */
export const isEmployeeDataDefined = (
  data: CalculatedEmployeeData,
): boolean => {
  return (
    _.some(
      data.costByDepartment,
      (departmentData) => !_.isEmpty(departmentData),
    ) ||
    _.some(
      data.headCountByDepartment,
      (departmentData) => !_.isEmpty(departmentData),
    )
  );
};

/**
 * this function is used to calculate the data that is used to create the graph
 * @param employees the data that is coming from the useEmployeesQuery hook
 * @param start_date the start date of the graph
 * @param end_date the end date of the graph
 * @returns calculated data that is used to create the graph
 */
export function getEmployeesCalculatedData(
  employees: Employee[],
  start_date: Date,
  end_date: Date,
  branchHiringSetting: HiringBranchSettings,
): CalculatedEmployeeData {
  const costByDepartment: Record<Department, DepartmentData> = {
    gna: {},
    rnd: {},
    snm: {},
    cogs: {},
  };
  const headCountByDepartment: Record<Department, DepartmentData> = {
    gna: {},
    rnd: {},
    snm: {},
    cogs: {},
  };

  if (!employees || employees.length === 0) {
    // If there are no employees, return empty data
    return { costByDepartment, headCountByDepartment };
  }

  // Track salary increases per employee
  const employeeSalaries: Record<string, number> = {};
  const employeeStartYears: Record<string, number> = {};

  employees.forEach((employee) => {
    if (employee.yearly_salary && employee.start_date) {
      const id = employee.id as number;
      employeeSalaries[id] = employee.yearly_salary;
      employeeStartYears[id] = parseUTCDateObject(
        employee.start_date,
      ).getFullYear();
    }
  });

  // Loop through each month between start_date and end_date
  for (
    let d = parseUTCDateObject(start_date);
    d <= end_date;
    d.setMonth(d.getMonth() + 1)
  ) {
    const month = d.getMonth() + 1; // 1-based month
    const year = d.getFullYear();

    // check there is a month and year otherwise skip
    if (month !== undefined && year !== undefined) {
      const monthKey = `${`0${month}`.slice(-2)}-${year}`; // MM-YYYY format

      // Apply salary increases in salary increase month, but only for employees who started before current year
      if (month === branchHiringSetting.salary_increse_month) {
        const raiseMultiplier =
          1 + (branchHiringSetting.annual_raise_precentage || 0) / 100;
        Object.keys(employeeSalaries).forEach((idStr) => {
          const id = parseInt(idStr, 10);
          const startYear = employeeStartYears[id];
          const currentSalary = employeeSalaries[id];
          // Only apply raise if employee started before current year and has a start year
          if (startYear !== undefined && startYear < year && currentSalary) {
            employeeSalaries[id] = currentSalary * raiseMultiplier;
          }
        });
      }

      // if current month key is not in costByDepartment and headCountByDepartment set it to 0
      fillEmptyDepartment(costByDepartment, monthKey, headCountByDepartment);

      // Create modified employees array with updated salaries
      const employeesWithUpdatedSalaries = employees.map((emp) => {
        if (!emp.yearly_salary) return emp;

        const updatedSalary = employeeSalaries[emp.id as number] ?? 0;
        const withSocialContribution =
          updatedSalary * (1 + (emp.social_contribution || 0));

        return {
          ...emp,
          yearly_salary: withSocialContribution,
        };
      });

      createEmployeeBreakdown(
        employeesWithUpdatedSalaries,
        d,
        costByDepartment,
        monthKey,
        headCountByDepartment,
        end_date,
      );
    }
  }

  return { costByDepartment, headCountByDepartment };
}
/**
 * This function is used to transform the data for the graph
 * @param inputData this is the data that is coming from the getEmployeesCalculatedData function
 * @returns this function returns the data that is used to create the graph
 */

export function transformEmployeeDataForGraph(
  inputData: CalculatedEmployeeData,
  type: string,
): GraphConfig {
  const departmentOrder: Record<string, string> = {
    gna: 'G&A',
    rnd: 'R&D',
    snm: 'S&M',
    cogs: 'COGS',
  };

  /**
   * Sets the chartData variable to the appropriate input data based on the chart type.
   * If type is 'cost', set chartData to the costByDepartment data.
   * If type is 'headcount', set chartData to the headCountByDepartment data.
   */
  let chartData: any = null;

  if (type === 'cost') {
    chartData = inputData.costByDepartment;
  }

  if (type === 'headcount') {
    chartData = inputData.headCountByDepartment;
  }

  // check that costByDepartment is not empty
  // Extracting and sorting the dates
  const dates = Object.keys(chartData?.gna || {}).sort(
    (a, b) => parseUTCDateObject(a).getTime() - parseUTCDateObject(b).getTime(),
  );

  // Generating xaxisLabels
  const xaxisLabels = dates.map((dateString) => {
    // Split the MM-YYYY format and reorder to YYYY-MM
    const [month, year] = dateString.split('-');
    const date = parseUTCDateObject(`${year}-${month}-01`); // Using the 1st day of the month

    // Convert to MMM format
    return date.toLocaleString('en', { month: 'short', year: '2-digit' });
  });
  // Prepare data for each department and find max value
  let maxValue = 0;
  const configPropsData = Object.entries(departmentOrder).map(([key, name]) => {
    const data = dates.map((date) => {
      const departmentKey = key as Department; // Type assertion
      const value = chartData[departmentKey]?.[date] || 0;
      maxValue = Math.max(maxValue, value);
      return value;
    });
    return { name, data };
  });

  // Generating yaxisLabels based on the max value
  const yAxisInterval = Math.ceil(maxValue / 7);
  const yaxisLabels = Array.from({ length: 8 }, (__, i) => {
    // before returning the value
    // convert it to M,K
    const curValue = i * yAxisInterval;
    const stringValue =
      type === 'cost'
        ? convertNumberToCurrencyString(curValue)
        : curValue.toString();
    return `${stringValue}`;
  });

  return {
    configPropsData,
    yaxisLabels,
    xaxisLabels,
  };
}

export const styleGeneratorForEmployeeRow = (employee: Employee) => {
  if (employee?.is_ai_employee) {
    return {
      backgroundColor: `${colors.veryPaleOrange2}`,
    };
  }
  if (employee?.integration_id) {
    return {
      backgroundColor: `${colors.whisperBlue}`,
    };
  }

  if (
    employee?.end_date && // Check if employee has an end date
    parseUTCDateObject(employee.end_date) < parseUTCDateObject() // Compare if end date is before current date
  ) {
    return {
      backgroundColor: `${colors.lightGrey}`, // Apply grey background for past employees
    };
  }

  if (
    // Check if:
    // 1. Employee has an end date
    employee?.end_date &&
    // 2. End date is in the future or today
    parseUTCDateObject(employee.end_date) >= parseUTCDateObject() &&
    // 3. End date is within next 3 months
    parseUTCDateObject(employee.end_date) <=
      createAdjustedUTCDate({
        adjustment: {
          value: 3,
          unit: 'months',
        },
      })
  ) {
    return {
      backgroundColor: `${colors.lightOrange}`, // Apply light orange background for employees who are leaving soon
    };
  }
  return {};
};
