import { Blocks, File } from 'lucide-react';
import React from 'react';
import { FaFileExcel } from 'react-icons/fa6';
import { IoSparklesOutline } from 'react-icons/io5';
import colors from '@/styles/scss/abstracts/_variables.module.scss';
import { createAdjustedUTCDate, getDateStringFormat } from '@/utils/dateUtils';
import type { CompanyState } from './onboardingPopupTypes';
import { AutomatedAiPlanBuilderSubPlanType, CompanyPlanType, FastPlanOptionsType } from './Stages/NewCompanySetupStage/NewCompanySetupStage';

/**
 * Updates the onboarding state based on the current step and company state
 * @param step - Current onboarding step
 * @param companyState - Current company state
 * @returns Updated state with completed steps and selected plans
 */
export const handlePlanSelection = (step: string, companyState: CompanyState) => {
  const newPlan = companyState['Choose your financial plan']?.selectedPlan || null;
  const automatedPlan = step === 'Automated AI Plan Builder' || step === 'Fast Plan Options' ? companyState['Automated AI Plan Builder']?.selectedPlan || null : null;
  const fastPlan = step === 'Fast Plan Options' ? companyState['Fast Plan Options']?.selectedPlan || null : null;
  return {
    plan: newPlan,
    ai_plan_action: automatedPlan,
    ai_plan_type: fastPlan
  };
};

/**
 * Determines the next step in the onboarding flow
 * @param step - Current onboarding step
 * @param companyState - Current company state
 * @returns Next step in the onboarding process
 */
export const getNextStep = (step: string, companyState: CompanyState): string => {
  if (step === 'Choose your financial plan' && companyState['Choose your financial plan'].selectedPlan === CompanyPlanType.AutomatedAiPlanBuilder) {
    return 'Automated AI Plan Builder';
  }
  return step;
};

/**
 * Initializes the onboarding state from saved data
 * @param companyState - Current company state
 * @param onboardingState - Previously saved onboarding state
 * @returns Initialized company state with restored selections
 */
export const getInitialOnboardingState = (companyState: CompanyState, onboardingState: {
  plan: CompanyPlanType | null;
  ai_plan_action: AutomatedAiPlanBuilderSubPlanType | null;
  ai_plan_type: FastPlanOptionsType | null;
} | null) => {
  if (!onboardingState) return companyState;
  return {
    ...companyState,
    ...(onboardingState.plan && {
      'Choose your financial plan': {
        selectedPlan: null
      }
    }),
    ...(onboardingState.ai_plan_action && {
      'Automated AI Plan Builder': {
        selectedPlan: onboardingState.ai_plan_action
      }
    }),
    ...(onboardingState.ai_plan_type && {
      'Fast Plan Options': {
        selectedPlan: onboardingState.ai_plan_type
      }
    })
  };
};

/**
 * Returns configuration objects for different plan types in the onboarding process
 * @param translate - Translation function to localize text content
 * @param isOnboarding - Boolean flag indicating if this is being used in onboarding flow
 * @returns Object containing configurations for main plans, AI plan builder options, and fast plan options
 */
export const getPlanConfigs = (translate: (key: string) => string, isOnboarding: boolean) => {
  return {
    planConfig: [{
      icon: <FaFileExcel size={40} />,
      title: translate('upload_your_plan'),
      badge: null,
      isHtml: true,
      type: CompanyPlanType.UploadYourPlan,
      descriptionList: [translate('upload_your_plan_txt_key_1'), translate('upload_your_plan_txt_key_2'), translate('upload_your_plan_txt_key_3')],
      isAiCard: false,
      isHidden: false
    }, {
      icon: <IoSparklesOutline size={40} />,
      title: translate('automated_ai_plan_builder'),
      badge: translate('recommended'),
      isHtml: false,
      type: CompanyPlanType.AutomatedAiPlanBuilder,
      descriptionList: [translate('automated_ai_plan_builder_txt_key_1'), translate('automated_ai_plan_builder_txt_key_2')],
      isAiCard: true,
      isHidden: false
    }, {
      icon: <File size={40} />,
      title: translate('blank_plan'),
      badge: null,
      type: CompanyPlanType.BlankPlan,
      descriptionList: [translate('blank_plan_description')],
      isAiCard: false,
      isHidden: isOnboarding
    }],
    automatedAiPlanBuilderPlanConfig: [{
      icon: <IoSparklesOutline size={40} />,
      title: translate('benchmark_plan'),
      badge: null,
      isHtml: false,
      type: AutomatedAiPlanBuilderSubPlanType.OneClickPlan,
      descriptionList: [translate('benchmark_plan_txt_key_1'), translate('benchmark_plan_txt_key_2')],
      isAiCard: true,
      isHidden: false
    }, {
      icon: <Blocks size={40} />,
      title: translate('plan_builder'),
      badge: null,
      isHtml: false,
      type: AutomatedAiPlanBuilderSubPlanType.PlanBuilder,
      descriptionList: [translate('plan_builder_description')],
      isAiCard: true,
      isHidden: false
    }],
    fastPlanOptionsConfig: [{
      icon: <IoSparklesOutline size={40} />,
      title: translate('simple_fast_plan'),
      badge: null,
      isHtml: false,
      type: FastPlanOptionsType.SimpleFastPlan,
      descriptionList: [translate('simple_fast_plan_description')],
      isAiCard: true
    }, {
      icon: <IoSparklesOutline size={40} />,
      title: translate('advanced_fast_plan'),
      description: translate('advanced_fast_plan_description'),
      badge: null,
      isHtml: false,
      type: FastPlanOptionsType.AdvancedFastPlan,
      descriptionList: [translate('advanced_fast_plan_description')],
      isAiCard: true
    }]
  };
};

/**
 * Returns an array of step configurations for the company onboarding process.
 *
 * This function defines the structure and properties of each step in the onboarding flow.
 * Each step object contains:
 * - label: The display name for the step (translated)
 * - route: The internal route identifier for the step
 * - hideSidebar: Whether to hide the sidebar navigation during this step
 * - isHiddenStep: Whether this step should be hidden from the normal flow (conditional steps)
 * - is_plan_selection_step: Indicates if this step is part of the plan selection process
 *
 * The function handles conditional visibility of certain steps based on the onboarding context.
 *
 * @param {function} translate - Translation function that returns localized strings
 * @param {boolean} isOnboarding - Flag indicating if this is the initial onboarding process
 * @returns {Array} Array of step configuration objects for the onboarding process
 */
export const getInitialSteps = (translate: (key: string) => string, isOnboarding: boolean) => [{
  label: translate('choose_your_financial_plan'),
  route: 'Choose your financial plan',
  hideSidebar: true,
  isHiddenStep: false,
  is_plan_selection_step: true
}, {
  label: translate('automated_ai_plan_builder'),
  route: 'Automated AI Plan Builder',
  isHiddenStep: isOnboarding,
  hideSidebar: true,
  is_plan_selection_step: true
}, {
  label: translate('fast_plan_options'),
  route: 'Fast Plan Options',
  isHiddenStep: true,
  hideSidebar: true,
  is_plan_selection_step: true
}, {
  label: translate('company_website'),
  route: 'Company Website',
  hideSidebar: true,
  isHiddenStep: false
}, {
  label: translate('company_name'),
  route: 'Company Name',
  hideSidebar: false
}, {
  label: translate('integrations'),
  route: 'Integrations',
  hideSidebar: false
}, {
  label: translate('company_details'),
  route: 'Company Details',
  hideSidebar: false
}, {
  label: translate('details_confirmation'),
  route: 'Details Confirmation',
  hideSidebar: false
}, {
  label: translate('company_goals'),
  route: 'Company Goals',
  hideSidebar: false,
  isLastStep: isOnboarding
}, {
  label: translate('company_products'),
  route: 'Company Products',
  hideSidebar: false,
  isHiddenStep: isOnboarding
}, {
  label: translate('your_custom_plan'),
  route: 'Your Custom Plan',
  hideSidebar: false,
  isHiddenStep: isOnboarding,
  isLastStep: !isOnboarding
}, {
  label: translate('upload_your_plan'),
  route: 'Upload Your Plan',
  hideSidebar: true,
  isHiddenStep: true,
  is_plan_selection_step: true
}];

/**
 * Returns the styles for the popup and overlay
 * @param colors - Color variables from SCSS module
 * @returns Object containing popup and overlay styles
 */
export const getPopupStyles = () => ({
  popupStyles: {
    borderRadius: '20px',
    padding: '24px',
    gap: '16px',
    width: '1100px',
    height: '710px'
  },
  overlayStyles: {
    backgroundColor: colors.modalOcerlayColor
  }
});

// Define the initial state for the company information being entered
export const initialCompanyState = {
  'Choose your financial plan': {
    selectedPlan: null
  },
  'Upload Your Plan': {},
  'Automated AI Plan Builder': {
    selectedPlan: AutomatedAiPlanBuilderSubPlanType.OneClickPlan
  },
  'Fast Plan Options': {
    selectedPlan: FastPlanOptionsType.SimpleFastPlan
  },
  'Company Name': {
    companyName: ''
  },
  Integrations: {
    connectedIntegrations: []
  },
  'Company Website': {
    website: ''
  },
  'Company Details': {
    text: ''
  },
  'Details Confirmation': {
    teamLocation: 'United States of America (USA)',
    companySectors: [],
    howMuchFunding: 0,
    companyStage: ''
  },
  'Company Goals': {
    howManyEmployees: '',
    revenueAYearFromNow: 0,
    whenYouRaiseFunding: getDateStringFormat(createAdjustedUTCDate({
      adjustment: {
        value: 3,
        unit: 'months'
      }
    })),
    howMuchFunding: 0
  },
  'Company Products': {
    products: []
  },
  'Your Custom Plan': {
    chosenTemplates: []
  }
};

/**
 * Generates the breadcrumb paths configuration for the onboarding process.
 *
 * This function creates a mapping of each step in the onboarding process to its
 * corresponding breadcrumb path. Each path consists of an array of objects with
 * translation keys and visibility settings.
 *
 * @param {boolean} isOnboarding - Flag indicating if this is part of the initial onboarding process,
 *                                which affects the visibility of certain breadcrumb items.
 * @returns {Object} An object where keys are step names and values contain path configuration
 *                  with translation keys and visibility settings.
 */
export const breadcrumbPathsGenerator = (isOnboarding: boolean) => ({
  'Choose your financial plan': {
    path: [{
      labelKey: 'company:choose_your_financial_plan'
    }],
    isHidden: false
  },
  'Automated AI Plan Builder': {
    path: [{
      labelKey: 'company:choose_your_financial_plan'
    }, {
      labelKey: 'company:automated_ai_plan_builder'
    }],
    isHidden: false
  },
  'Fast Plan Options': {
    path: [{
      labelKey: 'company:choose_your_financial_plan'
    }, {
      labelKey: 'company:automated_ai_plan_builder'
    }, {
      labelKey: 'company:benchmark_plan'
    }],
    isHidden: false
  },
  'Company Website': {
    path: [{
      labelKey: 'company:choose_your_financial_plan'
    }, {
      labelKey: 'company:automated_ai_plan_builder',
      isHidden: isOnboarding
    }, {
      labelKey: 'company:company_website'
    }],
    isHidden: false
  },
  'Upload Your Plan': {
    path: [{
      labelKey: 'company:choose_your_financial_plan'
    }, {
      labelKey: 'company:upload_your_plan'
    }],
    isHidden: false
  }
});

/**
 * Retrieves the breadcrumb path for a specific step in the onboarding process.
 *
 * This function takes the current step name, a translation function, and an optional
 * onboarding flag to generate the appropriate breadcrumb path. It filters out hidden
 * items and translates the labels.
 *
 * @param {string} currentStep - The name of the current step in the onboarding process.
 * @param {(key: string) => string} translate - Function to translate label keys.
 * @param {boolean} [isOnboarding=false] - Flag indicating if this is part of the initial onboarding process.
 * @returns {Array<{label: string}> | null} An array of breadcrumb items with translated labels,
 *                                          or null if the entire path is hidden.
 */
export const getBreadcrumbPath = (currentStep: string, translate: (key: string) => string, isOnboarding: boolean = false) => {
  const paths = breadcrumbPathsGenerator(isOnboarding);
  const pathConfig = paths[(currentStep as keyof typeof paths)];
  if (pathConfig?.isHidden) return null;
  return pathConfig?.path.filter((item: {
    labelKey: string;
    isHidden?: boolean;
  }) => !item.isHidden).map(({
    labelKey
  }: {
    labelKey: string;
    isHidden?: boolean;
  }) => ({
    label: translate(labelKey)
  }));
};