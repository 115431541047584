import React, { useEffect, useState } from 'react';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import useMutations from '@/utils/hooks/mutations/useMutations';
import useReports from '@/utils/hooks/reports/useReports';
import DraggableColumn from '../DraggableColumn/DraggableColumn';
const CurrentDateDraggableColumn = () => {
  const STEP_SIZE = 105; // Width in pixels for each month
  const MIN_STEPS = 1; // Minimum number of steps

  const {
    activeReport
  } = useReports();
  const [actualDateAreaSize, setActualDateAreaSize] = useState(0);
  const [maxStepSize, setMaxStepSize] = useState(0);
  const {
    monthlyDates,
    currentDate,
    setCurrentDate,
    activeCompany
  } = useZustandStore();
  const {
    updateCompany
  } = useMutations();

  // Initialize the width of the actual date area
  useEffect(() => {
    if (monthlyDates.length > 0 && currentDate) {
      const currentDateIndex = monthlyDates.findIndex(date => date === currentDate);
      if (currentDateIndex !== -1) {
        const defaultSize = (currentDateIndex + 1) * STEP_SIZE;
        setActualDateAreaSize(defaultSize);
      } else {
        setActualDateAreaSize(MIN_STEPS * STEP_SIZE);
      }
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 0);
    }
  }, [monthlyDates, currentDate, activeReport]);

  // Calculate maximum step size
  useEffect(() => {
    if (monthlyDates.length > 0) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const maxStepIndex = monthlyDates.findIndex(date => {
        const monthDate = new Date(date);
        return monthDate > today;
      });
      const maxStep = maxStepIndex === -1 ? monthlyDates.length : maxStepIndex;
      setMaxStepSize(maxStep * STEP_SIZE);
    }
  }, [monthlyDates]);

  // Update the current date based on the new size
  const updateCurrentDate = (newSize: number) => {
    const numberOfSteps = newSize / STEP_SIZE;
    const currentDateFromSteps = monthlyDates[numberOfSteps - 1];
    setCurrentDate((currentDateFromSteps as string));
    setActualDateAreaSize(newSize);
    updateCompany.mutate({
      ...activeCompany,
      current_date: currentDateFromSteps
    });
  };

  // Check if the report is active and if it is, check if the report is showing months, quarters, years, or total
  const isActive = activeReport.settings?.showMonths && !activeReport.settings?.showQuarters && !activeReport.settings?.showYears && !activeReport.settings?.showTotal;
  return <DraggableColumn isActive={(isActive as boolean)} leftAreaSize={actualDateAreaSize} stepSize={STEP_SIZE} minSteps={MIN_STEPS} maxSize={maxStepSize} onDrop={updateCurrentDate} data-sentry-element="DraggableColumn" data-sentry-component="CurrentDateDraggableColumn" data-sentry-source-file="CurrentDateDraggableController.tsx" />;
};
export default CurrentDateDraggableColumn;