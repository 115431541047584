/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable import/no-extraneous-dependencies */

import 'reactjs-popup/dist/index.css';
import { useButton } from '@react-aria/button';
import { useOverlay } from '@react-aria/overlays';
import { mergeProps } from '@react-aria/utils';
import { useRouter } from 'next/router';
import type { CSSProperties } from 'react';
import React from 'react';
import { useFocus, useMenu, useMenuItem, useMenuTrigger } from 'react-aria';
import { useMenuTriggerState, useTreeState } from 'react-stately';
import Popup from 'reactjs-popup';
import type { PopupActions } from 'reactjs-popup/dist/types';
import styles from '../../styles/scss/header.module.scss';
import { textColors } from '../../styles/variables/color';
function MenuPopup(props: any) {
  // Create menu state based on the incoming props
  const state: any = useTreeState({
    ...props,
    selectionMode: 'none'
  });

  // Get props for the menu element
  const ref: any = React.useRef();
  const {
    menuProps
  } = useMenu(props, state, ref);

  // Handle events that should cause the menu to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  const overlayRef: any = React.useRef();
  const {
    overlayProps
  } = useOverlay({
    isOpen: true,
    isDismissable: true
  }, overlayRef);

  // Wrap in <FocusScope> so that focus is restored back to the
  // trigger when the menu is closed. In addition, add hidden
  // <DismissButton> components at the start and end of the list
  // to allow screen reader users to dismiss the popup easily.
  return <div data-sentry-component="MenuPopup" data-sentry-source-file="MenuComponent.tsx">
      <div {...overlayProps} ref={overlayRef}>
        <ul {...mergeProps(menuProps, props.domProps)} ref={ref} aria-label="List Of Menu Items Outputs" className={`${styles.listWrapper} ${props.align ? props.align : 'p-4'} ${props.width ? props.width : 'w-40'}
          ${props.radius ? props.radius : 'rounded'}`}>
          {[...state.collection].map(item =>
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <MenuItem key={item.key} item={item} state={state} onAction={props.onAction} onClose={props.onClose} listItemStyles={props.listItemStyles} />)}
          {props.BottomComponent && props.BottomComponent}
        </ul>
      </div>
    </div>;
}
export function MenuButton(props: any) {
  // Create state based on the incoming props
  const {
    Icon
  } = props;
  const state = useMenuTriggerState(props);
  const router = useRouter();

  // Get props for the menu trigger and menu elements
  const ref: any = React.useRef();
  const popUp = React.useRef<PopupActions>(null);
  const {
    menuTriggerProps,
    menuProps
  } = useMenuTrigger({}, state, ref);

  // Get props for the button based on the trigger props from useMenuTrigger
  const {
    buttonProps
  } = useButton(menuTriggerProps, ref);
  const contentStyle: CSSProperties = {
    padding: 0,
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    width: 'auto',
    height: 'auto'
  };
  const closePopup = () => {
    router.events.off('routeChangeStart', closePopup);
    popUp.current?.close();
  };
  const handleActions = (e: any) => {
    props.onAction(e);
  };

  // close popup when page are changed
  router.events.on('routeChangeStart', closePopup);
  return <div className="menu_wrapper" data-sentry-component="MenuButton" data-sentry-source-file="MenuComponent.tsx">
      <Popup onOpen={() => document.addEventListener('scroll', closePopup)} onClose={() => document.removeEventListener('scroll', closePopup)} ref={popUp} repositionOnResize trigger={<button {...buttonProps} ref={ref} className="relative focus:outline-none">
            {Icon}
          </button>} arrow={false} position="bottom right" contentStyle={contentStyle} closeOnDocumentClick closeOnEscape nested data-sentry-element="Popup" data-sentry-source-file="MenuComponent.tsx">
        <MenuPopup {...props} onAction={handleActions} domProps={menuProps} autoFocus={state.focusStrategy} data-sentry-element="MenuPopup" data-sentry-source-file="MenuComponent.tsx" />
      </Popup>
    </div>;
}
function MenuItem({
  item,
  state,
  onAction,
  listItemStyles,
  onClose
}: any) {
  // Get props for the menu item element
  const ref: any = React.useRef();
  const {
    menuItemProps
  } = useMenuItem({
    key: item.key,
    isDisabled: item.isDisabled,
    onAction,
    onClose
  }, state, ref);

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  // const [isFocused, setFocused] = React.useState(false); this was remove to clear log
  const {
    focusProps
  } = useFocus({
    onFocusChange: item.onFocusChange
  }); // sshould be onFocusChange: setFocused

  // console.clear();
  // console.log('item', item);

  return <li {...mergeProps(menuItemProps, focusProps)} ref={ref} className={`menuItem_styling ${listItemStyles || `font-ibmMono text-xs ${textColors.blackText}`} bg-transparent ${item?.props?.['data-custom-prop'] ? styles.userSettingsDisabled : ''}`} data-sentry-component="MenuItem" data-sentry-source-file="MenuComponent.tsx">
      {item.rendered}
    </li>;
}