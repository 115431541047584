import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IntegrationsPopupBody from '@/components/Settings/Integrations/IntegrationsPoup/IntegrationsPopupBody';
import type { ApiDeckConnector } from '@/utils/hooks/useGetConnectors/useGetConnectors';
import type { CompanyState } from '../../onboardingPopupTypes';
import styles from './companyNameOrIntegrationsStage.module.scss';

/**
 * Renders the Integrations stage of the CompanyOnboardPopup.
 * This component displays the available accounting and payroll integrations
 * that the user can connect to their company.
 *
 * The component uses the `useGetConnectors` and `useGetVaultToken` hooks
 * to fetch the available integrations and the vault token, respectively.
 * It then renders the available integrations in separate sections for
 * accounting and payroll.
 *
 * The `onConnectIntegration` function is called when the user clicks
 * on an integration to connect it. This function opens the Apideck Vault
 * with the necessary parameters to authenticate the user and connect
 * the integration.
 */
export const IntegrationsStage = ({
  onChange
}: {
  onChange: (data: {
    companyName: string;
    connectedIntegrations: ApiDeckConnector[];
  }) => void;
}) => {
  const [connectedIntegrations, setConnectedIntegrations] = useState<ApiDeckConnector[]>([]);
  useEffect(() => {
    onChange({
      companyName: '',
      connectedIntegrations
    });
  }, [connectedIntegrations]);
  return <div className="h-full w-full" data-testid="integrations-stage" data-sentry-component="IntegrationsStage" data-sentry-source-file="CompanyNameOrIntegrationsStage.tsx">
      <div className={styles.integrationsPanel}>
        <div className={styles.integrationsPanelContent}>
          <IntegrationsPopupBody isFilterEmptyTabs onConnected={(item: ApiDeckConnector) => {
          setConnectedIntegrations([...connectedIntegrations, item]);
        }} data-sentry-element="IntegrationsPopupBody" data-sentry-source-file="CompanyNameOrIntegrationsStage.tsx" />
        </div>
      </div>
    </div>;
};

/**
 * Renders the CompanyNameStage component, which allows the user to input and update the company name.
 *
 * @param onChange - A function that is called when the company name is updated, with the new company name as the argument.
 * @param data - An object containing the current company name data.
 * @param nextStep - An optional function that is called when the user presses the Enter key and the company name is at least 3 characters long.
 */
export const CompanyNameStage = ({
  onChange,
  data,
  nextStep
}: {
  onChange: (data: CompanyState['Company Name']) => void;
  data: CompanyState['Company Name'];
  nextStep?: () => void;
}) => {
  const [selectedName, setSelectedName] = useState<string>(data.companyName);
  const {
    t: translate
  } = useTranslation(['company']);

  // Handles the change of the company name
  const handleNameChange = (e: any) => {
    setSelectedName(e.target.value);
    onChange({
      companyName: e.target.value
    });
  };
  useEffect(() => {
    // Checking wether or not company name was updated
    if (data.companyName) {
      setSelectedName(data.companyName);
    }
  }, [data]);
  return <div className="h-full w-full" data-testid="company-name-stage" data-sentry-component="CompanyNameStage" data-sentry-source-file="CompanyNameOrIntegrationsStage.tsx">
      <div className="relative h-full w-full">
        <input data-testid="company-name-input" type="text" className={styles.addNameInput} placeholder={translate('company_name')}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus value={selectedName} onChange={e => handleNameChange(e)} onKeyDown={e => {
        if (e.key === 'Enter' && selectedName.length >= 3) {
          nextStep?.();
        }
      }} />
      </div>
    </div>;
};