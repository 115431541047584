import { Clipboard } from 'lucide-react';
import { IoMdAdd } from 'react-icons/io';
import { LuPencil } from 'react-icons/lu';
import AddChartIcon from '@/public/assets/icons/ChartPlus.svg';
import styles from '@/styles/table.module.scss';
import { parseUTCDateObject } from '@/utils/dateUtils';
import type { SpecificHeaderProps } from '../shared/tableHelper';

/**
 *   gets ISO string and returns the value of the formula string to send to the backend
 * */
export const getDateFormulaString = (date: string) => {
  return `{DATE}(${parseUTCDateObject(date).getMonth() + 1},${parseUTCDateObject(date).getFullYear()})`;
};
export const ModelTableHeader: React.FC<SpecificHeaderProps> = ({
  headerGroup,
  activeBranch
}) => {
  const {
    headers
  } = headerGroup;
  const header0ID = `${headers[0]?.id ? headers[0]?.id : 'sidebar'}-top-control`;
  const header1ID = `${headers[1]?.id ? headers[1]?.id : 'column-1'}-top-control`;
  return <tr key={`${headerGroup.id}-top-bar`} className={styles.tableSettingsHeader} data-sentry-component="ModelTableHeader" data-sentry-source-file="ModelTableUtils.tsx">
      <th aria-label="header cell" key={header0ID}>
        <div className={styles.tableSettingHeaderCell}>
          <img src="/assets/icons/Table-scenario.svg" alt="scenario" />{' '}
          {activeBranch?.name}
        </div>
      </th>
      <th aria-label="header cell" key={header1ID}>
        <div className={styles.tableSettingsHeader_search}>
          <img src="/assets/icons/search-icon.svg" alt="search" />
          <input type="text" placeholder="Search" className={styles.searchInput} />
        </div>
      </th>
    </tr>;
};
export const getOptions = (addFormula: () => void, newFormulaInGroup: {
  title: string;
  action: () => void;
}, deleteFormula: () => void, renameFormula: () => void, createGraphForFormula: () => void, cloneFormula: () => void) => [{
  label: 'Add Formula',
  onClick: () => addFormula(),
  id: `table-options-${1}`,
  icon: <IoMdAdd width={15} color="black" />
}, {
  label: `Add new ${newFormulaInGroup.title.replace('s', '')}`,
  onClick: () => newFormulaInGroup.action(),
  id: `table-options-${2}`,
  icon: <IoMdAdd size={18} color="black" />
}, {
  label: 'Rename',
  onClick: () => renameFormula(),
  id: `table-options-${3}`,
  icon: <LuPencil size={15} color="black" />
}, {
  label: 'Clone',
  onClick: () => cloneFormula(),
  id: `table-options-${4}`,
  icon: <Clipboard size={15} color="black" />
}, {
  label: 'Create Graph for formula',
  onClick: () => createGraphForFormula(),
  id: `table-options-${5}`,
  icon: <img src={AddChartIcon.src} alt="chart" width={20} height={20} />
}, {
  label: 'Delete',
  onClick: () => deleteFormula(),
  id: `table-options-${6}`,
  icon: <img src="/assets/icons/deleteNewIcon.svg" height={15} width={15} alt="delete" />
}];