import { useMemo } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useShallow } from 'zustand/react/shallow';
import type { DataStoreState } from '@/miscellaneous/store/DataStore';
import DataStore from '@/miscellaneous/store/DataStore';
import type { UtilsStoreState } from '@/miscellaneous/store/utilsStore/utilsStore';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import type { ZustandState } from '@/miscellaneous/store/zustand_store';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { vercelEnv } from '@/utils/AppConfig';
const PRODUCT_FRUITS_WORKSPACE_CODE = vercelEnv === 'production' ? process.env.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE_CODE_LIVE : process.env.NEXT_PUBLIC_PRODUCT_FRUITS_WORKSPACE_CODE_DEV;
interface ProductFruitsLayoutProps {
  children: React.ReactNode;
}
const ProductFruitsLayout = ({
  children
}: ProductFruitsLayoutProps) => {
  const {
    userMe,
    activeCompany
  } = useZustandStore(useShallow((state: ZustandState) => ({
    userMe: state.userMe,
    activeCompany: state.activeCompany
  })));
  const {
    isProductFruitsEnabled
  } = useUtilsStore(useShallow((state: UtilsStoreState) => ({
    isProductFruitsEnabled: state.isProductFruitsEnabled
  })));
  const {
    subscriptionDetails
  } = DataStore(useShallow((state: DataStoreState) => ({
    subscriptionDetails: state.subscriptionDetails
  })));
  const userInfo = useMemo(() => {
    if (!userMe?.username || !userMe?.email) {
      return null;
    }
    return {
      username: userMe.username,
      email: userMe.email,
      firstname: userMe.first_name || '',
      lastname: userMe.last_name || '',
      role: userMe.is_superuser ? 'admin' : 'user',
      props: {
        fullName: userMe.full_name || `${userMe.first_name || ''} ${userMe.last_name || ''}`.trim() || userMe.username
      }
    };
  }, [userMe]);
  const productFruitsEnabled = userMe && userInfo && !!activeCompany.onboarding_finished_date && !!subscriptionDetails && isProductFruitsEnabled;
  return <>
      {productFruitsEnabled && <ProductFruits workspaceCode={(PRODUCT_FRUITS_WORKSPACE_CODE as string)} language="en" user={userInfo} />}
      {children}
    </>;
};
export default ProductFruitsLayout;