/* eslint-disable import/no-cycle */
import type { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { WandSparkles } from 'lucide-react';
import moment from 'moment';
import type { Dispatch, SetStateAction } from 'react';
import { toast } from 'react-toastify';
import slugify from 'slugify';
import useUtilsStore from '@/miscellaneous/store/utilsStore/utilsStore';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, MIXPANEL_KEYS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import { createAdjustedUTCDate, getDateStringFormat } from '@/utils/dateUtils';
import type { Company } from '@/utils/hooks/company/useCompany';
import queryClient from '@/utils/queryClient';
import { isValidUrl } from '@/utils/regex';
import scrapeWebsite from '@/utils/scrapeWebsite';
import { errorToaster } from '@/utils/toaster/toasters';
import type { Steps } from '../GenericStagePopup/GenericStagePopup';
import type { CompanyState, StepConfig } from './onboardingPopupTypes';
import { isProductFinished } from './Stages/CompanyProductsStage/ProducStageSubComponents/ProducStageSubComponents';
import { AutomatedAiPlanBuilderSubPlanType, CompanyPlanType, FastPlanOptionsType } from './Stages/NewCompanySetupStage/NewCompanySetupStage';

/**
 * Determines whether the "Next" button should be disabled based on the current state of the onboarding process.
 *
 * The function checks the current active step and the corresponding state of the company information to determine if the "Next" button should be disabled. It checks for the following conditions:
 *
 * 1. If the active step is "New or Existing" and the `companyState` is falsy, the button should be disabled.
 * 2. If the active step is "Company Name/Integrations" and the company is a new company (`companyState['New or Existing'].isNewCompany`) and the company name (`companyState['Company Name/Integrations'].companyName`) is less than 3 characters long, the button should be disabled.
 * 3. If the active step is "Company Details" and the company details text (`companyState['Company Details'].text`) is less than 3 characters long, the button should be disabled.
 * 4. If the active step is "Details Confirmation" and any of the values in `companyState['Details Confirmation']` are falsy, the button should be disabled.
 * 5. If the active step is "Company Goals" and any of the values in `companyState['Company Goals']` are falsy, the button should be disabled.
 * 6. If the active step is "Template Generation" and the chosen templates (`companyState['Template Generation'].chosenTemplates`) is empty, the button should be disabled.
 *
 * @returns {boolean} Whether the "Next" button should be disabled.
 */
export const buttonDisabledHandler = (activeStep: keyof CompanyState, companyState: CompanyState) => {
  const thirdStageIsDisabled = activeStep === 'Company Name' && companyState['Company Name'].companyName?.length < 3;
  const fourthStageIsDisabled = activeStep === 'Company Details' && companyState['Company Details'].text?.length <= 500;
  const fifthStageIsDisabled = activeStep === 'Details Confirmation' && Object.values(companyState['Details Confirmation']).some(value => value === undefined || value === null || value === '');
  const sixthStageIsDisabled = activeStep === 'Company Goals' && Object.values(companyState['Company Goals']).some(value => value === undefined || value === null || value === '') && !companyState['Company Goals'].howManyEmployees;
  const seventhStageIsDisabled = activeStep === 'Your Custom Plan' && companyState['Your Custom Plan']?.chosenTemplates?.length === 0;
  const eighthStageIsDisabled = activeStep === 'Company Products' && companyState['Company Products'].products?.length > 0 && companyState['Company Products'].products.some(product => !isProductFinished(product));
  const ninthStageIsDisabled = activeStep === 'Company Website' && !isValidUrl(companyState['Company Website'].website) && companyState['Company Website'].website?.length > 0;
  const tenthStageIsDisabled = activeStep === 'Choose your financial plan' && companyState['Choose your financial plan'].selectedPlan === null;
  return thirdStageIsDisabled || ninthStageIsDisabled || fourthStageIsDisabled || fifthStageIsDisabled || sixthStageIsDisabled || eighthStageIsDisabled || seventhStageIsDisabled || tenthStageIsDisabled;
};

/**
 * Generates the text to display on the "Next" button in the onboarding popup.
 *
 * If the current step is the last step, the button text will indicate that the onboarding is complete and the templates will be generated.
 * Otherwise, the button text will indicate the current step number and the total number of steps.
 *
 * @param {string} activeStep - The current active step in the onboarding popup.
 * @param {Record<string, StepConfig<any>>} stepConfig - Configuration for the steps in the onboarding popup.
 * @param {any} translate - A function to translate the button text.
 * @param {boolean} isFirstCompany - Whether the company is the first company.
 * @returns {React.ReactNode} The text to display on the "Next" button.
 */
export const getNextButtonText = (activeStep: string, stepConfig: Record<string, StepConfig<any>>, translate: any, steps: Steps) => {
  const stepKeys = Object.keys(stepConfig);
  const currentStepIndex = stepKeys.indexOf(activeStep);
  // Add null check to prevent "Cannot read properties of undefined" error
  const currentStepObject = currentStepIndex !== -1 && steps ? steps[currentStepIndex] : null;

  // If the current step is the last step, show the "Complete and Generate" text on the "Next" button
  if (currentStepObject?.isLastStep // this is to check if user is on first onboarding process , and we pass last 2 steps (Product and Plan) , we will show complete and generate button for better user experience
  ) {
    return <span className="flex items-center gap-2">
        <WandSparkles size={16} />
        {translate('complete_and_generate')}
      </span>;
  }
  return translate('next_step');
};
export const onOnboardingFinishEffects = () => {
  const {
    setDisplayCounter,
    setAnimationActive,
    setIsProductFruitsEnabled,
    setCallFunctionAfterConfettiAnimation,
    setAppLoader
  } = useUtilsStore.getState();
  setDisplayCounter(false);
  setAppLoader(false);
  setAnimationActive(true);
  setCallFunctionAfterConfettiAnimation(() => {
    setIsProductFruitsEnabled(true);
  });
};

/**
 * Completes the onboarding process and redirects the user to the company's page.
 *
 * This function is responsible for the following actions:
 * - Performs a shallow route navigation to the company's page using the provided `shallowRoute` function.
 * - Invalidates all queries in the query client to ensure the data is up-to-date.
 * - Sets the `countLoader`, `displayCounter`, and `appLoader` states to false after a 2-second delay.
 * - Sets the `animationActive` state to true after a 2-second delay.
 *
 * @param {any} shallowRoute - A function to perform a shallow route navigation.
 * @param {(isLoading: boolean) => void} setCountLoader - A function to set the count loader state.
 * @param {(isLoading: boolean) => void} setDisplayCounter - A function to set the display counter state.
 * @param {(isLoading: boolean) => void} setAppLoader - A function to set the app loader state.
 * @param {(isLoading: boolean) => void} setAnimationActive - A function to set the animation active state.
 * @param {Company} result - The company object that was onboarded.
 */
const finishOnboardingAction = (shallowRoute: any, setCountLoader: (isLoading: boolean) => void, result: Company, mixpanelKey: string, translate: any, companyState?: CompanyState) => {
  const {
    setOnboardingState
  } = useZustandStore.getState();
  shallowRoute({
    company: result?.slug
  });
  queryClient.invalidateQueries().then(() => {
    setCountLoader(false);
    if (companyState && companyState['Automated AI Plan Builder']?.selectedPlan) {
      mixpanelTrackEventWrapper(MIXPANEL_EVENTS.AUTOMATED_AI_COMPLETED, {
        userstage: mixpanelKey,
        planselected: companyState['Automated AI Plan Builder'].selectedPlan
      });
    }
    mixpanelTrackEventWrapper(`${mixpanelKey} ${MIXPANEL_EVENTS.FINISHED}`);

    // reset the onboarding state
    setOnboardingState({
      plan: null,
      ai_plan_action: null,
      ai_plan_type: null
    });
    setTimeout(() => {
      onOnboardingFinishEffects();
      toast.success(translate('new_company_added_your_billing'));
    }, 1000);
  });
};

/**
 * Performs a fast onboarding process for a company.
 * @param fastOnboarding - A mutation function to perform the fast onboarding process.
 * @param companyState - The current state of the company being onboarded.
 * @param close - A function to close the onboarding popup.
 * @param shallowRoute - A function to update the URL with the new company slug.
 * @param setActiveCompany - A function to set the active company in the application state.
 * @returns {Promise<Company | undefined>} - The updated company data, or undefined if the process failed.
 */
export const fastOnboardingProcess = async (fastOnboarding: ReturnType<typeof useMutation>, companyState: CompanyState, close: (shouldRedirect: boolean) => void, shallowRoute: any, setActiveCompany: (company: Company) => void, mixpanelKey: string, translate: any) => {
  mixpanelTrackEventWrapper(`${mixpanelKey} ${MIXPANEL_EVENTS.GENERATE_CLICKED}`);
  const {
    setCountLoader,
    setDisplayCounter,
    setAppLoader
  } = useUtilsStore.getState();
  const {
    website
  } = companyState['Company Website'];
  close(true);
  setAppLoader(true);
  setDisplayCounter(true);
  setCountLoader(true);
  const isSimple = companyState['Fast Plan Options'].selectedPlan === FastPlanOptionsType.SimpleFastPlan;
  if (website) {
    const result = await fastOnboarding.mutateAsync({
      company_url: website,
      is_simple_onboarding: isSimple
    });
    if (result) {
      setActiveCompany((result as Company));
      finishOnboardingAction(shallowRoute, setCountLoader, (result as Company), mixpanelKey, translate, companyState);
      return result;
    }
  }
};

// Track mixpanel event for the current step in the onboarding popup
export const trackMixpanelStep = (activeStep: string, stepConfig: Record<string, StepConfig<any>>, companyState: CompanyState, mixpanelKey: string) => {
  const currentMixpanelConfig = stepConfig[activeStep]?.mixpanelConfig;
  if (currentMixpanelConfig) {
    const {
      getContextFunction
    } = currentMixpanelConfig;
    const context = getContextFunction ? getContextFunction(companyState) : {};
    const mixpanelEventString = `${mixpanelKey} ${currentMixpanelConfig.mixpanelEventString}`;
    mixpanelTrackEventWrapper(mixpanelEventString, context);
  }
};
const createBlankPlan = async (setAppLoader: (isLoading: boolean) => void, setCountLoader: (isLoading: boolean) => void, setDisplayCounter: (isLoading: boolean) => void, finishOnboarding: any, mixpanelKey: string, shallowRoute: any, setActiveCompany: (company: Company) => void, activeCompany: Company, close: (shouldRedirect: boolean) => void, translate: any) => {
  // Shows the loader with counter
  setAppLoader(true);
  setCountLoader(true);
  setDisplayCounter(true);
  mixpanelTrackEventWrapper(`${mixpanelKey} ${MIXPANEL_EVENTS.GENERATE_CLICKED}`);

  // If user chooses blank plan, we will create a new company with default values, When company has that fields filled, we will reset the company
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  finishOnboarding && finishOnboarding.mutateAsync && finishOnboarding?.mutateAsync({
    template_id_list: []
  }).then(async (company: Company) => {
    setActiveCompany(company);
    finishOnboardingAction(shallowRoute, setCountLoader, activeCompany, mixpanelKey, onOnboardingFinishEffects, translate);
  }).catch((error: AxiosError) => {
    mixpanelTrackEventWrapper(`${mixpanelKey} ${MIXPANEL_EVENTS.FINISHED_FAILED}`, {
      error
    });
    errorToaster('Error happened during onboarding our team has been notified');
  });
  close(true);
};

/**
 * Handles the logic for navigating to the next step in the onboarding popup.
 *
 * If the current step is "Company Name/Integrations" and the company is not new, the modal will be opened if there are no connected integrations.
 * Otherwise, the function will update the company data with the current step information and navigate to the next step.
 * If the current step is the last step, the function will complete the onboarding process and redirect the user.
 *
 * @param {string} activeStep - The current active step in the onboarding popup.
 * @param {CompanyState} companyState - The current state of the company being onboarded.
 * @param {boolean} isModalOpen - Whether the modal is currently open.
 * @param {Dispatch<SetStateAction<boolean>>} setIsModalOpen - Function to set the modal open state.
 * @param {Dispatch<SetStateAction<string>>} setActiveStep - Function to set the active step.
 * @param {Record<string, StepConfig<any>>} stepConfig - Configuration for the steps in the onboarding popup.
 * @param {any} close - Function to close the onboarding popup.
 * @param {any} updateCompany - Function to update the company data.
 * @param {any} shallowRoute - Function to perform a shallow route navigation.
 * @param {any} finishOnboarding - Function to complete the onboarding process.
 */
export const goToNextStep = async (activeStep: string, companyState: CompanyState, isModalOpen: boolean, setIsModalOpen: Dispatch<SetStateAction<boolean>>, setActiveStep: Dispatch<SetStateAction<string>>, stepConfig: Record<string, StepConfig<any>>, close: any, updateCompany: any, shallowRoute: any, finishOnboarding: any, activeCompany: Company, setAppLoader: (isLoading: boolean) => void, setActiveCompany: (company: Company) => void, setModalContent: Dispatch<SetStateAction<string>>, translate: any, scrapeCompany: any, fastOnboarding: any, mixpanelKey: string, steps: Steps) => {
  const {
    setCountLoader,
    setDisplayCounter
  } = useUtilsStore.getState();
  const {
    setStartDate,
    setCurrentDate,
    onboardingState
  } = useZustandStore.getState();
  const stepKeys = Object.keys(stepConfig);
  const currentStepIndex = stepKeys.indexOf(activeStep);
  const currentStepObject = currentStepIndex !== -1 && steps ? steps[currentStepIndex] : null;
  trackMixpanelStep(activeStep, stepConfig, companyState, mixpanelKey);
  const isOnboarding = mixpanelKey === MIXPANEL_KEYS.ONBOARDING;

  // Update the company data with the current step information
  const updateCompanyData = async (data: any, isShallowRoute: boolean = false) => {
    setAppLoader(true);
    try {
      const result = await updateCompany.mutateAsync(data);
      if (result) {
        setActiveCompany(result);
        if (stepKeys[currentStepIndex + 1]) {
          setActiveStep((stepKeys[currentStepIndex + 1] as string));
        }
        if (isShallowRoute) {
          setCountLoader(true);
          setDisplayCounter(true);
          finishOnboarding.mutateAsync({
            template_id_list: companyState['Your Custom Plan'].chosenTemplates.map(template => template.id),
            is_simple_onboarding: isOnboarding
          }).then(async () => {
            finishOnboardingAction(shallowRoute, setCountLoader, result, mixpanelKey, onOnboardingFinishEffects, companyState);
          }).catch((error: AxiosError) => {
            mixpanelTrackEventWrapper(`${mixpanelKey} ${MIXPANEL_EVENTS.FINISHED_FAILED}`, {
              error
            });
            errorToaster('Error happened during onboarding our team has been notified');
          });
        }
      }
      if (!isShallowRoute) {
        setAppLoader(false);
      }
    } catch (error) {
      setAppLoader(false);
      if (stepKeys[currentStepIndex + 1]) {
        setActiveStep((stepKeys[currentStepIndex + 1] as string));
      }
    }
  };

  // Each if statement handles a specific independent case that requires its own validation and action

  // Case 1: Handles blank plan selection
  if (activeStep === 'Choose your financial plan') {
    if (companyState['Choose your financial plan'].selectedPlan === CompanyPlanType.BlankPlan) {
      await createBlankPlan(setAppLoader, setCountLoader, setDisplayCounter, finishOnboarding, mixpanelKey, shallowRoute, setActiveCompany, activeCompany, close, translate);
      return;
    }
    if (companyState['Choose your financial plan'].selectedPlan === CompanyPlanType.UploadYourPlan) {
      setActiveStep('Upload Your Plan');
      return;
    }
  }
  if (activeStep === 'Upload Your Plan') {
    await createBlankPlan(setAppLoader, setCountLoader, setDisplayCounter, finishOnboarding, mixpanelKey, shallowRoute, setActiveCompany, activeCompany, close, translate);
    return;
  }

  // If user on first onbaording process, we will move user to company website step , when user select automated ai plan builder
  if (activeStep === 'Choose your financial plan' && companyState['Choose your financial plan'].selectedPlan === CompanyPlanType.AutomatedAiPlanBuilder && mixpanelKey === MIXPANEL_KEYS.ONBOARDING // this is to check if user is on first onboarding process
  ) {
    setActiveStep('Company Website');
    return;
  }
  if (activeStep === 'Automated AI Plan Builder') {
    mixpanelTrackEventWrapper(MIXPANEL_EVENTS.AUTOMATED_AI_STARTED, {
      userstage: mixpanelKey,
      planselected: companyState['Automated AI Plan Builder'].selectedPlan
    });
    if (companyState['Automated AI Plan Builder'].selectedPlan === AutomatedAiPlanBuilderSubPlanType.PlanBuilder) {
      setActiveStep('Company Website');
      return;
    }
  }

  // Case 2: Validates integration requirements
  if (activeStep === 'Integrations' && companyState.Integrations.connectedIntegrations?.length === 0 && !isModalOpen) {
    // Shows confirmation modal before proceeding without integrations
    setModalContent(translate('company:are_you_sure_contuniue'));
    setIsModalOpen(true);
    return;
  }

  // Case 3: Enforces website requirement
  if (activeStep === 'Company Website' && !companyState['Company Website'].website && !isModalOpen) {
    // Blocks progression if website is missing
    setModalContent(translate('company:website_required'));
    setIsModalOpen(true);
    return;
  }

  // Case 4: Handles website scraping
  if (activeStep === 'Company Website' && companyState['Company Website'].website) {
    if (companyState['Automated AI Plan Builder'].selectedPlan === AutomatedAiPlanBuilderSubPlanType.OneClickPlan && onboardingState?.ai_plan_action === AutomatedAiPlanBuilderSubPlanType.OneClickPlan && !isOnboarding) {
      await fastOnboardingProcess(fastOnboarding, companyState, close, shallowRoute, setActiveCompany, mixpanelKey, translate);
      return;
    }
    // Initiates website scraping process when website is provided
    await scrapeWebsite(companyState['Company Website'].website, setAppLoader, setActiveCompany, updateCompany, scrapeCompany, () => {
      setActiveStep((stepKeys[currentStepIndex + 1] as string));
    }, currentStepIndex, activeCompany);
    return;
  }

  // This is to check if user is on first onboarding process, if yes , ignore last 2 steps (Product and Plan). Otherwise, doing normal way
  const dynamicOnboardingCondition = !currentStepObject?.isLastStep;

  // If the current step is not the last step, update the company data with the next step information and navigate to the next step
  if (dynamicOnboardingCondition) {
    const companyData = {
      id: activeCompany?.id,
      onboarding_step: currentStepIndex + 1,
      is_new_idea: companyState['Choose your financial plan'].selectedPlan === CompanyPlanType.AutomatedAiPlanBuilder,
      website: companyState['Company Website'].website || null,
      name: companyState['Company Name'].companyName || null,
      slug: activeCompany?.slug,
      location: companyState['Details Confirmation'].teamLocation || null,
      products: companyState['Company Products'].products ? companyState['Company Products'].products.map(product => ({
        name: product.name,
        price: product.price,
        amount_sold_last_m: product.sold_last_month,
        amount_sold_y_ago: product.sold_1_year_ago,
        subscription_type: product.subscription_type,
        CAC: product.cac
      })) : null,
      sectors: companyState['Details Confirmation'].companySectors?.join('|') || null,
      funding_raise: companyState['Details Confirmation'].howMuchFunding || null,
      company_stage: companyState['Details Confirmation'].companyStage || null,
      target_employees_in_one_year: companyState['Company Goals'].howManyEmployees || null,
      target_revenue_in_one_year: companyState['Company Goals'].revenueAYearFromNow || '0',
      raise_next_round_date: companyState['Company Goals'].whenYouRaiseFunding || null,
      target_round_funding: companyState['Company Goals'].howMuchFunding || '0',
      description: companyState['Company Details'].text
    };
    await updateCompanyData(companyData);
  } else {
    let companyData = {
      id: activeCompany?.id,
      slug: slugify(companyState['Company Name'].companyName || activeCompany.name, {
        replacement: '-',
        remove: /[^a-zA-Z0-9\s]/g,
        lower: true,
        strict: true
      }),
      onboarding_finished_date: moment()
    };
    if (companyState.Integrations.connectedIntegrations?.length === 0) {
      const newDate = getDateStringFormat(createAdjustedUTCDate({
        adjustment: {
          value: -1,
          unit: 'months'
        },
        day: 1
      }));
      companyData = {
        ...companyData,
        // @ts-ignore
        start_date: newDate,
        current_date: newDate
      };
      setStartDate(newDate);
      setCurrentDate(newDate);
    }
    await updateCompanyData({
      owner_id: activeCompany.owner_id,
      ...companyData
    }, true);
    close(true);
  }
};

/**
 * Retrieves the appropriate component to render for the current active step in the onboarding popup.
 * @returns {React.ReactElement} The component to render for the current active step.
 */
export const getStepComponent = (activeStep: string, stepConfig: Record<string, StepConfig<any>>) => {
  if (!activeStep) return null;
  // @ts-ignore
  const {
    component: Component,
    ...rest
  } = stepConfig[(activeStep as string)];
  return <Component {...rest} data-sentry-element="Component" data-sentry-component="getStepComponent" data-sentry-source-file="onBoardingPopupBodyFunctions.tsx" />;
};

/**
 * Compares two strings ignoring whitespace and case differences.
 * @param {string} str1 - The first string to compare.
 * @param {string} str2 - The second string to compare.
 * @returns {boolean} - Returns true if the normalized strings are equal, otherwise false.
 */
export function areStringsEqualIgnoreWhitespaceAndCase(str1: string, str2: string) {
  return str1?.toString().replaceAll(/\s|\n/g, '').toLowerCase() === str2?.toString().replaceAll(/\s|\n/g, '').toLowerCase();
}