import classNames from 'classnames';
import { CircleHelp } from 'lucide-react';
import ShineBorder from '@/components/common/ShineBorder/ShineBorder';
import styles from '@/miscellaneous/layouts/PageLayout/pageLayout.module.scss';
import variables from '@/styles/scss/abstracts/_variables.module.scss';
const HelpButton = ({
  widgets
}: {
  widgets: string[];
}) => {
  return <div className={styles.helpButtonContainer} data-sentry-component="HelpButton" data-sentry-source-file="HelpButton.tsx">
      <div className={styles.helpButton}>
        <ShineBorder borderRadius={50} borderWidth={2} color={[(variables.midBlue as string), (variables.livelyPink as string), (variables.paleOrange as string)]} data-sentry-element="ShineBorder" data-sentry-source-file="HelpButton.tsx">
          <CircleHelp size={22} data-sentry-element="CircleHelp" data-sentry-source-file="HelpButton.tsx" />
        </ShineBorder>
      </div>
      <div className={styles.helpButtonWidgetsContainer}>
        {widgets?.map(widget => <div key={widget} className={classNames(widget, styles.elfsightWidget)} />)}
      </div>
    </div>;
};
export default HelpButton;