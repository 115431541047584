import { MoveRight, ShieldCheck } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheckCircle } from 'react-icons/fa';
import { useShallow } from 'zustand/react/shallow';
import FileUploadWithUrl from '@/components/common/FileUploadWithUrl/FileUploadWithUrl';
import ShineBorder from '@/components/common/ShineBorder/ShineBorder';
import useZustandStore from '@/miscellaneous/store/zustand_store';
import { MIXPANEL_EVENTS, mixpanelTrackEventWrapper } from '@/mixpanelUtils';
import variables from '@/styles/scss/abstracts/_variables.module.scss';
import useMutations from '@/utils/hooks/mutations/useMutations';
import { errorToaster } from '@/utils/toaster/toasters';
import type { CompanyState } from '../../onboardingPopupTypes';
import { AutomatedAiPlanBuilderSubPlanType } from '../NewCompanySetupStage/NewCompanySetupStage';
import styles from './uploadFileStage.module.scss';
const UploadFileStageButtons = ({
  logoutApp,
  goToWebsiteStage
}: {
  logoutApp: () => void;
  goToWebsiteStage: () => void;
}) => {
  const {
    t: translate
  } = useTranslation(['company']);
  return <div className={styles.buttonContainer} data-sentry-component="UploadFileStageButtons" data-sentry-source-file="UploadFileStage.tsx">
      <button onClick={() => logoutApp()} type="button" className={styles.logoutButton}>
        Log Out & Notify Me When Ready
      </button>
      <ShineBorder borderRadius={6} borderWidth={2.5} color={[(variables.midBlue as string), (variables.livelyPink as string), (variables.paleOrange as string)]} className="overflow-hidden" data-sentry-element="ShineBorder" data-sentry-source-file="UploadFileStage.tsx">
        <button onClick={goToWebsiteStage} type="button" className={styles.generatePlanButton}>
          {translate('generate_ai_powered_lucid_plan_now')}
        </button>
      </ShineBorder>
    </div>;
};

/**
 * Type definition for file upload payload
 */
type UploadPayload = {
  filename: string;
  file_content: string;
  google_drive_link: string;
};

/**
 * Displays success message and next steps after successful file upload
 * @param onContinue - Callback function to proceed to next step
 */
const SuccessView = ({
  setActiveStep,
  setCompanyState,
  companyState
}: {
  setActiveStep: (step: string) => void;
  setCompanyState: (state: CompanyState) => void;
  companyState: CompanyState;
}) => {
  const {
    t: translate
  } = useTranslation(['company']);
  const {
    logoutApp
  } = useZustandStore(useShallow(state => ({
    logoutApp: state.logoutApp
  })));
  const i18TextKeysForUploadSuccess = ['plan_upload_success_txt_key_1', 'plan_upload_success_txt_key_2', 'plan_upload_success_txt_key_3', 'plan_upload_success_txt_key_4'];

  // Goes directly to AI Plan Builder Website Step.
  const goToWebsiteStage = () => {
    setCompanyState({
      ...companyState,
      'Automated AI Plan Builder': {
        selectedPlan: AutomatedAiPlanBuilderSubPlanType.PlanBuilder
      }
    });
    setActiveStep('Company Website');
  };
  return <div className={styles.successContainer} data-sentry-component="SuccessView" data-sentry-source-file="UploadFileStage.tsx">
      <h2>
        <FaCheckCircle size={50} data-sentry-element="FaCheckCircle" data-sentry-source-file="UploadFileStage.tsx" />
        {translate('upload_your_plan_success_title')}
      </h2>
      <ul className={styles.successList}>
        {i18TextKeysForUploadSuccess.map(item => <li key={item}>
            <MoveRight size={18} />
            <span>{translate(item)}</span>
          </li>)}
      </ul>
      <UploadFileStageButtons logoutApp={logoutApp} goToWebsiteStage={goToWebsiteStage} data-sentry-element="UploadFileStageButtons" data-sentry-source-file="UploadFileStage.tsx" />
    </div>;
};

/**
 * Component for handling financial plan file uploads
 * Supports both direct file upload and Google Drive link
 * Shows success view after successful upload
 *
 * @param nextStep - Callback function to proceed to next step
 */
const UploadFileStage = ({
  mixpanelKey,
  setActiveStep,
  setCompanyState,
  companyState
}: {
  mixpanelKey: string;
  setActiveStep: (step: string) => void;
  setCompanyState: (state: CompanyState) => void;
  companyState: CompanyState;
}) => {
  const {
    t: translate
  } = useTranslation(['company']);
  const {
    uploadFinancialPlan
  } = useMutations();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  /**
   * Handles file upload submission
   * @param payload - Upload payload containing file information or Google Drive link
   */
  const handleSubmit = async (payload: UploadPayload) => {
    mixpanelTrackEventWrapper(MIXPANEL_EVENTS.UPLOAD_SHEET_STARTED, {
      userstage: mixpanelKey
    });
    setIsLoading(true);
    try {
      await uploadFinancialPlan.mutateAsync(payload);
      setIsSuccess(true);
      mixpanelTrackEventWrapper(MIXPANEL_EVENTS.UPLOAD_SHEET_FINISHED, {
        userstage: mixpanelKey
      });
    } catch (error) {
      const message = error instanceof Error && 'response' in error && error.response ? (error.response as any).data?.message ?? translate('upload_your_plan_error') : translate('upload_your_plan_error');
      errorToaster(message);
      mixpanelTrackEventWrapper(MIXPANEL_EVENTS.UPLOAD_SHEET_FAILED, {
        userstage: mixpanelKey,
        error
      });
    } finally {
      setIsLoading(false);
    }
  };
  return <div className={styles.uploadFileStage} data-sentry-component="UploadFileStage" data-sentry-source-file="UploadFileStage.tsx">
      {!isSuccess && <div className={styles.uploadFileStageTitle}>
          <h1>{translate('upload_your_plan_title')}</h1>
          <div className={styles.descriptionContainer}>
            <ShieldCheck size={20} />
            <p>{translate('upload_your_plan_description_text')}</p>
          </div>
        </div>}

      <div className={styles.uploadFileContainer}>
        {isSuccess ? <SuccessView setActiveStep={setActiveStep} setCompanyState={setCompanyState} companyState={companyState} /> : <FileUploadWithUrl onSubmit={handleSubmit} isLoading={isLoading} infoTextForButton="This will be sent to our professional team" />}
      </div>
    </div>;
};
export default UploadFileStage;